// @ts-nocheck

import { QueryBuilderProperty } from "@bikdotai/bik-component-library/dist/esm/components/QueryBuilder/types/QueryBuilder.type";
import { unCamelCase } from "../../../utilities/unCamelCase";
import { format } from "date-fns";
import { cloneDeep } from "lodash";

const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

export const extractHeaderAndSubHeader = (builderState: any) => {
  const node = builderState.nodes[0];
  let header = extractHeader(node.type);
  let subHeader = "";

  subHeader =
    constructReadableString(node.metas, node) +
    " " +
    constructReadableString(node.properties, node);

  return { header, subHeader, id: node._id };
};

const constructReadableString = (originalData: any[], node: any) => {
  let datas = cloneDeep(originalData);
  let result = "";
  datas.forEach((data, index) => {
    let str = "";
    if (data?.name) {
      // Todo: remove this check once campaign fixes this
      if (
        !!node.metas.length &&
        (node.metas[0].value === "abandonedAProduct" ||
          node.metas[0].value === "productViewed") &&
        data.name === "productId"
      ) {
        str += unCamelCase("productName");
      } else {
        str += unCamelCase(data.name);
      }
    }
    str += data.operator ? " " + unCamelCase(data.operator) : "";
    if (Array.isArray(data.value)) {
      if (data.operator === "in the last") {
        str += " " + data.value[0] + " " + data.value[1];
      } else if (data.operator === "exactly") {
        str += " " + data.value[0] + " " + data.value[1] + " Ago";
      } else {
        data.value.forEach((v, i) => {
          if (typeof v === "undefined") return;
          const isDate = dateRegex.test(v);
          if (isDate) {
            v = format(new Date(v), "do MMM yyyy");
            str += " " + v + `${i === data.value.length - 1 ? "" : "-"}`;
          } else if (typeof v.name === "undefined") {
            str += " " + v + `${i === data.value.length - 1 ? "" : ","}`;
          } else {
            str += " " + v.name + `${i === data.value.length - 1 ? "" : ","}`;
          }
        });
      }
    } else if (typeof data.value === "object") {
      str += data.value.operator ? " " + unCamelCase(data.value.operator) : "";
      if (Array.isArray(data.value.value)) {
        data.value.value.forEach((v, i) => {
          const isDate = dateRegex.test(v);
          if (isDate) {
            v = format(new Date(v), "do MMM yyyy");
          }
          str += " " + v;
        });
      } else if (data.value && typeof data.value.value === "undefined") {
        if (data.operator === "in between last") {
          const { offset, start, type } = data.value;
          if (parseInt(offset) > parseInt(start)) {
            data.value = `${start} to ${offset} ${type}`;
          } else {
            data.value = `${offset} to ${start} ${type}`;
          }
        }
        str += data.value ? " " + data.value : "";
      } else {
        const isDate = dateRegex.test(data.value.value);
        if (isDate) {
          data.value.value = format(new Date(data.value.value), "do MMM yyyy");
        } else if (data.key === "frequency") {
          data.value.value = data.value.value
            ? data.value.value + " time(s)"
            : "";
        } else if (data.value.operator === "in the last") {
          data.value.value = data.value.value
            ? data.value.value + " day(s)"
            : "";
        } else if (data.value.operator === "in between last") {
          const { offset, start } = data.value.value;
          if (parseInt(offset) > parseInt(start)) {
            data.value.value = `${start} to ${offset} day(s)`;
          } else {
            data.value.value = `${offset} to ${start} day(s)`;
          }
        }
        str += data.value.value ? " " + data.value.value : "";
      }
    } else if (dateRegex.test(data.value)) {
      data.value = format(new Date(data.value), "do MMM yyyy");
      str += data.value ? " " + data.value : "";
    } else {
      str += data.value ? " " + unCamelCase(data.value) : "";
    }
    result += str;
  });
  return result;
};

export const extractHeader = (type: string) => {
  switch (type) {
    case "EVENT_FILTER":
      return `Performed Event`;
    case "NO_EVENT_FILTER":
      return `Didn't perform event`;
    case "USER_PROPERTY_FILTER":
      return `Have User Property`;
    case "SEGMENT_FILTER":
      return "Part of Segment";
  }
};

export const getChildNodes = (nodeData: any) => {
  return {
    0: {
      key: "If Filter matches",
      value:
        nodeData?.conf?.actions?.filter?.conditions?.[0]?.operator_values?.[0][
          "value"
        ] || "",
    },
    1: {
      key: "Else",
      value:
        nodeData?.conf?.actions?.filter?.conditions?.[0]?.operator_values?.[1][
          "value"
        ] || "",
    },
  };
};

export const sanitiseState = (state: any) => {
  const frontendNodes = state.nodes.map((node: any) => {
    const newMetas = node.metas.map((meta: any) => deleteUndefinedKeys(meta));
    const newProperties = node.properties.map((property: any) =>
      deleteUndefinedKeys(property)
    );
    return { ...node, metas: newMetas, properties: newProperties };
  });

  const backendNodes = frontendNodes.map((node: any) => {
    const backendMetas = node.metas.map((meta: any) =>
      deleteUndefinedKeys(prepareDateMeta(meta))
    );
    const properties =
      node.properties
        ?.map((property) =>
          prepareDate(
            prepareInteger(prepareIsAndIsNot(prepareBoolean(property)))
          )
        )
        .flat() ?? [];
    return { ...node, metas: backendMetas, properties };
  });

  const frontendState = { ...state, nodes: frontendNodes };
  const backendState = { ...state, nodes: backendNodes };

  return { frontendState, backendState };
};

function prepareDateMeta(meta: any) {
  if (meta.key === "date") {
    if (
      meta.value.operator === "in the last" ||
      meta.value.operator === "not in the last"
    ) {
      return {
        ...meta,
        value: {
          ...meta.value,
          operator: `${
            meta.value.operator === "not in the last" ? "not in the " : ""
          }last X ${meta.value.value?.[1] ?? "days"}`,
          value: [(meta.value.value?.[0] ?? 1).toString()],
        },
      };
    } else if (meta.value.operator === "last 7 days") {
      return {
        ...meta,
        value: {
          ...meta.value,
          value: ["7"],
        },
      };
    } else if (meta.value.operator === "last 30 days") {
      return {
        ...meta,
        value: {
          ...meta.value,
          value: ["30"],
        },
      };
    } else if (
      meta.value.operator === "in between last" ||
      meta.value.operator === "not in between last"
    ) {
      const offset =
        meta.value.value.offset < meta.value.value.start
          ? meta.value.value.offset
          : meta.value.value.start;

      const start =
        meta.value.value.offset < meta.value.value.start
          ? meta.value.value.start
          : meta.value.value.offset;

      return {
        ...meta,
        value: {
          ...meta.value,
          operator: `${
            meta.value.operator === "not in between last" ? "not " : ""
          }in the last X ${meta.value.value?.type ?? "days"} with offset of Y ${
            meta.value.value?.type ?? "days"
          }`,
          value: [start],
          offset: [offset],
        },
      };
    } else if (
      meta.value.operator === "on" ||
      meta.value.operator === "notOn"
    ) {
      const val = meta.value.value;
      const date = new Date(val);
      const today = new Date(date.setHours(0, 0, 0, 0));
      const tomorrow = new Date(
        new Date(date.setHours(23, 59, 59, 999)).setDate(date.getDate())
      );

      return {
        ...meta,
        value: {
          ...meta.value,
          operator:
            meta.value.operator === "on"
              ? "inBetween"
              : meta.value.operator === "notOn"
              ? "notInBetween"
              : meta.value.operator,
          value: [today, tomorrow],
        },
      };
    } else if (
      meta.value.operator === "exactly" ||
      meta.value.operator === "not exactly"
    ) {
      const type = meta.value.value?.[1] ?? "days";
      const x = meta.value.value?.[0] ?? "1";

      return {
        ...meta,
        value: {
          ...meta.value,
          operator:
            meta.value.operator === "exactly"
              ? `exactly x ${type} ago`
              : meta.value.operator === "not exactly"
              ? `not exactly x ${type} ago`
              : meta.value.operator,
          value: [x.toString()],
        },
      };
    } else {
      return {
        ...meta,
        value: {
          ...meta.value,
          operator:
            meta.value.operator === "on"
              ? "inBetween"
              : meta.value.operator === "notOn"
              ? "notInBetween"
              : meta.value.operator,
        },
      };
    }
  } else if (meta.key === "frequency") {
    return {
      ...meta,
      value: {
        ...meta.value,
        value: parseInt(meta.value?.value),
      },
    };
  }
  return meta;
}

function prepareDate(property: QueryBuilderProperty): QueryBuilderProperty {
  if (property.dataType === "date" || property.dataType === "exactly") {
    if (
      property.operator === "in the last" ||
      property.operator === "not in the last"
    ) {
      return {
        ...property,
        operator: `${
          property.operator === "not in the last" ? "not in the " : ""
        }last X ${property.value?.[1] ?? "days"}`,
        value: [(property.value?.[0] ?? 1).toString()],
      };
    } else if (property.operator === "last 7 days") {
      return {
        ...property,
        operator: "last X days",
        value: ["7"],
      };
    } else if (property.operator === "last 30 days") {
      return {
        ...property,
        operator: "last X days",
        value: ["30"],
      };
    } else if (
      property.operator === "in between last" ||
      property.operator === "not in between last"
    ) {
      const offset =
        property.value?.offset < property.value?.start
          ? property.value?.offset
          : property.value?.start;

      const start =
        property.value?.offset < property.value?.start
          ? property.value?.start
          : property.value?.offset;

      return {
        ...property,
        operator: `${
          property.operator === "not in between last" ? "not " : ""
        }in the last X ${property.value?.type ?? "days"} with offset of Y ${
          property.value?.type ?? "days"
        }`,
        value: [start],
        offset: [offset],
      };
    } else if (property.operator === "on" || property.operator === "notOn") {
      const val = property.value;
      const date = new Date(val);
      const today = new Date(date.setHours(0, 0, 0, 0));
      const tomorrow = new Date(
        new Date(date.setHours(23, 59, 59, 999)).setDate(date.getDate())
      );

      return {
        ...property,
        operator:
          property.operator === "on"
            ? "inBetween"
            : property.operator === "notOn"
            ? "notInBetween"
            : property.operator,
        value: [today, tomorrow],
      };
    } else if (
      property.operator === "exactly" ||
      property.operator === "not exactly"
    ) {
      const type = property.value?.[1] ?? "days";
      const x = property.value?.[0] ?? "1";

      return {
        ...property,
        operator:
          property.operator === "exactly"
            ? `exactly x ${type} ago`
            : property.operator === "not exactly"
            ? `not exactly x ${type} ago`
            : property.operator,
        value: [x.toString()],
      };
    } else {
      return {
        ...property,
        operator:
          property.operator === "on"
            ? "inBetween"
            : property.operator === "notOn"
            ? "notInBetween"
            : property.operator,
      };
    }
  }

  return property;
}

function prepareInteger(property: QueryBuilderProperty) {
  if (
    property.operator === "lessThan" ||
    property.operator === "lessThanOrEqualTo" ||
    property.operator === "greaterThan" ||
    property.operator === "greaterThanOrEqualTo"
  ) {
    return {
      ...property,
      value: Array.isArray(property.value) ? property.value : [property.value],
    };
  }
  return { ...property };
}

function prepareIsAndIsNot(property: QueryBuilderProperty) {
  if (typeof property.value === "object" && Array.isArray(property.value)) {
    let value = property.value;

    if (
      property.dataType === "string" &&
      (property.operator === "is" || property.operator === "isNot")
    ) {
      value = property?.value?.map((val) => {
        if (typeof val === "object") {
          return val;
        }

        return {
          id: val,
          name: val,
        };
      });
    } else {
      value = property?.value?.map((val) => {
        if (typeof val === "object" && val.id) {
          return val.id;
        }

        return val;
      });
    }

    property = {
      ...property,
      value: value?.filter((val) => val !== null && val !== undefined) ?? [],
    };
  }

  return property;
}

export function prepareBoolean(
  property: QueryBuilderProperty
): QueryBuilderProperty {
  if (property.dataType === "boolean") {
    if (property.operator === "isTrue") {
      return {
        ...property,
        operator: "is",
        value: [true],
      };
    }
    if (property.operator === "isFalse") {
      return {
        ...property,
        operator: "is",
        value: [false],
      };
    }
  }

  return property;
}

function deleteUndefinedKeys(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    const cleanedArr = [];
    obj.forEach((item) => {
      if (typeof item !== "undefined") {
        if (item instanceof Date) {
          cleanedArr.push(item?.toISOString());
        } else {
          cleanedArr.push(deleteUndefinedKeys(item));
        }
      }
    });
    return cleanedArr;
  } else {
    const cleanedObj = {};
    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (typeof value === "undefined") {
        try {
          delete obj[key];
        } catch (err) {
          // Handle read-only key
          if (Object.getOwnPropertyDescriptor(obj, key).configurable) {
            Object.defineProperty(obj, key, {
              value: undefined,
              configurable: true,
            });
            delete obj[key];
          }
        }
      } else {
        if (value instanceof Date) {
          cleanedObj[key] = value?.toISOString();
        } else {
          const cleanedValue = deleteUndefinedKeys(value);
          if (
            typeof cleanedValue !== "undefined" ||
            Object.keys(cleanedValue).length > 0
          ) {
            cleanedObj[key] = cleanedValue;
          }
        }
      }
    });
    return cleanedObj;
  }
}

export const SpecialResponse = {
  data: [
    {
      id: "lastOrderDate",
      name: "Last order date",
      needValuesRetrieval: false,
      type: "exactly",
      description: "The date when the most recent order was placed by the user",
    },
    {
      id: "initialOrderDate",
      name: "First order date",
      needValuesRetrieval: false,
      type: "exactly",
      description: "The date when the first order was placed by the user",
    },
    {
      id: "totalOrderValue",
      name: "Customer’s total order value",
      needValuesRetrieval: false,
      type: "integer",
      description: "Total (monetary) order value for a customer",
    },
  ],
  status: 200,
};

export const removeSubstring = (
  originalString: string,
  substringToRemove: string
) => {
  return originalString.replace(substringToRemove, "").trim();
};
