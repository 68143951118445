import { combineReducers, configureStore, PayloadAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import builderStateSlice from "./builderState";
import nodeClickDataSlice from "./nodeClickDataState";
import NodeItemDataSlice from "./nodeItemDataState";
import storeDataSlice from "./storeDataState";
import { RootState } from "./storeType";
import flowMetaStateSlice from "./flowMetaState";
import homeDataSlice from "./homeState";
import publishRequestSlice from "./publishState";
import customisationState from "./customisationState";
import categoryState from "./categoryState";
import localVariablesState from "./localVariablesState";
import globalVariablesState from "./globalVariablesState";
import aiVariablesState from "./aiVariablesState";
import apiDataStateSlice from "./apiDataState";
import notificationDataStateSlice from "./notificationDataState";
import NodeActionSlice from "./nodeActionState";
import nodeSubtypeMappingState from "./nodeSubtypeMappingState";
import proximityConnectSlice from "./proximityConnect";
import EventPropertySlice from "./eventPropertyState";
import errorDataSlice from "./errorDataState"
import { variablesApi } from "./variables";
import { integrationsCheckApi } from "./integrations";
import debugRunDataSlice from "./debugRunState";
import productsDataState from "./productsState";
import stickyNotesSlice from "./stickyNotesState";
import AutomationTriggerSlice from "./automationTriggerState";
import { queryBuilderSlice } from "@bikdotai/bik-component-library";
import payloadVariablesDataSlice from "./payloadVariableState";
import storeInfoSlice from "./storeInfoState";
import keyboardEventDataSlice from "./keyboardEventState";

const combineReducer = combineReducers({
    storeState: storeDataSlice.reducer,
    nodeClickState: nodeClickDataSlice.reducer,
    nodeItemDataState: NodeItemDataSlice.reducer,
    builderState: builderStateSlice.reducer,
    flowMetaState: flowMetaStateSlice.reducer,
    homeState: homeDataSlice.reducer,
    publishRequestState: publishRequestSlice.reducer,
    customisationState: customisationState.reducer,
    categoryState: categoryState.reducer,
    localVariablesState: localVariablesState.reducer,
    payloadVariableState: payloadVariablesDataSlice.reducer,
    debugRunState: debugRunDataSlice.reducer,
    globalVariablesState: globalVariablesState.reducer,
    aiVariablesState: aiVariablesState.reducer,
    apiDataState: apiDataStateSlice.reducer,
    storeInfoState: storeInfoSlice.reducer,
    notificationDataState: notificationDataStateSlice.reducer,
    nodeActionState: NodeActionSlice.reducer,
    nodeSubtypeMappingState: nodeSubtypeMappingState.reducer,
    proximityConnectState: proximityConnectSlice.reducer,
    eventPropertyState: EventPropertySlice.reducer,
    automationTriggerState: AutomationTriggerSlice.reducer,
    errorDataState: errorDataSlice.reducer,
    productsDataState: productsDataState.reducer,
    stickyNotesState: stickyNotesSlice.reducer,
    keyboardEventState: keyboardEventDataSlice.reducer,
    eventTrigger: queryBuilderSlice('eventTrigger').reducer,
    [variablesApi.reducerPath]: variablesApi.reducer,
    [integrationsCheckApi.reducerPath]: integrationsCheckApi.reducer
});

const rootReducer = (state: any, action: PayloadAction<any>) => {
  if (action?.type === "reset") {
    const homeState = { ...state.homeState };
    homeState.flowName = "Chatbot Builder";
    state = {
      undefined,
      homeState,
    };
  }
  return combineReducer(state, action);
};

const botStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(variablesApi.middleware, integrationsCheckApi.middleware),
});

export type AppDispatch = typeof botStore.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default botStore;
