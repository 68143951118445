import { BikShimmer, BodyPrimary, BodySecondary, Button, ButtonGroup, COLORS, CheckBox, DynamicTabs, DynamicTabsDetails, DynamicTabsStyleDetails, StyledModal, TitleMedium, TitleRegular } from "@bikdotai/bik-component-library";
import { ActionStrip, Body, BtnGrpSelector, CheckBoxBar, CommentAction, Container, ExcludeStrip, Footer, Header, HeartReaction, InlineText, KeywordContainer, ModalTitle } from "./style";
import { VerticalSeparator } from "./style";
import { ErrorIndicator, KeywordsInput } from "./comments";
import { useEffect, useRef, useState } from "react";
import HeartIcon from "../../icons/heartIcon";
import { ActionEventsList } from "./models";
import { generateUniqueId } from "../../app/action-block/Util";
import InstaIdsDropdown from "./instaDropdown";
import ErrScreen from "./errScreen";
import { v4 as uuidv4 } from 'uuid';


const Replies = (props: {
    instaId: string;
    storeId: string;
    navigateBack: React.Dispatch<React.SetStateAction<ActionEventsList | "default">>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    fetchedConf: any;
    onSave: (data: any) => void;
}) => {

    const { fetchedConf } = props

    const [anyOrSpecificKeywords, setAnyOrSpecificKeywords] = useState<string>('Any')
    const [excludeInKeywords, setExcludeInKeywords] = useState<boolean>(false);
    const [keywords, setKeywords] = useState<string[]>([])
    const [heart, setHeart] = useState<boolean>(false)
    const [errors, setErrors] = useState<{
        [key: string]: boolean
    }>({
        keywords: false
    })
    const [selectedInsta, setSelectedInsta] = useState<{
        label: string,
        value: string,
        selected: boolean
    }>();
    const prevSelectedInsta = useRef<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [showErrScreen, setShowErrorScreen] = useState<boolean>(false)

    useEffect(() => {
        if (Object.keys(fetchedConf).length) {
            setHeart(fetchedConf.heartReaction)

            const replies = (fetchedConf.trigger.eventProperties.filter((item: any) => item.firstOperand === 'Reply'))[0]
            if (replies.condition === 'any') {
                setAnyOrSpecificKeywords('Any')
                if (replies.secondOperandType === 'includes') {
                    setExcludeInKeywords(false)
                } else {
                    setKeywords(replies.secondOperand)
                    setExcludeInKeywords(true)
                }
            } else {
                setAnyOrSpecificKeywords('Specific')
                setExcludeInKeywords(false)
                setKeywords(replies.secondOperand)
            }
        }
    }, [])

    useEffect(() => {

        if (selectedInsta?.value) {
            setIsLoading(false)
        }

        if (selectedInsta?.value === 'ERROR') {
            setShowErrorScreen(true)
            return
        }

        if (!prevSelectedInsta.current || !selectedInsta?.value) {
            prevSelectedInsta.current = selectedInsta?.value || ''
            return
        }

        if (prevSelectedInsta.current && prevSelectedInsta.current !== selectedInsta.value) {
            setHeart(false)
            setAnyOrSpecificKeywords('Any')
            setExcludeInKeywords(false)
            setKeywords([])
            prevSelectedInsta.current = selectedInsta.value
        }

    }, [selectedInsta])

    const onSelectKeyword = (keywords: string[]) => {
        setKeywords(keywords)
    }

    const tabStyleInfo: DynamicTabsStyleDetails = {
        type: 'SQUARE',
    };


    const tabsInfoKeywords: DynamicTabsDetails[] = [
        {
            id: 0,
            text: 'Any',
            action: (tabName: string) => {
                if (tabName !== anyOrSpecificKeywords) {
                    setKeywords([])
                    setExcludeInKeywords(false)
                }
                setAnyOrSpecificKeywords('Any')
            },
        },
        {
            id: 1,
            text: 'Specific',
            action: (tabName: string) => {
                if (tabName !== anyOrSpecificKeywords) {
                    setKeywords([])
                    setExcludeInKeywords(false)
                }
                setAnyOrSpecificKeywords('Specific')
            },
        },
    ];

    const generateConfig = () => {

        if (anyOrSpecificKeywords === 'Specific' && !keywords.length) {
            setErrors({
                'keywords': true
            })
            return
        } else {
            setErrors({
                'keywords': false
            })
        }

        const eventProperties = [
            {
                condition: "in",
                firstOperand: "anyStory",
                secondOperand: []
            },
        ]
        let secondProperty = {} as any
        if (anyOrSpecificKeywords === 'Any') {
            secondProperty = {
                condition: 'any',
                firstOperand: 'Reply',
                secondOperand: excludeInKeywords ? keywords : [],
                secondOperandType: excludeInKeywords ? 'excludes' : 'includes'
            }
        } else {
            secondProperty = {
                condition: 'contains',
                firstOperand: 'Reply',
                secondOperand: keywords,
                secondOperandType: 'includes'
            }
        }
        eventProperties.push(secondProperty)
        const eventConnector = "AND"
        const eventSelected = "Replies"
        const trigger = {
            eventProperties,
            eventSelected,
            eventConnector
        }
        const mediaType = ""
        const media: [] = []

        const header = 'Replies on story'
        const subHeader = anyOrSpecificKeywords === 'Any' ? 'Reply contains any keyword' : `Reply contains ${keywords.map(item => `'${item}'`).join(', ')}`
        const id = fetchedConf?.id ? fetchedConf.id : uuidv4();
        const type = 'ig'
        const tag = 'Any Story'
        const instaId = selectedInsta?.value
        const operator = 'AND'

        props.onSave({
            trigger,
            mediaType,
            media,
            header,
            subHeader,
            id,
            type,
            tag,
            instaId,
            operator,
            commentReplies: [],
            heartReaction: heart
        })
        props.setShowModal(false)
    }

    const onDeleteKeyword = (keyword: string) => {
        setKeywords(keywords.filter(item => item !== keyword))
    }


    return (
        <StyledModal open={true} zIndex={200} centralContainerStyles={{ width: "1032px", height: "560px" }} onClose={() => props.setShowModal(false)}>
            <Container>

                <Header>
                    <TitleMedium>{'Replies on story'}</TitleMedium>
                    <InstaIdsDropdown storeId={props.storeId} instaId={props.instaId} setSelectedInsta={setSelectedInsta} />
                </Header>

                {
                    showErrScreen ? (
                        <Body>
                            <div style={{
                                minHeight: '90%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <ErrScreen
                                    body={'Could not find instagram account'}
                                    title="No account found"
                                />
                            </div>
                        </Body>
                    )
                        : isLoading ? <Body>
                            <BikShimmer boxes={
                                [{ width: "968px", height: "350px" }]
                            } />
                        </Body> :
                            <Body>
                                <ModalTitle>
                                    <TitleRegular>Journey will be triggered...</TitleRegular>
                                </ModalTitle>
                                <CommentAction>
                                    <ActionStrip>
                                        <InlineText>
                                            <BodyPrimary>
                                                {'Whenever someone replies on'}
                                            </BodyPrimary>
                                            <BodyPrimary>
                                                <b>any</b>
                                            </BodyPrimary>
                                            <BodyPrimary>
                                                {'story'}
                                            </BodyPrimary>
                                        </InlineText>
                                    </ActionStrip>
                                </CommentAction>

                                <VerticalSeparator />

                                <CommentAction>
                                    <KeywordContainer>
                                        <BtnGrpSelector>
                                            <BodyPrimary>
                                                And reply contains
                                            </BodyPrimary>

                                            <DynamicTabs
                                                tabsInfo={tabsInfoKeywords}
                                                tabStyleInfo={tabStyleInfo}
                                                selectedTabId={anyOrSpecificKeywords === 'Any' ? 0 : 1}
                                            />
                                            <BodyPrimary>
                                                keywords
                                            </BodyPrimary>
                                        </BtnGrpSelector>
                                        {
                                            anyOrSpecificKeywords === 'Specific' ?
                                                <KeywordsInput keywords={keywords} onDeleteKeyword={onDeleteKeyword}
                                                    onSelectKeyword={onSelectKeyword} errors={errors} setErrors={setErrors} enableError={true} />
                                                : <></>
                                        }
                                    </KeywordContainer>

                                    <ExcludeStrip>
                                        <CheckBoxBar>
                                            <CheckBox
                                                isChecked={excludeInKeywords}
                                                onValueChange={(checked) => {
                                                    checked ? setExcludeInKeywords(true) : setExcludeInKeywords(false)
                                                    setKeywords([])
                                                }}
                                                isDisabled={anyOrSpecificKeywords === 'Specific' ? true : false}
                                            />
                                            <BodySecondary color={anyOrSpecificKeywords === 'Specific' ? COLORS.content.inactive : COLORS.content.primary}>Exclude specific keywords</BodySecondary>
                                        </CheckBoxBar>
                                        {
                                            excludeInKeywords ? <KeywordsInput keywords={keywords} onDeleteKeyword={onDeleteKeyword} onSelectKeyword={onSelectKeyword} /> : <></>
                                        }
                                    </ExcludeStrip>
                                </CommentAction>

                                <HeartReaction>
                                    <CheckBox isChecked={heart} onValueChange={function (checked: boolean, value?: string | undefined): void {
                                        setHeart(checked);
                                    }} />
                                    <InlineText>
                                        <BodySecondary>React with</BodySecondary>
                                        <HeartIcon />
                                        <BodySecondary>in response</BodySecondary>
                                    </InlineText>
                                </HeartReaction>

                            </Body>
                }

                <Footer>
                    {errors['keywords'] ? <ErrorIndicator /> : <div></div>}
                    <Button buttonText="Create" size={'medium'} onClick={() => {
                        generateConfig()
                    }} />
                </Footer>

            </Container>
        </StyledModal>
    )
}

export default Replies;