import { Spinner, COLORS, IconButton, Tooltip } from "@bikdotai/bik-component-library";
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import DownloadIcon from "../../../icons/DownloadIcon";
import RefreshIcon from "../../../icons/refreshIcon";
import homeState, { homeDataAction } from "../../../state/homeState";
import {
  ChildNodeProps,
  CustomNodeProps,
  IconMapping,
  NODE_IMAGE_CONSTANT,
} from "../../../utilities/flowBuilder.utility";
import { AnalyticsCardRenderer, ChildNodes } from "../customNode";
import { NodeStyle } from "../style";
import { useAppSelector } from "../../../state/store";
import { AiNodeStyle } from "./style";
import { borderColorBasedOnSubType } from "../util";
import { useDetectAnalytics } from "../useDetectAnalytics";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useRetryHook from "../retryHook";
import { AnalyticsToPercentageConverter, downloadNodeLevelAnalytics } from "../../../services/helpers/AnalyticsHelper";
import { generateCSV } from "../../../utilities/fileDownload";
import { useDispatch } from "react-redux";
import { notificationDataStateActions } from "../../../state/notificationDataState";
import { captureErrorToSentry } from "../../../utilities/sentryHelper";
import ErrorReport from "../../../icons/errorReport";
import {SummaryAnalyticsViewTabName} from '../../../components/header/SummaryAnalytics';
import { AmplitudeEventStep, AmplitudeKeys, TargetPlatform } from "../../../utilities/AnalyticsTypes";
import { RecordEventHelper } from "../../../utilities/RecordEventHelpers";

const AINode = (props: CustomNodeProps) => {
  const {
    nodeData,
    leftHandlerStyle,
    isConnectable,
    getIcon,
    editNode,
    onHover,
  } = props;
  const isAnalytics = useDetectAnalytics();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isErrorDownloading, setIsErrorDownloading] = useState<boolean>(false);
  const [isRetrying, setIsRetrying] = useState<boolean>(false);
  const location = useLocation();
  const homeState = useAppSelector((state) => state.homeState);
  const isSSO = homeState.userData?.loginType === "sso";
  const { retry } = useRetryHook();
  const dispatcher = useDispatch();
  const [aiAnalytics, setAiAnalytics] = useState<{ [key: string]: string }>({});

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
      updateNodeInternals(props?.nodeData.nodeId)
  }, [props?.nodeData.childNodes])

  const getEmptyNodeDesc = (subType: string) => {
    if (subType === "ai_classify") {
      return "Click for classification"
    } else if(subType === "ai_product_recommend"){
      return "Click for recommendations"
    } else {
      return `Click to add ${subType?.split("_")?.at(-1)}`
    }
  }

  useEffect(() => {
    if (homeState?.analyticsData?.nodeAnalytics) {
      const triggered = homeState?.analyticsData?.nodeAnalytics?.[nodeData.nodeId]?.triggered_count;
      const executed = homeState?.analyticsData?.nodeAnalytics?.[nodeData.nodeId]?.executed_count;
      setAiAnalytics({
        Triggered: triggered > 0 ? triggered : "--",
        Success: (executed / triggered) * 100 > 100 ? "100%" : (executed / triggered) * 100 > 0 ? `${AnalyticsToPercentageConverter(executed, triggered)}%` : "--",
      })
    }
  }, [nodeData, homeState, location]);

  const handleNLADownload = async (e: any, isError = false) => {
    e.stopPropagation();
    if(isError) {
      setIsErrorDownloading(true);
    } else {
      setIsDownloading(true);
    }
    const conditions: { [key: string]: string } = {};
    nodeData?.childNodes?.forEach((item: any) => {
      conditions[item?.buttonId] = item?.name;
    });
    const payload: any = {
      storeId: homeState.storeId,
      flowId: homeState.flowId,
      startDate: homeState.analyticsData?.startDate,
      endDate: homeState.analyticsData?.endDate,
      channel: homeState.channel,
      nodeId: nodeData.nodeId,
      nodeSubType: nodeData.subType,
      nodeType: nodeData.type,
      conditions: conditions,
      basedOnTriggeredDate:
        homeState?.analyticsData?.selectedView ===
        SummaryAnalyticsViewTabName.FUNNEL_VIEW,
    };
    if(isError) {
      payload.onlyErrorReport = true;
    }
    const response = await downloadNodeLevelAnalytics(payload);
    if (response) {
      generateCSV(response, `${isError ? 'error_' : ''}${homeState.flowId}_${nodeData.nodeId}_${nodeData.subType}`)
      const eventHelper = RecordEventHelper.getInstance();
      eventHelper.trackEvent(
        TargetPlatform.Amplitude,
        AmplitudeKeys.service_used,
        {
          step: isError
            ? AmplitudeEventStep.error_report_downloaded
            : AmplitudeEventStep.node_analytics_downloaded,
          screen: 'journey_builder_analytics',
        }
      );
    }
    if(isError) {
      setIsErrorDownloading(false);
    } else {
      setIsDownloading(false);
    }
  };

  const retryNLA = async () => {
    setIsRetrying(true)
    const response = await retry(nodeData);
    if (!response) {
      dispatcher(
        notificationDataStateActions.setNotifications({
          successMessage: "",
          errorMessage: "Something went wrong",
        })
      );
    } else {
      dispatcher(
        notificationDataStateActions.setNotifications({
          successMessage: "Retry Success",
          errorMessage: "",
        })
      );
    }
    setIsRetrying(false)
  }

  const approve = () => {
    if (homeState.retryAccess) {
      retryNLA().then().catch((e) => {
        captureErrorToSentry(e, `Something went wrong conditionNode`);
      });
    } else {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          showPwModal: true
        })
      );
    }
  }

  return (
    <>
      <AiNodeStyle>
        {nodeData?.description && (
          <Handle
            id="target"
            type="target"
            position={Position.Left}
            style={leftHandlerStyle}
            isConnectable={isConnectable}
          />
        )}
        <NodeStyle
          className="customNode flex--column"
          onMouseEnter={() => onHover(props?.id, nodeData?.subType)}
          onMouseLeave={() => onHover(null, undefined)}
          onClick={() =>


            editNode(
              nodeData?.type,
              props?.id,
              nodeData?.subType,
              nodeData?.nodeIndex,
              nodeData?.automationChannel
            )
          }
          nodeColor={COLORS.stroke.ai.lightAlt}
        >
          <div className="customNode__header flex justify-content-between align-items-center">
            <div className="flex">
              <span className="customNode__header__icon icon-wrapper">
                {IconMapping(nodeData?.subType, homeState?.channel!)}
              </span>
              <div style={{ width: 15 }} />
              <span className="customNode__header__title">
                {nodeData?.title || nodeData?.subType}
              </span>
            </div>
            {isAnalytics && (
              <div className={"ml-auto d-flex"}>
                {isSSO && (
                  <div>
                    {isRetrying ? (
                      <div style={{width: 32}}>
                        <Spinner size="small" color={COLORS.content.primary} />
                      </div>
                    ) : (
                      <IconButton Icon={RefreshIcon} style={{ color: "#919191", display: 'flex' }} onClick={approve} />
                    )}

                  </div>
                )}
                <div>
                  {isDownloading ? (
                    <div style={{width: 32}}>
                      <Spinner size="small" color={COLORS.content.primary} />
                    </div>
                  ) : (
                    /* @ts-ignore */
                    <Tooltip delay={[200,0]} body="Download node analytics" placement="right">
                      <IconButton
                        Icon={DownloadIcon}
                        style={{ color: "#919191", display: "flex" }}
                        onClick={handleNLADownload}
                      />
                    </Tooltip>
                  )}
                </div>
                <div>
                  {isErrorDownloading ? (
                    <div style={{width: 32}}>
                      <Spinner size="small" color={COLORS.content.primary} />
                    </div>
                  ) : (
                    /* @ts-ignore */
                    <Tooltip delay={[200,0]} body="Download error report" placement="right">
                      <IconButton
                        Icon={ErrorReport}
                        style={{ color: "#919191", display: "flex" }}
                        onClick={(e) => handleNLADownload(e, true)}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="customNode__body flex--column">
            {nodeData?.media && (
              <div className="customNode__body__image flex">
                {NODE_IMAGE_CONSTANT[nodeData?.media]}
              </div>
            )}
            {!nodeData?.description && !!!nodeData?.childNodes?.length && (
              <div className="customNode__body__no-content flex">
                {`${getEmptyNodeDesc(nodeData?.subType)}`}
              </div>
            )}
            {nodeData?.description && (
              <div className="customNode__body__description">
                <span>
                  {nodeData?.description?.split("\\n").map((str: string) => (
                    <p style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                      {str}
                    </p>
                  ))}
                </span>
              </div>
            )}
            {nodeData?.slang && (
              <div className="customNode__body__slang">{nodeData?.slang}</div>
            )}
          </div>
          {!!nodeData?.childNodes?.length && (
            <div className="customNode__footer">
              <div className="customNode__child-nodes flex--column">
                {nodeData.childNodes.map(
                  (child: ChildNodeProps, index: number) => (
                    <div className="customNode__childNodes">
                      <ChildNodes
                        key={child.name}
                        name={child.name}
                        buttonId={child.buttonId}
                        index={index}
                        connectorColor={nodeData?.connectorColor}
                        nodeType={"Condition"}
                        count={nodeData.childNodes.length}
                        isConnectable={child?.isConnectable}
                        nodeId={nodeData?.nodeId}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </NodeStyle>
        {isAnalytics && AnalyticsCardRenderer(aiAnalytics, "100%", homeState?.analyticsData?.shimmer)}
      </AiNodeStyle>
    </>
  );
};

export default AINode;
