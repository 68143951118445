import React, { ReactElement } from "react";
import ActionIcon from "../icons/actionIcon";
import ActionNodeIcon from "../icons/actionNodeIcon";
import ConditionIcon from "../icons/conditionIcon";
import ConditionNodeIcon from "../icons/conditionNodeIcon";
import ImageIcon from "../icons/imageIcon";
import MessageIcon from "../icons/messageIcon";
import MessageNodeIcon from "../icons/messageNodeIcon";
import MessageProviderIcon from "../icons/MessageProviderIcon";
import { MessageProviderNodeIcon } from "../icons/MessageProviderNodeIcon";
import TagNodeIcon from "../icons/tagNodeIcon";
import TriggerNodeIcon from "../icons/triggerNodeIcon";
import { AddNodeDataProps } from "../ui-components/add-node-options/addNodePopover";
import MessageBlock from "../icons/messageBlock";
import List from "../icons/list";
import Evaluate from "../icons/equalTo";
import FreshdeskCreateTicketIcon from "../icons/freshdeskCreateTicketIcon";
import RazorpayCreateTicketIcon from "../icons/razorpayCreateTicketIcon";
import VideoIcon from "../icons/video";
import DocIcon from "../icons/docIcon";
import AudioIcon from "../icons/audioIcon";
import ShopifyAddTagIcon from "../icons/shopifyAddTagIcon";
import StartFlowIcon from "../icons/startFlow";
import AssignAgentIcon from "../icons/addPersonIcon";
import AddEvent from "../icons/addEvent";
import Filter from "../icons/filter";
import DelayIcon from "../icons/delayIcon";
import PreventBacktrackingIcon from "../icons/preventBacktrackingIcon";
import ABTestingIcon from "../icons/ABTestingIcon";
import CallApi from "../icons/callApi";
import StoreVariable from "../icons/storeVariable";
import TemplateBlock from "../icons/templateBlock";
import CatalogList from "../icons/customDomain";
import QuestionIcon from "../icons/questionIcon";
import MultiProductIcon from "../icons/multiProductIcon";
import Box from "../icons/box";
import OrdersList from "../icons/ordersList";
import Catalog from "../icons/catalog";
import Person from "../icons/name";
import Email from "../icons/email";
import Phone from "../icons/phone";
import Carousel from "../icons/carousal";
import SmsIcon from "../icons/sms";
import BackTrack from "../icons/backtracking";
import { JudgeMe } from "../icons/judgeme";
import { CsatIcon } from "../icons/csat";
import StickyNoteIcon from "../icons/stickyNotesIcon";
import Cart from "../icons/cart";
import GoogleSheet from "../icons/google_sheet";
import TagIcon from "../icons/tagIcon";
import { COLORS } from "@bikdotai/bik-component-library";
import GoswiftIcon from "../icons/goswiftIcon";
import BrowsingFlowIcon from "../icons/browsingFlowIcon";
import GmailIcon from "../icons/gmailIcon";
import TicketIcon from "../icons/ticketIcon";
import EvaluateIcon from "../icons/evaluate";
import ProductCardIcon from "../icons/productCard";
import ShopifyDiscountCodeIcon from "../icons/shopifyDiscountCodeIcon";
import ShopifyOrderNoteIcon from "../icons/shopifyOrderNoteIcon";
import ShopifyCustomerTagIcon from "../icons/shopifyCustomerTagIcon";
import GoogleSheetAdd from "../icons/google_sheet_add";
import GoogleSheetUpdate from "../icons/google_sheet_update";
import GoogleSheetGet from "../icons/google_sheet_add copy";
import OtherActionIcon from "../icons/OtherActionIcon";
import WorkingHourIcon from "../icons/workingHours";
import MediaMessage from "../icons/mediaMessage";
import OptInOut from "../icons/optInOut";
import Reply from "../icons/reply";
import SparkleIcon from "../icons/SparkleIcon";
import ClassifyIcon from "../icons/classifyIcon";
import ProductRecommendIcon from "../icons/productRecommend";
import AiReplyIcon from "../icons/aiReply";

export class TriggerNode {
  nodeId: string = "";
  conditions: Array<any> = [
    {
      operator_values: {},
    },
  ];
  type: string = "trigger";
}

export interface CustomNodeIconProps {
  backgroundColor?: string;
  color?: string;
  height?: string;
  width?: string;
  style?: { [key: string]: string | number };
  showBorder?: boolean;
}

export const CREATE_NODE_DATA: AddNodeDataProps[] = [
  {
    icon: <TriggerNodeIcon />,
    name: "Trigger",
    type: "Trigger",
    connectorColor: "#616161",
  },
  {
    icon: <ConditionNodeIcon />,
    name: "Condition",
    type: "Condition",
    connectorColor: "#616161",
  },
  {
    icon: <MessageNodeIcon />,
    name: "Message",
    type: "Message",
    connectorColor: "#616161",
  },
  {
    icon: <MessageNodeIcon />,
    name: "Message (Instagram)",
    type: "Message (Instagram)",
    connectorColor: "#616161",
  },
  {
    icon: <SparkleIcon />,
    name: "AI",
    type: "AI",
    connectorColor: "#616161",
  },
  {
    icon: <ActionNodeIcon />,
    name: "Action",
    type: "Action",
    connectorColor: "#616161",
  },
  {
    icon: <MessageProviderNodeIcon />,
    name: "Single Product Message",
    type: "Single Product Message",
    connectorColor: "#616161",
  },
  {
    icon: <MessageProviderNodeIcon />,
    name: "Multi Product Message",
    type: "Multi Product Message",
    connectorColor: "#616161",
  },
  {
    icon: <StickyNoteIcon />,
    name: "Sticky notes",
    type: "Sticky notes",
    connectorColor: "#616161",
  },
];

export const NODE_IMAGE_CONSTANT: { [key: string]: React.ReactElement } = {
  image: <ImageIcon />,
  video: <VideoIcon />,
  doc: <DocIcon />,
  audio: <AudioIcon />,
};

export const NODE_COLOR: { [key: string]: string } = {
  Action: "#FFCED4",
  Condition: "#FFEABE",
  Trigger: "#EFE6F9",
  Message: "#C3E4C4",
  AI: COLORS.stroke.ai.vibrant,
};

export interface ChildNodeProps {
  name: string;
  buttonId: string;
  index: number;
  connectorColor?: string;
  nodeType?: string;
  count: number;
  isConnectable?: boolean;
  subType?: string;
  childNodeCount?: number;
  title?: string;
  imageUrl?: string;
  parentIndex?: number;
  value?: string;
  ctaValue?: any;
  noButtons?: boolean;
  nodeId?: string;
  description?: string;
  type?: string;
}

export interface CustomNodeProps {
  id: string;
  nodeData: any;
  leftHandlerStyle: { [key: string]: string | number };
  rightHandlerStyle: { [key: string]: string | number };
  isConnectable: boolean;
  getIcon: (nodeName: string) => React.ReactNode;
  editNode: (
    nodeType: string,
    nodeId: string,
    nodeSubType: string,
    nodeIndex: number,
    automationChannel: string
  ) => void;
  onHover: (nodeId: string | null, subType: string | undefined) => void;
}

export const IconMapping = (subType: string, channel: string) => {

  const CHANNEL_SPECIFIC_COLOR = channel === "instagram" ? '#510058' : COLORS.content.positive;
  // Action Icons
  const mapping: { [key: string]: ReactElement } = {
    start_flow: <StartFlowIcon color={COLORS.content.negative} />,
    add_tags: <TagNodeIcon color={COLORS.content.negative} />,
    assign_to_agent: <AssignAgentIcon color={COLORS.content.negative} />,
    assign_ticket: <TicketIcon color={COLORS.content.negative} />,
    add_event: <AddEvent color={COLORS.content.negative} />,
    filter: <Filter color={COLORS.content.warning} />,
    broadcast_events: <OptInOut color={COLORS.content.negative} />,
    delay: <DelayIcon color={COLORS.content.warning} />,
    prevent_backtracking: <BackTrack />,
    store_variable: <StoreVariable color={COLORS.content.negative} />,
    evaluate: <EvaluateIcon />,
    ab_test: <ABTestingIcon color={COLORS.content.warning} />,
    call_api: <CallApi color={COLORS.content.negative} />,
    call_custom_api: <OtherActionIcon color={COLORS.content.negative} />,
    shopify_add_tags: <ShopifyAddTagIcon />,
    shopify_add_notes: <ShopifyOrderNoteIcon />,
    create_ticket_on_freshdesk: <FreshdeskCreateTicketIcon />,
    create_payment_link_on_Razorpay: <RazorpayCreateTicketIcon />,
    create_payment_link_on_Goswift: <GoswiftIcon />,
    discount_code: <ShopifyDiscountCodeIcon />,
    send_email: <Email />,
    send_sms: <SmsIcon />,
    // Message Icons
    message: <MessageBlock color={CHANNEL_SPECIFIC_COLOR} />,
    template: <TemplateBlock color={CHANNEL_SPECIFIC_COLOR} />,
    list: <List color={CHANNEL_SPECIFIC_COLOR} />,
    multi_product: <MultiProductIcon color={COLORS.content.positive} />,
    single_product: <Box color={CHANNEL_SPECIFIC_COLOR} />,
    product_card: <ProductCardIcon color={COLORS.content.positive} />,
    cart_info: <Cart color={COLORS.content.positive} />,
    question: <QuestionIcon color={CHANNEL_SPECIFIC_COLOR} />,
    catalog: <Catalog color={COLORS.content.positive} />,
    catalog_list: <CatalogList color={CHANNEL_SPECIFIC_COLOR} />,
    order_list: <OrdersList color={CHANNEL_SPECIFIC_COLOR} />,
    add_customer_tags: <ShopifyCustomerTagIcon />,
    ask_name: <Person color={CHANNEL_SPECIFIC_COLOR} />,
    ask_email: <Email color={CHANNEL_SPECIFIC_COLOR} />,
    ask_phone: <Phone color={CHANNEL_SPECIFIC_COLOR} />,
    // Condition Icons
    Condition: <ConditionIcon color={COLORS.content.warning} />,
    // Trigger Icon
    Trigger: <TriggerNodeIcon color={COLORS.content.brand} />,
    starting_point: <TriggerNodeIcon color={COLORS.content.brand} />,
    carousel: <Carousel />,
    product_carousel: <Box color={"#510058"} />,
    instagram_qrb: <MessageBlock color={"#510058"} />,
    instagram_media: <MediaMessage color={"#510058"} />,
    instagram_text: <MessageIcon color={"#510058"} />,
    judgeme: <JudgeMe />,
    "CSAT/NPS": <CsatIcon color={CHANNEL_SPECIFIC_COLOR} />,
    email_csat: <Email color={"#F00"} />,
    CSAT: <CsatIcon color={CHANNEL_SPECIFIC_COLOR} />,
    rest_api: <CallApi color={COLORS.content.negative} />,
    call_external_api: <CallApi color={COLORS.content.negative} />,
    sticky_notes: <StickyNoteIcon />,
    email_temp: <Email />,
    email_temp_new: <Email color={'#F00'} />,
    gmail_reply: <Reply />,
    google_sheet_add_row: <GoogleSheetAdd />,
    google_sheet_update_row: <GoogleSheetUpdate />,
    google_sheet_get_row_data: <GoogleSheetGet />,
    browse_collection: <BrowsingFlowIcon color={CHANNEL_SPECIFIC_COLOR} />,
    add_ticket_label: (
      <TagIcon
        color={COLORS.content.negative}
        backgroundColor={COLORS.background.negative.light}
      />
    ),
    change_ticket_stage: <TicketIcon color={COLORS.content.negative} />,
    check_working_hours: <WorkingHourIcon color={COLORS.content.negative} />,
    sms_block: <SmsIcon />,
    ai_classify: <ClassifyIcon />,
    ai_product_recommend: <ProductRecommendIcon />,
    ai_reply: <AiReplyIcon />,
    wa_carousel: <Carousel color={COLORS.background.positive.vibrant}/>,
  };
  return mapping[subType];
};

export interface StickyNotesInterface {
  header?: string;
  content: string;
  createdDate: string | null;
  updatedDate: string | null;
  nodeId: string;
}

export class NodeConfig {
  nodeId: string = "";
  nodeIndex?: number = 0;
  type: string = "";
  subType: string = "";
  isMicroFlow: boolean = false;
  title?: string = "";
  description: string = "";
  slang?: string = "";
  media?: string = "";
  header?: string = "";
  automationChannel?: string = "";
  childNodes: { [key: string]: any } = {};
  dripNodes: { [key: string]: any } = {};
  mediaUrl?: string = "";
}

export interface NodeDataInterface {
  conf: NodeConfig;
  nodeId: string;
  subtype: string;
}

export const TriggerTypesTesting = [
  {
    id: "whatsAppCart",
    header: "WhatsApp Cart",
  },
  {
    id: "storyReply",
    header: "Story Reply",
  },
  {
    id: "storyMention",
    header: "Story Mention",
  },
  {
    id: "instaComments",
    header: "Instagram comments",
  },
  {
    id: "rewardDisbursed",
    header: "Reward Disbursed",
  },
];

export const TriggerTypes = [
  {
    id: "orderCreated",
    header: "Order Created",
  },
  {
    id: "abandonedCart",
    header: "Abandoned Cart",
  },
  {
    id: "orderUpdate",
    header: "Order Update",
  },
  {
    id: "userCreated",
    header: "User Created",
  },
  {
    id: "lineItemShippingUpdate",
    header: "Line Item Shipping Update",
  },
  {
    id: "paymentLinkUpdate",
    header: "Payment Link Update",
  },
  {
    id: "whatsAppCart",
    header: "WhatsApp Cart",
  },
  {
    id: "rewardDisbursed",
    header: "Reward Disbursed",
  },
];

export const TriggerTypesInstagram = [
  {
    id: "storyReply",
    header: "Story Reply",
  },
  {
    id: "storyMention",
    header: "Story Mention",
  },
  {
    id: "instaComments",
    header: "Instagram comments",
  },
  {
    id: "paymentLinkUpdate",
    header: "Payment Link Update",
  },
];

export interface SyncTemplateResponse {
  data?: Array<{
    status: number;
    response: {
      id: string;
      message: string;
    };
  }>;
  success?: boolean;
  errors?: Array<{
    code: number;
    message: string;
  }>;
}

export function unCamelCase(str?: string) {
  return (
    str
      // insert a space between lower & upper
      ?.replace(/([a-z])([A-Z])/g, "$1 $2")
      // space before last upper in a sequence followed by lower
      ?.replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
      // uppercase the first character
      ?.replace(/^./, function (st: string) {
        return st.toUpperCase();
      }) ?? ""
  );
}

export function unCamelCaseWithFirstUppercase(str: string) {
  const newStr = unCamelCase(str);
  return newStr[0].toUpperCase() + newStr.slice(1);
}

export function snakeToCamel(str: string) {
  return str.replace(/_([a-zA-Z0-9])/g, function (match, letter) {
    return letter.toUpperCase();
  });
}
