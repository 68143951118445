import { COLORS } from "@bikdotai/bik-component-library";

export const borderColorBasedOnSubType = (
  subType: string,
  channel = "whatsapp"
) => {
  switch (subType) {
    case "single_product":
    case "message":
    case "list":
    case "catalog_list":
    case "template":
    case "wa_carousel":
    case "order_list":
    case "ask_name":
    case "ask_email":
    case "ask_phone":
    case "question":
    case "CSAT/NPS":
    case "product_card":
    case "multi_product":
    case "cart_info":
    case "browse_collection":
      return channel === "instagram"
        ? "#D300C5"
        : COLORS.background.positive.vibrant;
    case "add_event":
    case "broadcast_events":
    case "assign_ticket":
    case "add_ticket_label":
    case "change_ticket_stage":
    case "check_working_hours":
    case "call_external_api":
    case "rest_api":
    case "call_custom_api":
    case "store_variable":
    case "call_api":
    case "start_flow":
      return COLORS.background.negative.vibrant;
    case "send_sms":
      return COLORS.content.blue;
    case "delay":
    case "filter":
    case "ab_test":
    case "Condition":
    case "evaluate":
      return COLORS.stroke.warning.vibrant;
    case "shopify_add_tags":
    case "discount_code":
    case "shopify_add_notes":
    case "add_customer_tags":
      return "#008060";
    case "create_ticket_on_freshdesk":
      return "#25C16F";
    case "create_payment_link_on_Razorpay":
      return "#3395FF";
    case "create_payment_link_on_Goswift":
      return "#4EAC5B";
    case "judgeme":
      return "#308A92";
    case "google_sheet_add_row":
    case "google_sheet_update_row":
    case "google_sheet_get_row_data":
      return "#34A853";
    case "instagram_media":
    case "instagram_text":
    case "carousel":
    case "product_carousel":
    case 'ai_classify':
    case 'ai_product_recommend':
    case 'ai_reply':
      return COLORS.stroke.ai.vibrant;
    case "CSAT":
      return "#D300C5";
    case "trigger_node":
      return "#EFE6F9";
    case "gmail_reply":
    case "email_temp_new":
    case "email_csat":
      return "#F00";
    default:
      return COLORS.background.brand;
  }
};

export const backgroundBasedOnSubType = (
  subType: string,
  channel = "whatsapp"
) => {
  switch (subType) {
    case "single_product":
    case "message":
    case "list":
    case "catalog_list":
    case "template":
    case "wa_carousel":
    case "order_list":
    case "ask_name":
    case "ask_email":
    case "ask_phone":
    case "question":
    case "CSAT/NPS":
    case "product_card":
    case "multi_product":
    case "cart_info":
    case "browse_collection":
      return channel === "instagram"
        ? "#F4E6F1"
        : COLORS.background.positive.light;
    case "add_event":
    case "broadcast_events":
    case "assign_ticket":
    case "add_ticket_label":
    case "change_ticket_stage":
    case "check_working_hours":
    case "call_external_api":
    case "rest_api":
    case "call_custom_api":
    case "store_variable":
    case "call_api":
    case "start_flow":
      return COLORS.background.negative.light;
    case "send_sms":
      return COLORS.background.blue.light;
    case "shopify_add_tags":
    case "discount_code":
    case "shopify_add_notes":
    case "add_customer_tags":
      return "#A5E2B94D";
    case "create_ticket_on_freshdesk":
      return "#DCEEDE";
    case "create_payment_link_on_Razorpay":
      return "#D6EAFF";
    case "create_payment_link_on_Goswift":
      return "#A5E2B94D";
    case "judgeme":
      return "#DCEBF6";
    case "google_sheet_add_row":
    case "google_sheet_update_row":
    case "google_sheet_get_row_data":
      return "#EDF2FA";
    case "delay":
    case "filter":
    case "ab_test":
    case "Condition":
    case "evaluate":
      return COLORS.background.warning.light;
    case "instagram_media":
    case "instagram_text":
    case "carousel":
    case "product_carousel":
    case "CSAT":
      return "#F4E6F1";
    case 'ai_classify':
    case 'ai_product_recommend':
    case 'ai_reply':
      return COLORS.surface.aiLight;
    case "gmail_reply":
    case "email_temp_new":
    case "email_csat":
      return COLORS.surface.hovered;
    default:
      return COLORS.background.brandLight;
  }
};
