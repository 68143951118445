// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import {
  QueryBuilder,
  BaseQueryBuilderPayload,
  Tag,
  StyledModal,
  Button,
  TitleMedium,
  COLORS,
  DropdownPopover,
  TitleSmall,
} from "@bikdotai/bik-component-library";
import {
  getFilters,
  getEventsName,
} from "../../services/helpers/QueryBuilderHelper";
import {
  BuilderContainer,
  InstaDropdownContainer,
  InstaDropdownWrapper,
} from "./styled";
import { QueryValidator } from "./queryValidator";
import { extractHeaderAndSubHeader, populateChannelIdsDropdown } from "./utils";
import { WarnIcon } from "../../icons/warnIcon";
import { FilterActions } from "../../app/action-block/filter/models";
import {
  customValuesQuery,
  generateDummyQueryBuilderResponse,
  GMAIL_LABELS,
} from "../../constants/customQueryBuilderValues";
import { Body, Container, Footer, Header } from "../ig-modals/style";
import WhatsappIcon from "../../icons/whatsappIcon";
import InstagramIcon from "../../icons/instagramIcon";
import ChevronDownIcon from "../../icons/chevron_down";
import { useAppSelector } from "../../state/store";
import EmailIcon from "../../icons/email";
import { prepareBoolean } from "../../app/action-block/filter/utils";

const handleCustomValues = (key: string) => {
  switch (key) {
    case "gmailReceived_labels":
      return generateDummyQueryBuilderResponse(GMAIL_LABELS);
    default:
      return generateDummyQueryBuilderResponse([]);
  }
};

const queryValidator = new QueryValidator();
let builderState = {};
let variableList: any[] = [];

export const eventsTriggerApiHandler = async (
  payload: BaseQueryBuilderPayload<
    | undefined
    | { triggerName: string; propertyName: string; searchKey?: string }
  >,
  storeId: string,
  channel: string,
  category?: string
) => {
  if (!payload.query) {
    const response = await getEventsName({
      storeId,
      token: storeId,
      action: FilterActions.GET_EVENT_NAMES,
      channel,
      category
    });
    if (response.data) {
      return response.data;
    }
  }
  if (payload.query?.triggerName && !payload.query?.propertyName) {
    
    if(payload.query.triggerName === 'whatsAppCart'){
      return {
        data: [],
        status: 200
      }
    }
    
    const response = await getFilters({
      storeId,
      token: storeId,
      action: FilterActions.GET_EVENT_PROPERTY_NAMES,
      eventId: payload.query.triggerName,
    });

    if (response.data) {
      variableList = response.data.map((item) => item.id);
      return response;
    }
  }
  if (
    payload.query?.triggerName &&
    payload.query?.propertyName &&
    !payload.query?.searchKey
  ) {
    const customValueNeeded = customValuesQuery.some((obj) => {
      return (
        obj.triggerName === payload.query?.triggerName &&
        obj.propertyName === payload.query?.propertyName
      );
    });
    if (customValueNeeded) {
      return handleCustomValues(
        `${payload.query?.triggerName}_${payload.query?.propertyName}`
      );
    }
    const response = await getFilters({
      storeId,
      token: storeId,
      action: FilterActions.GET_EVENT_PROPERTY_VALUES,
      eventId: payload.query.triggerName,
      property: payload.query.propertyName,
    });
    if (response.data) {
      return response;
    }
  }
  if (
    payload.query?.triggerName &&
    payload.query?.propertyName &&
    payload.query?.searchKey
  ) {
    const customValueNeeded = customValuesQuery.some((obj) => {
      return (
        obj.triggerName === payload.query?.triggerName &&
        obj.propertyName === payload.query?.propertyName
      );
    });
    if (customValueNeeded) {
      return handleCustomValues(
        `${payload.query?.triggerName}_${payload.query?.propertyName}`
      );
    }
    const response = await getFilters({
      storeId,
      token: storeId,
      action: FilterActions.SEARCH_EVENT_PROPERTY_VALUES,
      eventId: payload.query.triggerName,
      property: payload.query?.propertyName,
      searchText: payload.query?.searchKey,
    });
    if (response.data) {
      return response;
    }
  }
  return { status: 200 };
};

const EventsBuilder: React.FC<{
  visible: boolean;
  onClose: Function;
  onSave: Function;
  nodes: [];
  mode: string;
  storeId: string;
  channel: string;
  currentChannelId?: string;
  category?: string
}> = (props) => {
  const [errorState, setErrState] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [channelIdOptions, setChannelIdOptions] = useState<
    {
      label: string;
      value: string;
      selected: boolean;
    }[]
  >([]);
  const [selectedChannel, setSelectedChannel] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const channels: { [key: string]: any } = useAppSelector(
    (state) => state.flowMetaState.channels!
  );

  console.log('nodes', props.nodes)

  const eventQbRef = useRef();
  const handleModalClose = () => {
    builderState = {};
    setErrState(undefined);
    if (eventQbRef && eventQbRef.current) {
      eventQbRef.current.clearAll("EVENTS_TRIGGER");
    }
    props.onClose();
  };

  const prepareState = (state: any) => {
    const updatedNode = state.nodes.map((node: any) => {
      const properties =
        node.properties
          ?.map((property: any) => prepareBoolean(property))
          .flat() ?? [];
      return { ...node, properties };
    });
    return { ...state, nodes: updatedNode };
  };

  const parseBuilderData = () => {
    if (!selectedChannel?.value) {
      setErrorMsg("Please select an account");
      return;
    }
    if (selectedChannel.value === "ERROR") {
      return;
    }
    let { isError, state } = checkForErros();
    if (isError) return;
    const { id, subHeader } = extractHeaderAndSubHeader(state);
    state = prepareState(state);
    props.onSave({
      ...state,
      header: state.nodes[0].metas[0].value,
      subHeader,
      id,
      type: "event",
      variableList,
      channelId: selectedChannel.value,
    });
    builderState = {};
    props.onClose();
  };

  const checkForErros = () => {
    const errorStateRes = queryValidator.validate({
      include: builderState,
    });
    setErrState({ ...errorStateRes[1].include });
    setErrorMsg(errorStateRes[0]);
    return { isError: errorStateRes[0], state: errorStateRes[1].include };
  };

  useEffect(() => {
    populateChannelIdsDropdown(
      props.channel,
      channels,
      setIsLoading,
      setSelectedChannel,
      setChannelIdOptions,
      props.currentChannelId
    );
  }, []);

  useEffect(() => {
    if(channelIdOptions && channelIdOptions.length===1){
      setSelectedChannel(channelIdOptions[0])
    }

    if (selectedChannel.value === "ERROR" && props?.currentChannelId) {
      setErrorMsg("No account is connected to this trigger. Please check");
    } else {
      setErrorMsg("");
    }
  }, [channelIdOptions, selectedChannel]);

  const getDropDownIcon = (channel: string) => {
    if (channel === "whatsapp") {
      return <WhatsappIcon />;
    } else if (channel === "instagram") {
      return <InstagramIcon />;
    } else {
      return <EmailIcon />;
    }
  };

  const InstaDropdown = () => {
    return (
      <InstaDropdownContainer>
        <DropdownPopover
          width="200px"
          disabled={isLoading}
          onSelect={(c: any) => {
            setSelectedChannel(c);
            const modified = channelIdOptions.map((d) => {
              if (d.value === c.value) {
                return {
                  label: d.label,
                  value: d.value,
                  selected: true,
                };
              } else {
                return {
                  label: d.label,
                  value: d.value,
                  selected: false,
                };
              }
            });
            setChannelIdOptions(modified);
          }}
          options={channelIdOptions}
        >
          <InstaDropdownWrapper>
            {getDropDownIcon(props.channel)}
            <TitleSmall className="dropdown-text" style={{ fontWeight: 400 }}>
              {selectedChannel.label || "Select an account"}
            </TitleSmall>
            <ChevronDownIcon color={COLORS.content.primary} />
          </InstaDropdownWrapper>
        </DropdownPopover>
      </InstaDropdownContainer>
    );
  };

  return (
    <>
      <StyledModal
        zIndex={200}
        centralContainerStyles={{ width: "1032px", height: "560px" }}
        onClose={() => {
          handleModalClose();
        }}
        open={props.visible}
      >
        <Container>
          <div style={{ display: "flex" }}>
            <Header>
              <TitleMedium>{`Create trigger based on events`}</TitleMedium>
            </Header>
            <div
              style={{
                marginTop: 15,
                paddingRight: 15,
                borderBottom: `1px solid ${COLORS.stroke.primary}`,
              }}
            >
              <InstaDropdown />
            </div>
          </div>

          <Body>
            <BuilderContainer>
              <QueryBuilder
                ref={eventQbRef}
                mode={props.mode}
                state={
                  props.nodes && props.nodes.length
                    ? { nodes: props.nodes }
                    : undefined
                }
                selectorKey="eventTrigger"
                addNodeBtnText="Add Trigger"
                queryPreText="Whenever user performs"
                infinite={false}
                onStateChange={(state: any) => {
                  builderState = state;
                }}
                errorState={errorState}
                typesAPIHandlers={{
                  EVENTS_TRIGGER: (
                    payload: BaseQueryBuilderPayload<
                      | { triggerName: string }
                      | {
                          triggerName: string;
                          propertyName: string;
                          searchKey?: string;
                        }
                      | undefined
                    >
                  ) =>
                    eventsTriggerApiHandler(
                      payload,
                      props.storeId,
                      props.channel,
                      props.category
                    ),
                }}
              />
            </BuilderContainer>
          </Body>

          <Footer>
            {errorMsg ? (
              <Tag tagText={errorMsg} type="negative" LeadingIcon={WarnIcon} />
            ) : (
              <div></div>
            )}
            <Button
              buttonText="Create"
              size={"medium"}
              onClick={() => {
                parseBuilderData();
              }}
            />
          </Footer>
        </Container>
      </StyledModal>
    </>
  );
};

export default EventsBuilder;
