import { IntegrationService } from "../../admin/integrations/IntegrationsService";
import {
  RequestExecutor,
  HTTPMethods,
} from "../../api-helpers/RequestExecutor";
import { getIntegrationsEndpoints } from "../../config";
import { FirebaseService } from "../firebase/FirebaseService";

export const parseCollection = (data: any) => {
  const collections = data?.collections || [];
  let collectionsParsed: any = {};

  collections.forEach((item: any) => {
    const id = item?.id?.toString();
    if (id) {
    }
    collectionsParsed[id] = {
      image: item?.image || "",
      name: item?.name || "",
      isSmartCollection: item?.isSmartCollection || false,
    };
  });

  return {
    collections: collectionsParsed,
  };
};

export const fetchCollections = async (
  offset: number,
  storeId: string,
  isNonShopifyStore: boolean = false
) => {
  const url =
    getIntegrationsEndpoints() +
    `/integrationsApiFunctions-executeIntegrationApi`;

  const res: any = await RequestExecutor.fetch({
    apiPath: url,
    apiMethod: HTTPMethods.POST,
    requestBody: {
      storeId: storeId,
      partner: "SHOPIFY",
      code: "fetchCollections",
      limit: 10,
      offset: offset,
      nonShopify: isNonShopifyStore,
    },
  });

  const responseJson = res.data?.data || {};
  const parsedData = parseCollection(responseJson);
  return parsedData;
};

export const parseProducts = (data: any) => {
  const products = data.products || [];
  const productsParsed: any = {};

  products.forEach((product: any) => {
    productsParsed[product.id.toString()] = {
      name: product.name || "",
      image: product.image || "",
      description: product.description || "",
      variants: {},
    };

    product?.combinations?.forEach((combo: any) => {
      const comboName = combo?.name || "";
      productsParsed[product.id.toString()]["variants"][combo.id.toString()] = {
        name: comboName === "Default Title" ? product.name : comboName,
        image:
          comboName === "Default Title" ? product?.image : combo?.image || "",
        price: combo?.discountedPrice || combo?.price,
        quantity: combo?.quantity || 0,
        description: product.name || "",
      };
    });
  });

  return productsParsed;
};

export const fetchProducts = async (
  collectionId: string,
  storeId: string,
  limit?: number,
  offset?: number,
  isNonShopifyStore: boolean = false
) => {
  const url =
    getIntegrationsEndpoints() +
    `/integrationsApiFunctions-executeIntegrationApi`;

  const res: any = await RequestExecutor.fetch({
    apiPath: url,
    apiMethod: HTTPMethods.POST,
    requestBody: {
      storeId: storeId,
      partner: "SHOPIFY",
      code: "fetchProductsForCollection",
      limit: limit,
      offset: offset ?? 0,
      payload: {
        collectionId: collectionId,
        showOutOfStockProducts: true,
      },
      nonShopify: isNonShopifyStore,
    },
  });

  const responseJson = res.data?.data || {};
  return parseProducts(responseJson);
};

export const fbStatusFetcher = async (
  storeId: string,
  variantIds: string[]
) => {
  const url =
    getIntegrationsEndpoints() +
    `/integrationsApiFunctions-executeIntegrationApi`;

  const integrationRepo = new IntegrationService();
  const catalogId = await integrationRepo.fetchCatalogId(storeId);

  const res: any = await RequestExecutor.fetch({
    apiPath: url,
    apiMethod: HTTPMethods.POST,
    requestBody: {
      storeId: storeId,
      partner: "FACEBOOK",
      code: "fetchFbItemErrors",
      payload: {
        catalogId: catalogId,
        productIds: variantIds,
      },
    },
  });

  const responseJson = res.data?.data || {};
  const variants: any = {};

  if (responseJson?.length > 0) {
    responseJson?.forEach((resp: any) => {
      const variantId = resp?.["retailer_id"];
      const error = resp?.["review_rejection_reasons"] || resp?.["errors"];
      if (variantId) {
        if (error) {
          variants[variantId] = "REJECTED";
        } else {
          variants[variantId] = "APPROVED";
        }
      }
    });
  }

  variantIds.forEach((variant) => {
    if (!variants[variant]) {
      variants[variant] = "APPROVED";
    }
  });

  return variants;
};

export const searchInCollection = async (
  storeId: string,
  collectionId: string,
  query: string,
  isNonShopifyStore: boolean = false
) => {
  const url =
    getIntegrationsEndpoints() +
    `/integrationsApiFunctions-executeIntegrationApi`;

  const res: any = await RequestExecutor.fetch({
    apiPath: url,
    apiMethod: HTTPMethods.POST,
    requestBody: {
      storeId: storeId,
      partner: "SHOPIFY",
      code: "searchProductsForCollection",
      payload: {
        collectionsId: collectionId,
        showOutOfStockProducts: true,
        query,
      },
      nonShopify: isNonShopifyStore,
    },
  });

  const responseJson = res.data?.data || {};
  return await fetchAndParseSearchedProducts(storeId, responseJson);
};

export const fetchAndParseSearchedProducts = async (
  storeId: string,
  data: any
) => {
  const productIds: string[] = (data?.products || []).map(
    (product: any) => `0_${product.id.toString()}`
  );
  const firebaseservice = new FirebaseService("", "");
  const productsFromDb = await firebaseservice.getProductsByIds(
    storeId,
    productIds
  );
  return parseProducts({ products: productsFromDb });
};

export const searchProducts = async (storeId: string, query: string) => {
  const url =
    getIntegrationsEndpoints() +
    `/integrationsApiFunctions-executeIntegrationApi`;

  const res: any = await RequestExecutor.fetch({
    apiPath: url,
    apiMethod: HTTPMethods.POST,
    requestBody: {
      storeId: storeId,
      partner: "SHOPIFY",
      code: "searchProducts",
      payload: {
        query,
      },
    },
  });

  const responseJson = res.data?.data || {};
  return parseCollectionsAndProducts(responseJson);
};

export const searchCollectionsAndProducts = async (
  storeId: string,
  query: string,
  isNonShopifyStore: boolean = false
) => {
  const url =
    getIntegrationsEndpoints() +
    `/integrationsApiFunctions-executeIntegrationApi`;

  const res: any = await RequestExecutor.fetch({
    apiPath: url,
    apiMethod: HTTPMethods.POST,
    requestBody: {
      storeId: storeId,
      partner: "SHOPIFY",
      code: "searchCollectionsAndProducts",
      payload: {
        query,
      },
      nonShopify: isNonShopifyStore,
    },
  });

  const responseJson = res.data?.data || {};
  const parsedProducts = parseCollectionsAndProducts(responseJson);
  const parsedCollections = parseCollection(responseJson);

  return {
    collections: parsedCollections.collections,
    products: parsedProducts,
  };
};

export const parseCollectionsAndProducts = (data: any) => {
  const products = data.products || [];
  const collectionAndProductsParsed: any = {};

  for (let item of products) {
    const collection = item.catalogs?.[0] ?? {
      id: "-1",
      name: "All Products",
    };
    const productId = item.id;

    if (!(collection.id in collectionAndProductsParsed)) {
      collectionAndProductsParsed[collection.id] = {
        products: {},
        name: collection.name || "",
        image: "",
        isSmartCollection: false,
      };
    }

    if (!(productId in collectionAndProductsParsed[collection.id].products)) {
      collectionAndProductsParsed[collection.id].products[productId] = {
        variants: {},
        name: item.name || "",
        image: item.image || "",
      };
    }

    item?.combinations?.forEach((combo: any) => {
      const comboName = combo?.name || "";
      collectionAndProductsParsed[collection.id].products[productId][
        "variants"
      ][combo.id.toString()] = {
        name: comboName === "Default Title" ? item.name : comboName,
        image: comboName === "Default Title" ? item?.image : combo?.image || "",
        price: combo?.price,
        quantity: combo?.quantity || 0,
      };
    });
  }
  return collectionAndProductsParsed;
};
