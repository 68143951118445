import { SideModalProps } from "../side-modal/sideModal";
import MessageBlock from "../../icons/messageBlock";
import List from "../../icons/list";
import OrdersList from "../../icons/ordersList";
import Person from "../../icons/name";
import Email from "../../icons/email";
import Phone from "../../icons/phone";
import Catalog from "../../icons/catalog";
import Box from "../../icons/box";
import AssignAgentIcon from "../../icons/addPersonIcon";
import AddEvent from "../../icons/addEvent";
import Filter from "../../icons/filter";
import ShopifyAddTagIcon from "../../icons/shopifyAddTagIcon";
import FreshdeskCreateTicketIcon from "../../icons/freshdeskCreateTicketIcon";
import RazorpayCreateTicketIcon from "../../icons/razorpayCreateTicketIcon";
import Evaluate from "../../icons/evaluate";
import StoreVariable from "../../icons/storeVariable";
import CallApi from "../../icons/callApi";
import TemplateBlock from "../../icons/templateBlock";
import CatalogList from "../../icons/customDomain";
import MultiProductIcon from "../../icons/multiProductIcon";
import DelayIcon from "../../icons/delayIcon";
import QuestionIcon from "../../icons/questionIcon";
import { JudgeMe } from "../../icons/judgeme";
import Cart from "../../icons/cart";
import Carousel from "../../icons/carousal";
import GoogleSheet from "../../icons/google_sheet";
import SmsIcon from "../../icons/sms";
import TagIcon from "../../icons/tagIcon";
import { COLORS } from "@bikdotai/bik-component-library";
import GoswiftIcon from "../../icons/goswiftIcon";
import BrowsingFlowIcon from "../../icons/browsingFlowIcon";
import GmailIcon from "../../icons/gmailIcon";
import TicketIcon from "../../icons/ticketIcon";
import ABTestingIcon from "../../icons/ABTestingIcon";
import ConditionIcon from "../../icons/conditionIcon";
import { CsatIcon } from "../../icons/csat";
import ProductCardIcon from "../../icons/productCard";
import WorkingHourIcon from "../../icons/workingHours";
import ShopifyDiscountCodeIcon from "../../icons/shopifyDiscountCodeIcon";
import ShopifyOrderNoteIcon from "../../icons/shopifyOrderNoteIcon";
import ShopifyCustomerTagIcon from "../../icons/shopifyCustomerTagIcon";
import GoogleSheetAdd from "../../icons/google_sheet_add";
import GoogleSheetUpdate from "../../icons/google_sheet_update";
import GoogleSheetGet from "../../icons/google_sheet_add copy";
import OtherActionIcon from "../../icons/OtherActionIcon";
import Reply from "../../icons/reply";
import MediaMessage from "../../icons/mediaMessage";
import MessageIcon from "../../icons/messageIcon";
import OptInOut from "../../icons/optInOut";
import ClassifyIcon from "../../icons/classifyIcon";
import ProductRecommendIcon from "../../icons/productRecommend";
import AiReplyIcon from "../../icons/aiReply";

export const sideModalConfigsWhatsapp: {
  [key: string]: Partial<SideModalProps>;
} = {
  Message: {
    header: "Message blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic message",
        listOfBlocks: [
          {
            blockIcon: (
              <MessageBlock color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Message",
            blockId: "message",
            description: "Send a non templated Whatsapp message",
          },
          {
            blockIcon: (
              <TemplateBlock color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Template",
            blockId: "template",
            description: "Select from a list of approved Whatsapp templates",
          },
          {
            blockIcon: <Email color={"#FF0000"} />,
            blockName: "Send Email",
            blockId: "email_temp_new",
            automationChannel: "WHATSAPP",
            description: "Select from a list of email templates",
          },
          {
            blockIcon: <SmsIcon />,
            blockName: "Send SMS",
            blockId: "send_sms",
            automationChannel: "WHATSAPP",
            description: "Select from a list of SMS templates. Requires SMS service provider integration",
          },
          {
            blockIcon: <Carousel color={COLORS.background.positive.vibrant}/>,
            blockName: "Carousel",
            blockId: "wa_carousel",
            automationChannel: "WHATSAPP",
            description: "Carousel",
          },
        ],
      },
      {
        subHeader: "List message",
        listOfBlocks: [
          {
            blockIcon: <List color={COLORS.background.positive.vibrant} />,
            blockName: "List",
            blockId: "list",
            description: "Send a Whatsapp list message",
          },
          {
            blockIcon: (
              <CatalogList color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Catalog list",
            blockId: "catalog_list",
            description: "Send a list of products as Whatsapp list message",
          },
          {
            blockIcon: (
              <OrdersList color={COLORS.background.positive.vibrant} />
            ),
            blockName: "List of orders",
            blockId: "order_list",
            description: "Send a list of latest orders (upto 10)",
          },
        ],
      },
      {
        subHeader: "Ask customers",
        listOfBlocks: [
          {
            blockIcon: <Person color={COLORS.background.positive.vibrant} />,
            blockName: "Name",
            blockId: "ask_name",
            automationChannel: "WHATSAPP",
            description: "Ask and save customer name",
          },
          {
            blockIcon: <Email color={COLORS.background.positive.vibrant} />,
            blockName: "Email",
            blockId: "ask_email",
            automationChannel: "WHATSAPP",
            description: "Ask and save customer email id",
          },
          {
            blockIcon: <Phone color={COLORS.background.positive.vibrant} />,
            blockName: "Phone number",
            blockId: "ask_phone",
            automationChannel: "WHATSAPP",
            description: "Ask and save customer phone number",
          },
          {
            blockIcon: (
              <QuestionIcon color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Question",
            blockId: "question",
            automationChannel: "WHATSAPP",
            description: "Ask and save any question like pincode, images, video, documents from customer",
          },
          // {
          //   blockIcon: <Location />,
          //   blockName: "Address",
          //   blockId: "Address",
          // },
          // {
          //   blockIcon: <OrdersList />,
          //   blockName: "Review",
          //   blockId: "Review",
          // },
          // {
          //   blockIcon: <Upload />,
          //   blockName: "Upload Media",
          //   blockId: "Upload Media",
          // },
          {
            blockIcon: <CsatIcon />,
            blockName: "CSAT/NPS",
            blockId: "CSAT/NPS",
            automationChannel: "WHATSAPP",
            description: "Send NPS or CSAT surveys (1-3,1-5 or 1-10 scale)",
          },
          {
            blockIcon: <Email color={"#FF0000"} />,
            blockName: "Email CSAT/NPS",
            blockId: "email_csat",
            automationChannel: "WHATSAPP",
            description:
              "Send NPS or CSAT surveys for Email (1-3,1-5 or 1-10 scale)",
          },
        ],
      },
      {
        subHeader: "Product and cart",
        listOfBlocks: [
          // {
          //   blockIcon: <Catalog />,
          //   blockName: "Catalog",
          //   blockId: "catalog",
          // },
          {
            blockIcon: <Box color={COLORS.background.positive.vibrant} />,
            blockName: "Single Product",
            blockId: "single_product",
            description: "Send a single whatsapp catalog message",
          },
          {
            blockIcon: (
              <MultiProductIcon color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Multi Product",
            blockId: "multi_product",
            description: "Send a multiple whatsapp catalog message",
          },
          {
            blockIcon: <Cart color={COLORS.background.positive.vibrant} />,
            blockName: "Cart info",
            blockId: "cart_info",
            description: "Send details of the cart created by user as part of end to end checkout flows",
          },
          {
            blockIcon: <ProductCardIcon />,
            blockName: "Product Card",
            blockId: "product_card",
            description: "Send a single product message with image, description and URL",
          },
          {
            blockIcon: <BrowsingFlowIcon />,
            blockName: "Browse Catalog",
            blockId: "browse_collection",
            description: "Set up browsing flows with products and collections",
          },
          // {
          //   blockIcon: <Cart />,
          //   blockName: "Cart",
          //   blockId: "Cart",
          // },
        ],
      },
    ],
  },
  AI: {
    header: "AI blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic Message",
        listOfBlocks: [
          {
            blockIcon: <ClassifyIcon />,
            blockName: "AI Classify",
            blockId: "ai_classify",
            description: "Classify customer messages using AI",
          },
          {
            blockIcon: <AiReplyIcon />,
            blockName: "AI Reply",
            blockId: "ai_reply",
            description: "Reply to customer messages using AI",
          },
          {
            blockIcon: <ProductRecommendIcon />,
            blockName: "AI Product Recommend",
            blockId: "ai_product_recommend",
            description: "Products recommended using AI",
          },

        ],
      },
    ],
  },
  Action: {
    header: "Action blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic actions",
        listOfBlocks: [
          {
            blockIcon: <AddEvent color={COLORS.background.negative.vibrant} />,
            blockName: "Add Event",
            blockId: "add_event",
            description: "Add event in BIK data platform which can be then later used for analytics or segmentation"
          },

          {
            blockIcon: <OptInOut color={COLORS.background.negative.vibrant} />,
            blockName: "Opt In/Out",
            blockId: "broadcast_events",
            description: "Take an action to opt in / out a customer from Whatsapp. Email unsubscribe happens on link click"
          },
          {
            blockIcon: (
              <TicketIcon color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Assign Ticket",
            blockId: "assign_ticket",
            description: "Assign tickets to a specific agent or team with a label (optional)"
          },
          {
            blockIcon: <TagIcon color={COLORS.background.negative.vibrant} />,
            blockName: "Add Ticket Label",
            blockId: "add_ticket_label",
            description: "Add a label to tickets",
          },
          {
            blockIcon: (
              <TicketIcon color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Change Ticket Stage",
            blockId: "change_ticket_stage",
            description: "Change the ticket stage to close/pending or any custom stage as defined",
          },
          {
            blockIcon: <WorkingHourIcon />,
            blockName: "Working Hour Check",
            blockId: "check_working_hours",
            description: "Check if the time falls within working hour and accordingly define actions such as agent assign etc. "
          },
          // {
          //   blockIcon: <Email />,
          //   blockName: "Send Email",
          //   blockId: "send_email",
          // },
          // {
          //   blockIcon: <SmsIcon />,
          //   blockName: "Send Sms",
          //   blockId: "send_sms",
          // },
          // {
          //   blockIcon: <BackTrack />,
          //   blockName: "Prevent backtracking",
          //   blockId: "prevent_backtracking",
          // },
        ],
      },
      {
        subHeader: "Shopify",
        listOfBlocks: [
          {
            blockIcon: <ShopifyDiscountCodeIcon />,
            blockName: "Discount Code",
            blockId: "discount_code",
            description: "Create static or dynamic discount codes on shopify"
          },
          {
            blockIcon: <ShopifyAddTagIcon />,
            blockName: "Add Order Tags",
            blockId: "shopify_add_tags",
            description: "Add a tag against the order in shopify",
          },
          {
            blockIcon: <ShopifyOrderNoteIcon />,
            blockName: "Add Order Notes",
            blockId: "shopify_add_notes",
            description: "Add a note against the order in shopify",
          },
          {
            blockIcon: <ShopifyCustomerTagIcon />,
            blockName: "Add Customer Tags",
            blockId: "add_customer_tags",
            description: "Add a customer tag against the order on shopify and BIK",
          },
        ],
      },
      {
        subHeader: "Integrations",
        listOfBlocks: [
          {
            blockIcon: <FreshdeskCreateTicketIcon />,
            blockName: "Freshdesk: Create Ticket",
            blockId: "create_ticket_on_freshdesk",
            description: "Create a ticket on Freshdesk. This requires freshdesk integration",
          },
          {
            blockIcon: <RazorpayCreateTicketIcon />,
            blockName: "Razorpay: Payment Link",
            blockId: "create_payment_link_on_Razorpay",
            description: "Create a payment link from razorpay",
          },
          {
            blockIcon: <GoswiftIcon />,
            blockName: "Goswift: Payment Link",
            blockId: "create_payment_link_on_Goswift",
            description: "Create a payment link from Goswift",
          },
          {
            blockIcon: <JudgeMe />,
            blockName: "Judge Me",
            blockId: "judgeme",
            description: "Create a review on Judge me",
          },
        ],
      },
      {
        subHeader: "Google Sheets",
        listOfBlocks: [
          {
            blockIcon: <GoogleSheetAdd />,
            blockName: "Add row",
            blockId: "google_sheet_add_row",
            description: "Add a new row on google sheet",
          },
          {
            blockIcon: <GoogleSheetUpdate />,
            blockName: "Update row",
            blockId: "google_sheet_update_row",
            description: "Update an existing row on google sheet",
          },
          {
            blockIcon: <GoogleSheetGet />,
            blockName: "Get row data",
            blockId: "google_sheet_get_row_data",
            description: "Get all column values for a particular row in google sheet",
          },
        ],
      },
      {
        subHeader: "Special actions",
        listOfBlocks: [
          {
            blockIcon: (
              <StoreVariable color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Store to variable",
            blockId: "store_variable",
            description: "Copy value of a variable / expression to another variable",
          },
          {
            blockIcon: <CallApi color={COLORS.background.negative.vibrant} />,
            blockName: "Rest API",
            blockId: "rest_api",
            description: "Call a rest api (GET/POST/PUT) to send or receive data",
          },
          {
            blockIcon: <OtherActionIcon />,
            blockName: "Other Actions",
            blockId: "call_custom_api",
            description: "Call a set of pre defined apis in BIK's system",
          },
          {
            blockIcon: <CallApi color={COLORS.background.negative.vibrant} />,
            blockName: "Call an API",
            blockId: "call_api",
            description: "Use this for generating cart and cart links"
          }
        ],
      },
    ],
  },
};

export const sideModalConfigsInstagram: {
  [key: string]: Partial<SideModalProps>;
} = {
  Message: {
    header: "Message blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic message",
        listOfBlocks: [
          // {
          //   blockIcon: <MessageBlock />,
          //   blockName: "Quick Replies",
          //   blockId: "instagram_qrb",
          // },
          {
            blockIcon: <MessageIcon color="#510058" />,
            blockName: "Message",
            blockId: "instagram_text",
            description: "Send a simple Instagram message",
          },
          {
            blockIcon: <MediaMessage color="#510058" />,
            blockName: "Media",
            blockId: "instagram_media",
            description: "Send media messages on Instagram",
          },
          {
            blockIcon: <Carousel height={20} width={20} color={"#212121"} />,
            blockName: "Carousel",
            blockId: "carousel",
            description: "Send carousel/slider message on Instagram",
          },
          // {
          //   blockIcon: <Catalog height={20} width={20} color={"#212121"} />,
          //   blockName: "Catalog",
          //   blockId: "catalog_carousel",
          // },
          {
            blockIcon: <Box color="#510058" />,
            blockName: "Product",
            blockId: "product_carousel",
            description: "Send one or multiple products on Instagram"
          },
          {
            blockIcon: <OrdersList color="#510058" />,
            blockName: "List of orders",
            blockId: "order_list",
            description: "Send a list of latest orders (upto 10)",
          },
          {
            blockIcon: <Email color={"#FF0000"} />,
            blockName: "Send Email",
            blockId: "email_temp_new",
            automationChannel: "INSTAGRAM",
            description: "Select from a list of email templates",
          },
          {
            blockIcon: <SmsIcon />,
            blockName: "Send SMS",
            blockId: "send_sms",
            automationChannel: "INSTAGRAM",
            description: "Select from a list of SMS templates. Requires SMS service provider integration",
          },
        ],
      },
      // {
      //   subHeader: "Messages as quick replies",
      //   listOfBlocks: [
      //   ],
      // },
      {
        subHeader: "Ask customers",
        listOfBlocks: [
          {
            blockIcon: <Person color="#510058" />,
            blockName: "Name",
            blockId: "ask_name",
            automationChannel: "INSTAGRAM",
            description: "Ask and save customer name",
          },
          {
            blockIcon: <Email color="#510058" />,
            blockName: "Email",
            blockId: "ask_email",
            automationChannel: "INSTAGRAM",
            description: "Ask and save customer email id",
          },
          {
            blockIcon: <Phone color="#510058" />,
            blockName: "Phone number",
            blockId: "ask_phone",
            automationChannel: "INSTAGRAM",
            description: "Ask and save customer phone number",
          },
          {
            blockIcon: <QuestionIcon color="#510058" />,
            blockName: "Question",
            blockId: "question",
            automationChannel: "INSTAGRAM",
            description: "Ask and save any question like pincode, images, video, documents from customer",
          },
          {
            blockIcon: <CsatIcon color="#510058" />,
            blockName: "CSAT",
            blockId: "CSAT",
            automationChannel: "INSTAGRAM",
            description: "Send NPS or CSAT surveys (1-3,1-5 or 1-10 scale)",
          },
          {
            blockIcon: <Email color={"#FF0000"} />,
            blockName: "Email CSAT/NPS",
            blockId: "email_csat",
            automationChannel: "WHATSAPP",
            description:
              "Send NPS or CSAT surveys for Email (1-3,1-5 or 1-10 scale)",
          },
        ],
      },
      {
        subHeader: "Product and cart",
        listOfBlocks: [
          // {
          //   blockIcon: <Catalog />,
          //   blockName: "Catalog",
          //   blockId: "catalog",
          // },
          // {
          //   blockIcon: <Box />,
          //   blockName: "Product",
          //   blockId: "product",
          // },
          {
            blockIcon: <Cart color="#510058" />,
            blockName: "Cart info",
            blockId: "cart_info",
            description: "Send details of the cart created by user as part of end to end checkout flows",
          },
          {
            blockIcon: <BrowsingFlowIcon color="#510058" />,
            blockName: "Browse Catalog",
            blockId: "browse_collection",
            description: "Set up browsing flows with products and collections",
          },
        ],
      },
    ],
  },
  AI: {
    header: "AI blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic Message",
        listOfBlocks: [
          {
            blockIcon: <ClassifyIcon />,
            blockName: "AI Classify",
            blockId: "ai_classify",
            description: "Classify customer messages using AI",
          },
          {
            blockIcon: <AiReplyIcon />,
            blockName: "AI Reply",
            blockId: "ai_reply",
            description: "Reply to customer messages using AI",
          },
          {
            blockIcon: <ProductRecommendIcon />,
            blockName: "AI Product Recommend",
            blockId: "ai_product_recommend",
            description: "Products recommended using AI",
          }
        ],
      },
    ],
  },
  Action: {
    header: "Action blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic actions",
        listOfBlocks: [
          {
            blockIcon: <AddEvent color={COLORS.background.negative.vibrant} />,
            blockName: "Add Event",
            blockId: "add_event",
            description: "Add event in BIK data platform which can be then later used for analytics or segmentation"
          },
          {
            blockIcon: (
              <TicketIcon color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Assign Ticket",
            blockId: "assign_ticket",
            description: "Assign tickets to a specific agent or team with a label (optional)",
          },
          {
            blockIcon: <TagIcon />,
            blockName: "Add Ticket Label",
            blockId: "add_ticket_label",
            description: "Add a label to tickets",
          },
          {
            blockIcon: (
              <TicketIcon color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Change Ticket Stage",
            blockId: "change_ticket_stage",
            description: "Change the ticket stage to close/pending or any custom stage as defined",
          },
          {
            blockIcon: <WorkingHourIcon />,
            blockName: "Working Hour Check",
            blockId: "check_working_hours",
            description: "Check if the time falls within working hour and accordingly define actions such as agent assign etc."
          },
        ],
      },
      {
        subHeader: "Shopify",
        listOfBlocks: [
          {
            blockIcon: <ShopifyDiscountCodeIcon />,
            blockName: "Discount code",
            blockId: "discount_code",
            description: "Create static or dynamic discount codes on shopify",
          },
          {
            blockIcon: <ShopifyAddTagIcon />,
            blockName: "Add Order Tags",
            blockId: "shopify_add_tags",
            description: "Add a tag against the order in shopify",
          },
          {
            blockIcon: <ShopifyOrderNoteIcon />,
            blockName: "Add Order Notes",
            blockId: "shopify_add_notes",
            description: "Add a note against the order in shopify",
          },
          {
            blockIcon: <ShopifyCustomerTagIcon />,
            blockName: "Add Customer Tags",
            blockId: "add_customer_tags",
            description: "Add a customer tag against the order on shopify and BIK",
          },
        ],
      },
      {
        subHeader: "Integrations",
        listOfBlocks: [
          {
            blockIcon: <FreshdeskCreateTicketIcon />,
            blockName: "Freshdesk: Create Ticket",
            blockId: "create_ticket_on_freshdesk",
            description: "Create a ticket on Freshdesk. This requires freshdesk integration",
          },
          {
            blockIcon: <RazorpayCreateTicketIcon />,
            blockName: "Razorpay: Payment Link",
            blockId: "create_payment_link_on_Razorpay",
            description: "Create a payment link from razorpay",
          },
          {
            blockIcon: <GoswiftIcon />,
            blockName: "Goswift: Payment Link",
            blockId: "create_payment_link_on_Goswift",
            description: "Create a payment link from Goswift",
          },
          {
            blockIcon: <JudgeMe />,
            blockName: "Judge Me",
            blockId: "judgeme",
            description: "Create a review on Judge me",
          },
        ],
      },
      {
        subHeader: "Google Sheets",
        listOfBlocks: [
          {
            blockIcon: <GoogleSheetAdd />,
            blockName: "Add row",
            blockId: "google_sheet_add_row",
            description: "Add a new row on google sheet",
          },
          {
            blockIcon: <GoogleSheetUpdate />,
            blockName: "Update row",
            blockId: "google_sheet_update_row",
            description: "Update an existing row on google sheet",
          },
          {
            blockIcon: <GoogleSheetGet />,
            blockName: "Get row data",
            blockId: "google_sheet_get_row_data",
            description: "Get all column values for a particular row in google sheet",
          },
        ],
      },
      {
        subHeader: "Special actions",
        listOfBlocks: [
          {
            blockIcon: (
              <StoreVariable color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Store to variable",
            blockId: "store_variable",
            description: "Copy value of a variable / expression to another variable",
          },
          {
            blockIcon: <CallApi color={COLORS.background.negative.vibrant} />,
            blockName: "Rest API",
            blockId: "rest_api",
            description: "Call a rest api (GET/POST/PUT) to send or receive data",
          },
          {
            blockIcon: <OtherActionIcon />,
            blockName: "Other Actions",
            blockId: "call_custom_api",
            description: "Call a set of pre defined apis in BIK's system",
          },
          {
            blockIcon: <CallApi color={COLORS.background.negative.vibrant} />,
            blockName: "Call an API",
            blockId: "call_api",
            description: "Use this for generating cart and cart links"
          }
        ],
      },
    ],
  },
  Condition: {
    header: "Flow control blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Flow Control",
        listOfBlocks: [
          {
            blockIcon: <DelayIcon />,
            blockName: "Delay",
            blockId: "delay",
            description: "Add a specific delay (in hours, minutes or days) OR wait till a specific time",
          },
          {
            blockIcon: <Filter />,
            blockName: "Filter",
            blockId: "filter",
            description: "Define journey branches basis user properties like city, last order date, total count/value of orders etc.",
          },
          {
            blockIcon: <ABTestingIcon color={COLORS.content.warning} />,
            blockName: "AB Testing",
            blockId: "ab_test",
            description: "Split the flow to set up AB tests",
          },
          {
            blockIcon: <ConditionIcon />,
            blockName: "Condition",
            blockId: "Condition",
            description: "Set up if/else conditions on variables",
          },
          {
            blockIcon: <Evaluate />,
            blockName: "Evaluate",
            blockId: "evaluate",
            description: "Evaluate or do basic computations like add/subtract/count/div/multiply",
          },
        ],
      },
    ],
  },
};

export const sideModalConfigsGmail: {
  [key: string]: Partial<SideModalProps>;
} = {
  Message: {
    header: "Email blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic emails",
        listOfBlocks: [
          {
            blockIcon: <Email color={'#F00'} />,
            blockName: "Template",
            blockId: "email_temp_new",
            description: "Select from a list of email templates",
          },
          {
            blockIcon: <Reply />,
            blockName: "Email reply",
            blockId: "gmail_reply",
            description: "Automate email replies",
          },
          {
            blockIcon: <Email color={"#FF0000"} />,
            blockName: "Email CSAT/NPS",
            blockId: "email_csat",
            automationChannel: "WHATSAPP",
            description:
              "Send NPS or CSAT surveys for Email (1-3,1-5 or 1-10 scale)",
          },
        ],
      },
    ],
  },
  AI: {
    header: "AI blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic Message",
        listOfBlocks: [
          {
            blockIcon: <ClassifyIcon />,
            blockName: "AI Classify",
            blockId: "ai_classify",
            description: "Classify customer messages using AI",
          }
        ],
      },
    ],
  },
  Action: {
    header: "Action blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic actions",
        listOfBlocks: [
          {
            blockIcon: <AddEvent color={COLORS.background.negative.vibrant} />,
            blockName: "Add Event",
            blockId: "add_event",
            description: "Add event in BIK data platform which can be then later used for analytics or segmentation"
          },
          {
            blockIcon: (
              <TicketIcon color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Assign Ticket",
            blockId: "assign_ticket",
            description: "Assign tickets to a specific agent or team with a label (optional)"
          },
          // {
          //   blockIcon: <AddEvent />,
          //   blockName: "Broadcast Events",
          //   blockId: "broadcast_events",
          // },
          {
            blockIcon: <TagIcon />,
            blockName: "Add Ticket Label",
            blockId: "add_ticket_label",
            description: "Add a label to tickets",
          },
          {
            blockIcon: <TicketIcon color={COLORS.background.negative.vibrant} />,
            blockName: "Change Ticket Stage",
            blockId: "change_ticket_stage",
            description: "Change the ticket stage to close/pending or any custom stage as defined",
          },
          {
            blockIcon: <WorkingHourIcon />,
            blockName: "Working Hour Check",
            blockId: "check_working_hours",
            description: "Check if the time falls within working hour and accordingly define actions such as agent assign etc."
          }
        ],
      },
      {
        subHeader: "Shopify",
        listOfBlocks: [
          {
            blockIcon: <ShopifyDiscountCodeIcon />,
            blockName: "Discount code",
            blockId: "discount_code",
            description: "Create static or dynamic discount codes on shopify",
          },
          {
            blockIcon: <ShopifyAddTagIcon />,
            blockName: "Add Order Tags",
            blockId: "shopify_add_tags",
            description: "Add a tag against the order in shopify",
          },
          {
            blockIcon: <ShopifyOrderNoteIcon />,
            blockName: "Add Order Notes",
            blockId: "shopify_add_notes",
            description: "Add a note against the order in shopify",
          },
          {
            blockIcon: <ShopifyCustomerTagIcon />,
            blockName: "Add Customer Tags",
            blockId: "add_customer_tags",
            description: "Add a customer tag against the order on shopify and BIK",
          },
        ],
      },
      {
        subHeader: "Integrations",
        listOfBlocks: [
          {
            blockIcon: <FreshdeskCreateTicketIcon />,
            blockName: "Freshdesk: Create Ticket",
            blockId: "create_ticket_on_freshdesk",
            description: "Create a ticket on Freshdesk. This requires freshdesk integration",
          },
          {
            blockIcon: <RazorpayCreateTicketIcon />,
            blockName: "Razorpay: Payment Link",
            blockId: "create_payment_link_on_Razorpay",
            description: "Create a payment link from razorpay",
          },
          {
            blockIcon: <GoswiftIcon />,
            blockName: "Goswift: Payment Link",
            blockId: "create_payment_link_on_Goswift",
            description: "Create a payment link from Goswift",
          },
          {
            blockIcon: <JudgeMe />,
            blockName: "Judge Me",
            blockId: "judgeme",
            description: "Create a review on Judge me",
          },
        ],
      },
      {
        subHeader: "Google Sheets",
        listOfBlocks: [
          {
            blockIcon: <GoogleSheetAdd />,
            blockName: "Add row",
            blockId: "google_sheet_add_row",
            description: "Add a new row on google sheet",
          },
          {
            blockIcon: <GoogleSheetUpdate />,
            blockName: "Update row",
            blockId: "google_sheet_update_row",
            description: "Update an existing row on google sheet",
          },
          {
            blockIcon: <GoogleSheetGet />,
            blockName: "Get row data",
            blockId: "google_sheet_get_row_data",
            description: "Get all column values for a particular row in google sheet",
          },
        ],
      },
      {
        subHeader: "Special actions",
        listOfBlocks: [
          {
            blockIcon: (
              <StoreVariable color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Store to variable",
            blockId: "store_variable",
            description: "Copy value of a variable / expression to another variable",
          },
          {
            blockIcon: <CallApi color={COLORS.background.negative.vibrant} />,
            blockName: "Rest API",
            blockId: "rest_api",
            description: "Call a rest api (GET/POST/PUT) to send or receive data",
          },
          {
            blockIcon: <OtherActionIcon />,
            blockName: "Other Actions",
            blockId: "call_custom_api",
            description: "Call a set of pre defined apis in BIK's system",
          },
          {
            blockIcon: <CallApi color={COLORS.background.negative.vibrant} />,
            blockName: "Call an API",
            blockId: "call_api",
            description: "Use this for generating cart and cart links"
          }
        ],
      },
    ],
  },
};
