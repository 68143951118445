interface EdgeObject {
    target: string;
    source: string;
}

interface PositionAbsolute {
    x: number;
    y: number;
}

interface Position {
    y: number;
    x: number;
}

interface Node {
    positionAbsolute: PositionAbsolute;
    id: string;
    height: number;
    position: Position;
    width: number;
}

export class NodeRearranger {
    levels: { [id: string]: number } = {};
    deltaY = 100;
    deltaX = 200;

    constructor(public nodes: Node[], public edges: EdgeObject[]) {
        this.nodes = this.nodes.filter(node => node)
    }

    findNodeById(id: string): Node {
        return this.nodes.find((node) => node.id === id)!;
    }

    findChildren(node: Node): Node[] {
        return this.edges
            .filter((edge) => edge.source === node.id)
            .map((edge) => this.findNodeById(edge.target));
    }

    addLevels(node: Node, level: number) {
        if (this.levels[node.id]) {
            return;
        }
        this.levels[node.id] = level;
        const children = this.findChildren(node);
        children.forEach((child) => {
            this.addLevels(child, level + 1);
        });
    }

    findSourceNodes(): Node[] {
        return this.nodes.filter((node) => {
            return !this.edges.find((edge) => edge.target === node.id);
        });
    }

    rearrange() {
        const sourceNodes = this.findSourceNodes();
        sourceNodes.forEach((node) => this.addLevels(node, 0));
        let maxY = 0;
        sourceNodes.forEach((node) => {
            maxY = this.assignPosition(node, 0, maxY);
        });
    }

    assignPosition(node: Node, x: number, y: number): number {
        node.position.x = x;
        node.position.y = y;

        let effectiveYForSibbling = y + this.deltaY + node.height;
        let effectiveYForChild = y;
        const children = this.findChildren(node);
        const childX = x + this.deltaX + node.width;
        children.forEach((child) => {
            if (this.levels[child.id] > this.levels[node.id]) {
                effectiveYForChild = Math.max(effectiveYForChild, this.assignPosition(child, childX, effectiveYForChild));
            }
        });
        const totalChildCount = children.length;
        if (totalChildCount) {
            node.position.y = (children[0].position.y + children[totalChildCount - 1].position.y) / 2;
        }

        if (node.positionAbsolute) {
            node.positionAbsolute.x = x;
            node.positionAbsolute.y = y;
        }
        return Math.max(effectiveYForSibbling, effectiveYForChild);
    }
}


