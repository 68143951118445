import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled, { keyframes } from "styled-components";
import { NodeActionActions } from "../../state/nodeActionState";
import { nodeClickDataActions } from "../../state/nodeClickDataState";
import { useAppSelector } from "../../state/store";
import { IconMapping, NodeConfig } from "../../utilities/flowBuilder.utility";
import FooterComponent from "../footer-component/footerComponent";
import HeaderComponent from "../header-component/headerComponent";
import { useOutside } from "../rich-text-editor/useOutside";
import ComponentModalHook from "./componentModal.hook";
import { Tag } from "@bikdotai/bik-component-library";
import {
  getDoNotRemindMeForEscapeToCloseFromLocalStorage,
  setDoNotRemindMeForEscapeToCloseFromLocalStorage,
} from "../../utilities/localStorageUtility";
import { EscapeClickConfirmationModal } from "../escape-confirmation-modal";
import { ConfirmationModal } from "../confirmation-modal";
import HelpSection from "../help-section";
import {enabledStoreIds} from "../../config";

interface ComponentModalProps {
  id?: string;
  storeId: string;
  flowId: string;
  config: NodeConfig;
  closeModal: () => void;
}

export enum ComponentModalType {
  google_sheet_add_row = "google_sheet_add_row",
  google_sheet_update_row = "google_sheet_update_row",
  google_sheet_get_row_data = "google_sheet_get_row_data",
  email_temp_new = "email_temp_new",
  filter = "filter",
  carousel = "carousel",
  browse_collection = "browse_collection",
  send_sms = "send_sms",
  wa_carousel = "wa_carousel"
}

const READ_ONLY_BLOCKS = ["evaluate", "store_variable", "call_api"];
const NOT_IN_COMPONENT_MODAL_BLOCKS: string[] = [
  "filter",
  "discount_code",
];

type Props = ComponentModalProps;

const generateTitle = (type: string): string => {
  switch (type) {
    case "add_ticket_label":
      return "Add Label";
    case "ab_test":
      return "AB Testing";
    case "call_custom_api":
      return "Other Actions";
    case "broadcast_events":
      return "Opt In/Out";
    case "ai_classify":
      return "AI Classify";
    case "ai_product_recommend":
      return "AI Product Recommend";
    case "ai_reply":
      return "AI Reply";
    case "email_temp_new":
      return 'Email'
    case "wa_carousel":
      return "Carousel";
    case "email_csat":
      return "Email CSAT/NPS"
    default:
      return type.split("_").join(" ");
  }
};

const ComponentModal: FunctionComponent<Props> = (props) => {
  const { config, id, storeId, flowId } = props;
  const [saveTo, setSaveTo] = useState<string>("");
  const nodeClickState = useAppSelector((state) => state.nodeClickState);
  const nodeFlow = useAppSelector((state) => state.storeState.flow);
  const [nodeData, setNodeData] = useState<any>({});
  const homeState = useAppSelector((state) => state.homeState);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { lazyLoadComponent } = ComponentModalHook(storeId, flowId);
  const dispatcher = useDispatch();
  const nodeActions = useAppSelector((state) => state.nodeActionState);
  const componentModal = useRef<any>();
  const contentRef = useRef<any>();
  const carouselContentRef = useRef<any>();
  const keyboardEventDataState = useAppSelector(
    (state) => state.keyboardEventState
  );
  const [isEscapePressed, setIsEscapePressed] = useState(false);
  const [isDontRemindMeAgainSelected, setIsDontRemindMeAgainSelected] =
    useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const escapeToCloseFromLocalStorage =
    getDoNotRemindMeForEscapeToCloseFromLocalStorage(storeId, flowId);

  const isSSO = homeState.userData?.loginType === "sso";
  const isReadOnly = !isSSO && READ_ONLY_BLOCKS.includes(nodeData?.subtype) && !(enabledStoreIds.includes(storeId));

  useOutside(
    componentModal,
    (target) => {
      onFocusOut();
    },
    [contentRef, carouselContentRef]
  );

  const onFocusOut = () => {
    const element = document.getElementById("cmpModal");
    if (element && element.clientWidth === 0 && element.clientHeight === 0) {
      return;
    }
    let list = element?.classList;
    list?.add("focus");
    setShowConfirmationModal(true);
    setTimeout(() => {
      list?.remove("focus");
    }, 4000);
  };

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    if (!nodeClickState.nodeId || !nodeClickState.nodeSubType) return;
    const selectedNodeId = nodeClickState.nodeId as string;
    const nodeType = nodeClickState.nodeType;
    const nodeSubType = nodeClickState.nodeSubType || "";
    const nodeData =
      nodeType === "Sticky notes" ? config : nodeFlow[selectedNodeId] || {};
    setNodeData({
      conf: nodeData,
      subtype: nodeSubType,
      nodeId: selectedNodeId,
    });
  }, [nodeClickState?.nodeId]);

  useEffect(() => {
    if (saveTo && saveTo === "close") {
      closeModal();
    }
  }, [saveTo]);

  useEffect(() => {
    if (nodeActions?.action === "delete" && nodeActions?.nodeId) {
      onClickCancel();
      setSaveTo("close");
      dispatcher(
        NodeActionActions.updateState({
          action: "",
          nodeId: "",
          type: "",
        })
      );
    }
  }, [nodeActions]);

  const onClickSave = () => {
    setSaveTo(nodeData?.subtype);
    setShowConfirmationModal(false);
  };

  const onLoading = (value: boolean) => {
    setLoading(value);
  };

  const onClickCancel = () => {
    dispatcher(
      nodeClickDataActions.setEditNode({
        nodeId: "",
        nodeType: "",
        nodeSubType: "",
        data: {},
      })
    );
    closeModal();
    setShowConfirmationModal(false);
    setSaveTo('cancel');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // after the close animation is completed
    setTimeout(() => {
      props.closeModal();
    }, 500);
  };

  /**
   * Runs when any action is dispatched when on keyboardEventDataState.
   * Basically, anytime a keyboard event which is used in the code is captured, the action to be dispatched.
   */
  useEffect(() => {
    //When user presses escape key | Depending on local storage value, show the popup or close the modal
    if (keyboardEventDataState.keyPressAction === "escape") {
      getDoNotRemindMeForEscapeToCloseFromLocalStorage(storeId, flowId)
        ? onClickCancel()
        : setIsEscapePressed(true);
    }
  }, [keyboardEventDataState?.keyPressAction]);

  /**
   * When user presses "Yes" on popup.
   * Stores value in local storage if user checks 'Don't remind me again'
   */
  const onYesFromEscapeConfirmModal = () => {
    if (isDontRemindMeAgainSelected) {
      setDoNotRemindMeForEscapeToCloseFromLocalStorage(storeId, flowId, "true");
    }
    onClickCancel();
    setIsEscapePressed(false);
  };

  return (
    <ComponentModalStyles subType={nodeData.subtype}>
      <div
        className={`component__modal ${isModalOpen ? "open" : "close"}`}
        ref={componentModal}
        id="cmpModal"
      >
        {!NOT_IN_COMPONENT_MODAL_BLOCKS.includes(nodeData?.subtype) && (
          <div className="header">
            <HeaderComponent
              channel={homeState.channel || "whatsapp"}
              type={nodeClickState.nodeType as string}
              headerType={generateTitle(nodeData?.subtype || "")}
              headerIcon={IconMapping(nodeData?.subtype, homeState.channel!)}
              subType={(nodeClickState.nodeSubType as string) || ""}
            />
          </div>
        )}
        {isReadOnly && (
          <div style={{ margin: 10, marginBottom: 0 }}>
            <Tag
              tagText={
                "🔒 Read-Only Mode: you can only view the content but cannot make any modifications."
              }
              type="warning"
            />
          </div>
        )}

        {nodeData.subtype === "carousel" ? (
          <div className="carousalContent" ref={carouselContentRef}>
            {lazyLoadComponent(
              nodeData,
              saveTo,
              setSaveTo,
              config,
              error,
              setError,
              setLoading
            )}
            <HelpSection subType={nodeData?.subtype} />
          </div>
        ) : (
          <div
            className="content"
            id="content-body"
            ref={contentRef}
            style={{ pointerEvents: isReadOnly ? "none" : "unset" }}
          >
            {lazyLoadComponent(
              nodeData,
              saveTo,
              setSaveTo,
              config,
              error,
              setError,
              setLoading
            )}
            <HelpSection subType={nodeData?.subtype} />
          </div>
        )}

        <div className="footer">
          <FooterComponent
            loading={loading}
            onClickCancel={onClickCancel}
            onClickSave={onClickSave}
            error={error}
            isReadOnly={isReadOnly}
          />
        </div>
      </div>
      <ConfirmationModal
        loading={loading}
        open={showConfirmationModal}
        onPositiveClick={onClickSave}
        onNegativeClick={onClickCancel}
        onCloseClick={onClickCancel}
        title={"Save changes to block?"}
        subTitle={
          "You have unsaved changes in this block. Do you wish to save the changes?"
        }
        positiveButtonText={"Save"}
        negativeButtonText={"Discard"}
      />
      <div className="freeze__screen"></div>
      {isEscapePressed && !escapeToCloseFromLocalStorage && (
        <EscapeClickConfirmationModal
          onPositiveButtonClick={onYesFromEscapeConfirmModal}
          onNegativeButtonClick={() => setIsEscapePressed(false)}
          isChecked={isDontRemindMeAgainSelected}
          onCheckedChange={() =>
            setIsDontRemindMeAgainSelected(!isDontRemindMeAgainSelected)
          }
        />
      )}
    </ComponentModalStyles>
  );
};

const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const ComponentModalStyles = styled.div.attrs(
  (props: { subType?: string }) => props
)`
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  .component__modal {
    position: absolute;
    height: 100%;

    width: 424px;
    top: -56px;
    left: 0;
    z-index: 11;
    display: flex;
    flex-direction: column;
    background: #fafafa;

    margin-top: 56px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    &.open {
      animation: ${slideIn} 0.5s forwards;
    }
    &.close {
      animation: ${slideOut} 0.5s forwards;
    }
  }
  .focus {
    transition-delay: 0.2s;
    outline: 2px solid #731dcf;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  }
  .freeze__screen {
    position: absolute;
    top: -56px;
    left: 0;
    margin-left: 424px;
    margin-top: 56px;
    width: 10000px;
    height: 100%;
    opacity: 0;
    z-index: 10;
  }

  .header {
    position: sticky;
    background: #fafafa;
    top: 0;
    z-index: 10;
  }

  .footer {
    position: sticky;
    bottom: 0;
    margin-top: auto;
    z-index: 98;
  }

  .content {
    overflow-y: auto;
    padding: 24px 16px 24px 16px;
    flex: 1;
    z-index: 99;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }

  .carousalContent {
    display: flex;
    flex-direction: column;
    padding: ${(props) =>
    props.subType === "carousel" ? "16px 16px 0" : "60px"};
    flex: 1;
    z-index: 99;
    overflow-y: auto;
  }
`;

export default ComponentModal;
