import { useState, useEffect } from "react";
import { useAppSelector } from "../../state/store";
import { snakeToCamel } from "../../utilities/flowBuilder.utility";
import { createList } from "../../components/webhook/helpers";
import { cartVariables, checkoutVariables, customerVariables, flowVariables, orderVariables, paymentVariables, productVariables, sessionVariables, storeVariables, ticketVariables } from "./VariableDirectory";

export interface VariableListInterfaceV2 {
    displayName: string,
    description: string,
    orderId: number,
    variables: any
}

export interface Variable {
    description?: string,
    displayName: string,
    actualValue: string,
    variableType: string,
    isAvailable: boolean,
    actualValueEval?: string,
    isSubHeader?: boolean,
    variables?: { [key: string]: Variable }
    needsFetching?: boolean
}

export interface PayloadConfig {
    fromWebhook?: boolean,
    hide?: boolean
}

export interface Props {
    payloadConfig?: PayloadConfig
}

const useVariablesListHookV2 = (props?: Props) => {
    const globalVariableList = useAppSelector(
        (state) => state.globalVariablesState.globalVariablesList
    );
    const aiVariableList = useAppSelector(
        (state) => state.aiVariablesState.aiVariablesList
    );
    const selectedNode = useAppSelector((state) => state.nodeClickState);
    const [variablesList, setVariablesList] =
        useState<VariableListInterfaceV2[]>([]);

    const localVariablesList = useAppSelector(
        (state) => state.localVariablesState.localVariablesList
    );
    const localVariablesListType = useAppSelector(
        (state) => state.localVariablesState.localVariablesListType
    );
    const storeState = useAppSelector((state) => state.storeState);
    const payloadVariables: any = useAppSelector(
        (state) => state.payloadVariableState.payload
    );

    const webhookVariables: any = useAppSelector(
        (state) => state.payloadVariableState.webhookPayload
    );

    const externalApiDataVariableList = useAppSelector(
        (state) => state.apiDataState.externalApiData
    );

    const triggers: { [key: string]: any } = useAppSelector(
        (state) => state.automationTriggerState.triggers
    );

    const eventVariables = useAppSelector((state) => state.eventPropertyState.data) || {};

    const apiDataVariableList = useAppSelector(
        (state) => state.apiDataState.apiData
    );

    const customApiRegex = /^N\d+ - \d+$/;
    const brandApiRegex = /^N\d+ - \d+ - \w+$/;
    const discountVariableList = useAppSelector(
        (state) => state.apiDataState.discountData
    );

    const getAiVariablesList = () => {
        const payloadData: VariableListInterfaceV2 = {
            displayName: 'AI Variables',
            description: 'Variables returned by Bik AI blocks',
            orderId: 36,
            variables: {}
        }
        Object.keys(aiVariableList).forEach((k) => {
            const payloadVar = {
                description: undefined,
                displayName: aiVariableList[k].name,
                actualValue: `{{${aiVariableList[k].value}}}`,
                isAvailable: true,
                isSubHeader: false,
                variableType: aiVariableList[k].type
            }
            payloadData.variables[k] = payloadVar as Variable;
        });
        return payloadData;
    };

    const getGlobalVarList = (listType: string) => {
        const payloadData: VariableListInterfaceV2 = {
            displayName: 'Global Variables',
            description: 'Variables you have defined in the variables section on your dashboard',
            orderId: 40,
            variables: {}
        }

        Object.keys(globalVariableList).forEach((k) => {
            let varType = globalVariableList[k].type || 'any'
            if (['Number', 'Integer'].includes(varType)) {
                varType = 'number'
            } else if (varType === 'String') {
                varType = 'string'
            } else if (varType === 'Boolean') {
                varType = 'boolean'
            }

            const payloadVar = {
                description: undefined,
                displayName: k,
                actualValue: listType === "eval" ? `Global['${k}']` : `{{${k}}}`,
                actualValueEval: `Global['${k}']`,
                variableType: varType,
                isAvailable: true,
                isSubHeader: false,
            }
            payloadData.variables[k] = payloadVar as Variable
        });
        return payloadData;
    };

    const getLocalVarList = (listType: string) => {
        const payloadData: VariableListInterfaceV2 = {
            displayName: 'Local User Responses',
            description: 'Variables containing user responses to questions, lists and QRBs',
            orderId: 32,
            variables: {}
        }

        Object.keys(localVariablesList).forEach((k) => {
            const payloadVar = {
                description: undefined,
                displayName: k,
                actualValue: listType === "eval"
                    ? `Local['${localVariablesList[k]
                        .replace("{{", "")
                        .replace("}}", "")}']`
                    : `{{${localVariablesList[k]}}}`,
                actualValueEval: `Local['${localVariablesList[k]
                    .replace("{{", "")
                    .replace("}}", "")}']`,
                variableType: localVariablesListType && localVariablesListType[k] ? localVariablesListType[k] : 'string',
                isAvailable: true,
                isSubHeader: false,
            }
            payloadData.variables[k] = payloadVar as Variable
        });
        return payloadData;
    };

    const getApiDataVarList = (listType: string) => {

        const outerKeys = Object.keys(apiDataVariableList)
        const apiRegex = /^N\d+ - \d+$/;
        const nodeKeys = outerKeys.filter(item => apiRegex.test(item) || brandApiRegex.test(item))
        const oldKeys = outerKeys.filter(item => !nodeKeys.includes(item))

        if (oldKeys.length) {

            const payloadData: VariableListInterfaceV2 = {
                displayName: 'Api Data Responses',
                description: 'API responses received from the APIs',
                orderId: 35,
                variables: {}
            }

            oldKeys.forEach((k) => {
                const payloadVar = {
                    description: undefined,
                    displayName: k,
                    actualValue: listType === "eval" ? `response['${k}']` : `{{response.${k}}}`,
                    variableType: 'any',
                    isAvailable: true,
                    isSubHeader: false,
                }
                payloadData.variables[k] = payloadVar as Variable
            })
            return payloadData
        }

        return {} as VariableListInterfaceV2
    };

    const getExternalApiVarList = (listType: string) => {
        const payloadData: VariableListInterfaceV2 = {
            displayName: 'External Api Responses',
            description: 'API responses received when a call external API is used',
            orderId: 34,
            variables: {}
        }

        Object.keys(externalApiDataVariableList).forEach((k) => {
            const payloadVar = {
                description: undefined,
                displayName: k,
                actualValue: listType === "eval"
                    ? `externalApiResponse['${k}']`
                    : `{{externalApiResponse.${k}}}`,
                variableType: 'any',
                isAvailable: true,
                isSubHeader: false,
            }
            payloadData.variables[k] = payloadVar as Variable
        });
        return payloadData;
    };

    const getEventVarList = (listType: string, eventName: string, orderId: number) => {
        const payloadData: VariableListInterfaceV2 = {
            displayName: eventName.split(/(?=[A-Z])/)
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
            description: eventVariables[eventName] ? eventVariables[eventName][0].headerDescription : undefined,
            orderId: orderId,
            variables: {}
        }

        eventVariables[eventName].forEach((event: any) => {
            const payloadVar = {
                description: event.description,
                displayName: event.name,
                actualValue: listType === "eval" ? `${eventName}['${event.id}']` : `{{${eventName}.${event.id}}}`,
                variableType: event?.type || 'any',
                isAvailable: true,
                isSubHeader: false,
                needsFetching: event?.needsFetching || false
            }
            payloadData.variables[event.id] = payloadVar as Variable
        });
        return payloadData;
    };

    const formatVariableList = (
        listType: string,
        varType: string,
        groupName?: string,
        orderId?: number
    ): VariableListInterfaceV2 => {
        if (varType === "global") {
            return getGlobalVarList(listType)
        } else if (varType === "local") {
            return getLocalVarList(listType);
        } else if (varType === "externalApi") {
            return getExternalApiVarList(listType);
        } else if (groupName && groupName === "events-trigger") {
            return getEventVarList(listType, varType, orderId ?? 1);
        } else {
            return getApiDataVarList(listType);
        }
    };

    function processObject(obj: any, targetObj: any, parentKey?: string, objName?: string) {
        for (const key in obj) {
            const currentKey = parentKey ? `${parentKey}.${key}` : key;
            const currentValue = `{{${objName}.${currentKey}}}`;
            if (typeof obj[key] !== "object") {
                const payloadVar = {
                    displayName: key,
                    actualValue: currentValue,
                    variableType: obj[key],
                    isAvailable: true,
                    isSubHeader: false,
                };
                targetObj[key] = payloadVar;
            } else if (Array.isArray(obj[key])) {
                // Handle arrays (recursively)
                const newPayloadVar: any = {
                    displayName: key,
                    actualValue: `{{${objName}.${key}}}`,
                    variableType: 'any',
                    isAvailable: true,
                    isSubHeader: true,
                    variables: {},
                };

                targetObj[key] = [];
                newPayloadVar.variables = {}
                obj[key].forEach((item: any, index: number) => {
                    const itemPayloadVar = {
                        displayName: index.toString(),
                        actualValue: `{{${objName}.${currentKey}[${index}]}}`,
                        variableType: 'any',
                        isAvailable: true,
                        isSubHeader: false
                    };
                    newPayloadVar.variables[index] = itemPayloadVar
                    if (typeof item === "object") {
                        newPayloadVar.variables[index].isSubHeader = true
                        newPayloadVar.variables[index].variables = {}
                        processObject(item, newPayloadVar.variables[index].variables, `${currentKey}[${index}]`, objName);
                    }
                });
                targetObj[key] = newPayloadVar;

            } else {
                // Handle nested objects (recursively)
                const newPayloadVar = {
                    displayName: key,
                    actualValue: `{{${objName}.${key}}}`,
                    variableType: 'any',
                    isAvailable: true,
                    isSubHeader: true,
                    variables: {},
                };

                targetObj[key] = {};
                processObject(obj[key], newPayloadVar.variables, currentKey, objName);
                targetObj[key] = newPayloadVar;
            }
        }
    }

    const getPayloadVariableList = () => {
        const payloadData: VariableListInterfaceV2 = {
            displayName: 'Payload Variables',
            description: (props?.payloadConfig?.fromWebhook || (triggers.length === 1 && triggers[0]?.type === 'webhook')) ? 'These are variables passed from the payload received' : 'These are variables passed from another flow via Start a Flow Block',
            orderId: 30,
            variables: {}
        }

        if (Object.keys(webhookVariables).length) {
            payloadData.variables = createList(webhookVariables, 'payload');
        } else {
            processObject(payloadVariables, payloadData.variables, undefined, 'payload');
        }
        return payloadData
    };

    const getDiscountVariables = () => {
        const payloadData: VariableListInterfaceV2 = {
            displayName: 'Discount Variables',
            description: '',
            orderId: 38,
            variables: {}
        }
        const nodeKeys = Object.keys(discountVariableList)

        nodeKeys.forEach((k) => {
            const nodeIndex = k.split('-')[1].trim()
            const customVar: any = {
                displayName: `discount${nodeIndex}`,
                actualValue: `discount${nodeIndex}`,
                variableType: 'any',
                isAvailable: true,
                isSubHeader: true,
                variables: {},
            }
            Object.keys(discountVariableList[k]).forEach((key) => {
                const newCustomVar = {
                    displayName: key,
                    actualValue: `{{discount${nodeIndex}.${key}}}`,
                    variableType: discountVariableList[k][key] || 'any',
                    isAvailable: true,
                    isSubHeader: false
                };
                customVar.variables[key] = newCustomVar
            })
            payloadData.variables[`discount${nodeIndex}`] = customVar
        });

        return payloadData
    }

    const getCallCustomApiVariables = () => {
        const payloadData: VariableListInterfaceV2 = {
            displayName: 'Api response variables',
            description: 'API responses received when a call custom API is used',
            orderId: 33,
            variables: {}
        }

        const outerKeys = Object.keys(apiDataVariableList)
        const nodeKeys = outerKeys.filter(item => customApiRegex.test(item) || brandApiRegex.test(item))

        nodeKeys.forEach((k) => {
            const nodeIndex = k.split('-')[1].trim()
            const brand = k.split('-')?.[2]?.trim()
            const customVar: any = {
                displayName: brand ? `${snakeToCamel(brand)}${nodeIndex}` : `callCustomApi${nodeIndex}`,
                actualValue: brand ? `${snakeToCamel(brand)}${nodeIndex}` : `callCustomApi${nodeIndex}`,
                variableType: 'any',
                isAvailable: true,
                isSubHeader: true,
                variables: {},
            }
            if (brand &&  snakeToCamel(brand).toLowerCase() === 'restapi') {
                processObject(apiDataVariableList[k], customVar.variables, undefined, `${snakeToCamel(brand)}${nodeIndex}`);
            } else {
                Object.keys(apiDataVariableList[k]).forEach((key) => {
                    const newCustomVar = {
                        displayName: key,
                        actualValue: brand ? `{{${snakeToCamel(brand)}${nodeIndex}.${key}}}` : `{{callCustomApi${nodeIndex}.${key}}}`,
                        variableType: apiDataVariableList[k][key]?.dataType || 'any',
                        isAvailable: true,
                        isSubHeader: false
                    };
                    customVar.variables[key] = newCustomVar
                })
            }
            payloadData.variables[brand ? `${snakeToCamel(brand)}${nodeIndex}` : `callCustomApi${nodeIndex}`] = customVar
        });

        return payloadData
    }

    useEffect(() => {
        if(selectedNode?.nodeId){
            constructVariableList();
        }
    }, [selectedNode?.nodeId]);

    const constructVariableList = async () => {
        let variableList: VariableListInterfaceV2[] = []

        variableList.push(storeVariables);
        variableList.push(customerVariables);
        variableList.push(sessionVariables);
        variableList.push(ticketVariables);
        variableList.push(cartVariables);
        variableList.push(orderVariables);
        variableList.push(productVariables);
        variableList.push(paymentVariables);
        variableList.push(checkoutVariables);
        variableList.push(flowVariables);

        if (Object.keys(globalVariableList).length) {
            variableList.push(formatVariableList("normal", "global"));
        }
        if (Object.keys(localVariablesList).length) {
            variableList.push(formatVariableList("normal", "local"));
        }
        if (Object.keys(aiVariableList).length) {
            variableList.push(getAiVariablesList());
        }

        const outerKeys = Object.keys(apiDataVariableList)
        const apiRegex = /^N\d+ - \d+$/;
        const brandApiRegex = /^N\d+ - \d+ - \w+$/;
        const nodeKeys = outerKeys.filter(item => apiRegex.test(item) || brandApiRegex.test(item))
        const oldKeys = outerKeys.filter(item => !nodeKeys.includes(item))
        if (oldKeys.length) {
            variableList.push(formatVariableList("normal", "response"));
        }
        if (Object.keys(externalApiDataVariableList).length) {
            variableList.push(formatVariableList("normal", "externalApi"));
        }
        if (Object.keys(eventVariables).length) {
            let orderId = 11
            for (const key of Object.keys(eventVariables)) {
                variableList.push(formatVariableList("normal", key, "events-trigger", orderId));
                orderId += 2
            }
        }
        if (Object.keys(payloadVariables).length || Object.keys(webhookVariables).length) {
            if(props?.payloadConfig?.hide === true){
            }else{
                variableList.push(getPayloadVariableList())
            }
        }

        if (nodeKeys && nodeKeys.length > 0) {
            variableList.push(getCallCustomApiVariables())
        }

        if (Object.keys(discountVariableList).length) {
            variableList.push(getDiscountVariables())
        }

        variableList.sort((a, b) => a.orderId - b.orderId);
        setVariablesList(variableList);
        return variableList;
    };

    return {
        variablesList,
        setVariablesList,
        constructVariableList
    };
};

export default useVariablesListHookV2;
