// @ts-nocheck
import React, { useEffect, useState } from "react";
import { cloneDeep, set } from "lodash";
import { useDispatch } from "react-redux";
import { AutomationTriggerActions } from "../../state/automationTriggerState";
import { flowMetaStateActions } from "../../state/flowMetaState";
import { nodeClickDataActions } from "../../state/nodeClickDataState";
import botStore, { useAppSelector } from "../../state/store";
import PopOver from "../../ui-components/popover";
import { NodeActionActions } from "../../state/nodeActionState";
import { EventPropertyActions } from "../../state/eventPropertyState";
import { storeDataActions } from "../../state/storeDataState";
import {
  AutomationTriggerStyle,
  PopOverItem,
  Separator,
} from "./AutomationTrigger.style";
import {
  BodyPrimary,
  BodySecondary,
  Button,
  COLORS,
  Dropdown,
  Tag,
  CheckBox,
  Input,
  MEDIA_TYPES,
  BodyCaption,
  Tooltip,
} from "@bikdotai/bik-component-library";
import EventsBuilder from "../query-builder/events";
import SpecialDate from "../query-builder/special";
import IgBuilder from "../query-builder/ig";
import KeywordsTrigger from "../query-builder/keywords";
import CtwaBuilder from "../query-builder/ctwa";
import WebhookBuilder from "../webhook/webhook";
import BusinessEvents from '../business-events/businessEvents';
import AutomationCard from "../../ui-components/automation-card";
import InstagramIcon from "../../icons/instagramIcon";
import HashIcon from "../../icons/hashIcon";
import AddEvent from "../../icons/addEvent";
import SpecialDateIcon from "../../icons/calendar";
import GreyCircle from "../../ui-components/grey-circle";
import {
  getAutomations,
  saveStartingPoint,
} from "../../services/helpers/AutomationHelper";
import AutomationShimmer from "./AutomationShimmer";
import PlusIcon from "../../icons/plusIcon";
import { notificationDataStateActions } from "../../state/notificationDataState";
import { unCamelCase } from "../../utilities/unCamelCase";
import InfoIcon from "../../icons/infoIcon";
import WhatsappIcon from "../../icons/whatsappIcon";
import WebhookIcon from "../../icons/webhookIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import BackInStockIcon from "../../icons/backInStockIcon";
import {
  DropdownOption,
  SingleOption,
} from "@bikdotai/bik-component-library/dist/esm/components/dropdown/type";
import { cancelOptions } from "../../app/messageBlock/Constants";
import CommentsConfigScreen from "../ig-modals/comments";
import Replies from "../ig-modals/replies";
import Mentions from "../ig-modals/mentions";
import Reactions from "../ig-modals/reactions";
import IgTriggersSelection from "../ig-modals/ig-triggers-selection";
import { ActionEventsList, WhatsAppActions, WhatsappActionEventsList } from "../ig-modals/models";
import { v4 as uuidv4 } from "uuid";
import { onNodeAdd } from "../../services/helpers/VariableV2Mapper";
import useVariablesListHookV2 from "../../services/helpers/VariablesListHookV2";
import { ErrorStateHelper } from "../../services/helpers/errorStateHelper";
import { errorDataActions } from "../../state/errorDataState";
import InstagramSendIcon from "../../icons/instagramSendIcon";
import InstagramCommentIcon from "../../icons/instagramCommentIcon";
import InstagramReplyIcon from "../../icons/instagramReplyIcon";
import { getChannelIdName, hasChannelError } from "../query-builder/utils";
import AlertTriangle from "../../icons/alertTriangle";
import PromocodeIcon from "../../icons/promocodeIcon";
import { getStoreWidgetStatus, getTrackerStatus } from "../business-events/apiRepo";
import { EventSubTypes } from './models';
import { getAllEventNames } from "../../ui-components/flow-builder/helper";
import { RecordEventHelper } from "../../utilities/RecordEventHelpers";
import {
  AmplitudeEventStep,
  AmplitudeKeys,
  TargetPlatform,
} from "../../utilities/AnalyticsTypes";


interface AutomationTriggerProps {
  nodeData: any;
  config: any;
  flowId: string;
  saveTo: string;
  setSaveTo: any;
  storeId: string;
  setLoading: Function;
}

interface IFrequencyCap {
  value: string;
  unit: "hours" | "days" | "weeks";
}

export enum TriggerTypes {
  event = "event",
  ig = "ig",
  ctwa = "ctwa",
  keyword = "keyword",
  relay = "relay",
  webhook = "webhook",
  businessEvents = 'businessEvents'
}

export const keyWordsStringify = (keywordList) => {
  let keywordJoint: any[] = [];
  Object.entries(keywordList).map((item) => {
    if (item[0] === "EXCLUDES") {
      return;
    }
    keywordJoint = keywordJoint.concat(item[1]);
  });
  return keywordJoint.join(",");
};

export const keyWordCounter = (keywordList) => {
  let count = 0;
  if (keywordList) {
    Object.entries(keywordList).map((item) => {
      count = count + item[1].length;
    });
  }
  return count;
};

const triggerBtnRef = React.createRef<HTMLButtonElement>();
const popoverRef = React.createRef<HTMLDivElement>();

const AutomationTrigger = (props: AutomationTriggerProps) => {
  const { flowId, saveTo, setSaveTo, storeId, setLoading } = props;
  const flowMeta: { [key: string]: any } = useAppSelector(
    (state) => state.flowMetaState.flowMeta
  );
  const triggers: { [key: string]: any } = useAppSelector(
    (state) => state.automationTriggerState.triggers
  );
  const eventData: { [key: string]: any } = useAppSelector(
    (state) => state.eventPropertyState.data
  );
  const errorStateComplete = useAppSelector((state) => state.errorDataState);
  const channel = flowMeta[flowId]?.channel;
  const itemList = cloneDeep(flowMeta);
  const dispatcher = useDispatch();
  const eventHelper = RecordEventHelper.getInstance();
  const [showKeywordsBuilder, setShowKeywordsBuilder] =
    useState<boolean>(false);
  const [showEventsBuilder, setShowEventsBuilder] = useState<boolean>(false);
  const [showIgBuilder, setShowIgBuilder] = useState<boolean>(false);
  const [showCtwaBuilder, setShowCtwaBuilder] = useState<boolean>(false);
  const [showWebhookBuilder, setShowWebhookBuilder] = useState<boolean>(false);
  const [showBackInStock, setShowBackInStock] = useState(false);
  const [showPriceDrop, setShowPriceDrop] = useState(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [buttonRect, setButtonRect] = useState<DOMRect | null>(null);
  const [isAutomationsLoading, setIsAutomationsLoading] =
    useState<boolean>(false);
  const [instaId, setInstaId] = useState<string>("");
  const [adAccountId, setAdAccountId] = useState<string>("");
  const [builderNodes, setBuilderNodes] = useState<[]>([]);
  const [builderMode, setBuilderMode] = useState("create");
  const [cancelTriggers, setCancelTriggers] = useState<any>({});
  const [triggerType, setTriggerType] = useState<TriggerTypes | undefined>(
    undefined
  );
  const storeState = useAppSelector((state: any) => state.storeState);
  const [keywordsLists, setKeywordsList] = useState<any>({});
  const [isFrequencyCapped, setIsFrequencyCapped] = useState<boolean>(false);
  const [frequencyCap, setFrequencyCap] = useState<IFrequencyCap>({
    value: "",
    unit: "hours",
  });
  const [frequencyCapError, setFrequencyCapError] = useState<string>("");
  const [frequencyThresholdError, setThresholdError] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [igScreen, setIgScreen] = useState<ActionEventsList | "default">(
    "default"
  );
  const [whatsAppScreen, setWhatsAppScreen] = useState<ActionEventsList | "default">(
    "default"
  );
  const [igConfig, setIgConfig] = useState<any>({});
  const [createNew, setCreateNew] = useState<boolean>(false);
  const { variablesList, setVariablesList } = useVariablesListHookV2();
  const [freqThreshold, setFreqThreshold] = useState<number>(1);
  const [segmentIdForRelay, setSegmentIdForRelay] = useState<string>("");
  const [showSpecialDateBuilder, setShowSpecialDateBuilder] =
    useState<boolean>(false);
  const [currentChannelId, setCurrentChannelId] = useState<string>("");
  const channels: { [key: string]: any } = useAppSelector(
    (state) => state.flowMetaState.channels!
  );
  const [triggerUpdate, setTriggerUpdate] = useState<boolean>(true);
  const [isAddTriggerDisabled, setIsAddTriggerDisabled] = useState<boolean>(false);
  const [cardHasWarning, setCardHasWarning] = useState<boolean>(false);
  const [warningText, setWarningText] = useState<string>('')
  const [allEventNames, setAllEventNames] = useState<{ [key: string]: EventSubTypes }>()
  const homeState = useAppSelector((state) => state.homeState);

  useEffect(() => {
    // clear any stale triggers if any
    dispatcher(AutomationTriggerActions.resetProperty());

    getAllEventNames(storeId, channel).then(res => {
      const keys = Object.keys(res)
      const revMap = {}
      keys.forEach(k => {
        res[k].forEach(v => {
          revMap[v.id] = k
        })
      })
      setAllEventNames(revMap)
    })

    const asynGetAutomationsHelper = async () => {
      await getAutomationsHelper();
      getFrequencyAndCancelTrigger();
      setTriggerTypeFromDb();
    };
    const asyncReRenderKeywordsNew = async () => {
      await reRenderKeywordsNew();
      getFrequencyAndCancelTrigger();
      setTriggerTypeFromDb();
    };
    if (itemList[flowId!]?.triggers && itemList[flowId!]?.triggers.length > 0) {
      asynGetAutomationsHelper();
    } else if (
      flowMeta[flowId]?.triggerType === "keyword" &&
      Object.keys(flowMeta[flowId].keywordsNew ?? {}).length > 0
    ) {
      asyncReRenderKeywordsNew();
    }
  }, []);

  useEffect(() => {
    (async function () {
      if (!!triggers && triggers.length > 0 && (triggerType === TriggerTypes.webhook || triggerType === TriggerTypes.businessEvents)) {
        setIsAddTriggerDisabled(true)
      } else {
        setIsAddTriggerDisabled(false)
      }

      if (triggerType === TriggerTypes.businessEvents && !!triggers && triggers.length) {
        let widgetEnabled = false
        const widgetEnabledRes = await getStoreWidgetStatus(storeId, triggers?.[0]?.businessEventType)
        if (widgetEnabledRes) {
          widgetEnabled = true
        }

        let trackerEnabled = false
        const trackerEnabledRes = await getTrackerStatus(storeId)
        if (trackerEnabledRes?.id) {
          trackerEnabled = true
        }

        const eventsChosen = triggers?.[0]?.eventsSubscribedTo

        if(!widgetEnabled && eventsChosen?.leadGenerated){
          setCardHasWarning(true)
          if (triggers?.[0]?.businessEventType === 'backInStock') {
            setWarningText("'Back in Stock' Popup on store is not enabled")
          } else {
            setWarningText("'Price Drop' Popup on website is not enabled")
          }
        } else if (!trackerEnabled && eventsChosen?.productViewed) {
          setCardHasWarning(true)
          setWarningText("Front-end trackers aren't enabled for 'Product Viewed'")
        }else{
          setCardHasWarning(false)
          setWarningText('')
        }
      } else {
        setCardHasWarning(false)
        setWarningText('')
      }
    })()

  }, [triggers, triggerUpdate])

  useEffect(() => {

    const triggers = botStore.getState().automationTriggerState.triggers ?? []

    if (!triggers?.length) {
      setTriggerType(undefined);
      setIsFrequencyCapped(false);
      setFrequencyCap({ value: "", unit: "hours" });
      setCancelTriggers({});
      setCurrentChannelId("");
    }
    if (triggers?.length && triggerUpdate) {
      const sortedTriggers = [...triggers].sort((a, b) => {
        if (a.type == 'ig') {
          return a?.instaId?.localeCompare(b?.instaId)
        } else {
          return a?.channelId?.localeCompare(b?.channelId)
        }
      });
      const sortedTriggerClone = cloneDeep(sortedTriggers);
      const updatedTriggers = sortedTriggerClone.map((trigger) => {
        delete trigger.isDuplicateChannelId
        return trigger
      })
      // Remove duplicate channel IDs
      const uniqueTriggers = [];
      const uniqueChannelIds = new Set();

      for (const trigger of updatedTriggers) {
        if (trigger.type === 'ig') {
          if (!uniqueChannelIds.has(trigger.instaId)) {
            // Add the trigger to the updated array
            uniqueTriggers.push(trigger);
            uniqueChannelIds.add(trigger.instaId);
          } else {
            trigger.isDuplicateChannelId = true;
            uniqueTriggers.push(trigger);
          }
        } else {
          if (!uniqueChannelIds.has(trigger.channelId)) {
            // Add the trigger to the updated array
            uniqueTriggers.push(trigger);
            uniqueChannelIds.add(trigger.channelId);
          } else {
            trigger.isDuplicateChannelId = true;
            uniqueTriggers.push(trigger);
          }
        }
      }
      dispatcher(
        AutomationTriggerActions.updateProperty(uniqueTriggers)
      );
      setTriggerUpdate(false)
    }
  }, [triggers?.length, triggerUpdate]);

  useEffect(() => {
    if (!isFrequencyCapped) {
      setFrequencyCap({ value: "", unit: "hours" });
    }
  }, [isFrequencyCapped]);

  useEffect(() => {
    if (saveTo !== "starting_point") return;
    handleAutomationSave();
  }, [saveTo]);

  useEffect(() => {
    document.addEventListener("mousedown", detectClickOutside);
    return () => {
      document.removeEventListener("mousedown", detectClickOutside);
    };
  }, []);

  const setTriggerTypeFromDb = () => {
    if (typeof itemList[flowId!]?.triggerType !== "undefined") {
      setTriggerType(itemList[flowId!]?.triggerType);
    }
  };

  const reRenderKeywordsNew = () => {
    return new Promise((resolve) => {
      if (flowMeta[flowId].keywordsNew) {
        Object.keys(flowMeta[flowId].keywordsNew).forEach((key) => {
          dispatcher(
            AutomationTriggerActions.insertProperty({
              header: "Customer says",
              subHeader: keyWordsStringify(flowMeta[flowId].keywordsNew[key]),
              type: "keyword",
              channelId: key,
            })
          );
        })
      }
      setKeywordsList(flowMeta[flowId].keywordsNew);
      resolve(true);
    });
  };

  const getFrequencyCapPayload = () => {
    if (!frequencyCap.value || !/^([0-9]+)$/.test(frequencyCap.value)) {
      setFrequencyCapError("This field is required");
      dispatcher(
        notificationDataStateActions.setNotifications({
          errorMessage: "Invalid or missing fields",
        })
      );
      setSaveTo("");
      setLoading(false);
      return {};
    }
    if (!freqThreshold || !/^([0-9]+)$/.test(freqThreshold)) {
      setThresholdError("This field is required");
      dispatcher(
        notificationDataStateActions.setNotifications({
          errorMessage: "Invalid or missing fields",
        })
      );
      setSaveTo("");
      setLoading(false);
      return {};
    }
    return {
      threshold: freqThreshold,
      ttlSeconds: getFrequencyCapInSeconds(),
      unit: frequencyCap.unit,
    };
  };

  const getCancelTriggerPayload = () => {
    let cancelTrigger = {};
    if (
      cancelTriggers &&
      Object.keys(cancelTriggers).length &&
      triggerType === TriggerTypes.event
    ) {
      Object.keys(cancelTriggers).forEach((index) => {
        if (cancelTriggers[index] !== "") {
          cancelTrigger[[cancelTriggers[index]]] = true;
        }
      });
    }
    return cancelTrigger;
  };

  const removeFallbackRefs = () => {
    const triggerConf = storeState.flow["N1"];
    const fallbackValues =
      triggerConf?.conditions?.[0]?.["operator_values"]?.["fallback"];
    if (fallbackValues) {
      const fallbackEdge = {
        sourceHandle: "fallback",
        target: fallbackValues["value"],
        source: "N1",
      };
      dispatcher(
        NodeActionActions.updateState({
          type: "edge",
          action: "deleteMultiple",
          edgeData: [fallbackEdge],
          nodeId: "",
        })
      );
      const dupConf = cloneDeep(triggerConf);
      dupConf.conditions[0]["operator_values"]["fallback"]["value"] = "";
      dispatcher(
        storeDataActions.updateInsertNode({
          flow: {},
          nodeId: "N1",
          data: dupConf,
        })
      );
    }
  };

  const handleAutomationSave = async () => {
    setLoading(true);
    let frequencyCap = {};
    let cancelTrigger = getCancelTriggerPayload();
    if (isFrequencyCapped) {
      frequencyCap = getFrequencyCapPayload();
      if (Object.keys(frequencyCap).length === 0) return;
    }
    const payload = {
      triggerType: triggerType,
      storeId: storeId,
      flowId: flowId,
      flowName: flowMeta[flowId]?.flowName || "",
      frequencyCap: frequencyCap,
      cancelTrigger: cancelTrigger,
      channel: channel,
      agentId: homeState?.userData?.agentId || homeState?.userData?.email,
    };
    if (triggerType !== "keyword") {
      removeFallbackRefs();
    }
    if (triggerType === "keyword" && !isFrequencyCapped) {
      removeFallbackRefs();
    }
    if (triggerType === TriggerTypes.keyword) {
      payload["keywords"] = keywordsLists;
    } else {
      payload["triggers"] = triggers;
    }
    if (segmentIdForRelay) {
      payload["segmentId"] = segmentIdForRelay;
    }
    const response = await saveStartingPoint(payload);
    if (response?.errors && response.errors.length > 0) {
      handleOnSaveError(response?.errors[0]);
    } else {
      if (response?.data && typeof response?.data === "object") {
        const formattedErrorState = new ErrorStateHelper().formatErrors(
          response?.data,
          flowId
        );
        dispatcher(
          errorDataActions.updateErrorState({
            ...errorStateComplete,
            errorModalOpenStatus:
              formattedErrorState.errorsCount > 0 ||
              formattedErrorState.warningsCount > 0,
            errors: { ...formattedErrorState.errors },
            errorsCount: formattedErrorState.errorsCount,
            warningsCount: formattedErrorState.warningsCount,
          })
        );
      }

      if (triggerType === TriggerTypes.keyword) {
        const keywords = payload.keywords;
        for (const key in keywords) {
          const properties = Object.keys(keywords[key]).filter(
            (k) => keywords[key][k].length > 0
          );
          const propertiesUsed = `${properties.join(", ")}`;

          eventHelper.trackEvent(
            TargetPlatform.Amplitude,
            AmplitudeKeys.service_used,
            {
              step: AmplitudeEventStep.trigger_added,
              triggerType: triggerType,
              propertiesUsed: propertiesUsed,
              screen: 'journey_builder',
            }
          );
        }
      } else {
        const triggers = payload.triggers;
        triggers.forEach((trigger) => {
          eventHelper.trackEvent(
            TargetPlatform.Amplitude,
            AmplitudeKeys.service_used,
            {
              step: AmplitudeEventStep.trigger_added,
              triggerType: triggerType,
              eventName: trigger.header,
              eventProperties: trigger.subHeader,
              screen: 'journey_builder',
            }
          );
        });
      }

      setSegmentIdForRelay("");
      itemList[flowId]!.triggerType = triggerType ?? "";
      if (typeof triggerType !== "undefined") {
        itemList[flowId]!.frequencyCapping = frequencyCap;
        itemList[flowId]!.cancelTrigger = cancelTrigger;
      }
      if (triggerType !== TriggerTypes.keyword) {
        deleteEventProperties();
        itemList[flowId]!.triggers = triggers;
        itemList[flowId]!.keywordsNew = {};
      } else {
        itemList[flowId]!.triggers = [];
        itemList[flowId]!.keywordsNew = keywordsLists;
      }
      onNodeAdd(
        "N1",
        itemList[flowId],
        setVariablesList,
        variablesList,
        "starting_point"
      );
      updateFlowMeta();
      closeComponentModal();
    }
  };

  const updateFlowMeta = () => {
    dispatcher(
      flowMetaStateActions.setFlowMeta({
        flowMeta: itemList,
        updateFlowMeta: true
      })
    );
  };

  const deleteEventProperties = () => {
    Object.keys(eventData || []).forEach((item) => {
      const split = item.split("Event");
      const filter = triggers.find(
        (trigger) => trigger?.nodes?.[0]?.metas?.[0]?.value === split[0]
      );
      if (!filter) {
        dispatcher(
          EventPropertyActions.deleteProperty({
            data: [item],
          })
        );
      }
    });
  };

  const handleOnSaveError = (error: { code: number; message: string }) => {
    dispatcher(
      notificationDataStateActions.setNotifications({
        errorMessage: error.message,
      })
    );
    setSaveTo("");
    setLoading(false);
  };

  const closeComponentModal = () => {
    dispatcher(
      nodeClickDataActions.setEditNode({
        nodeId: "",
        nodeType: "",
        nodeSubType: "",
        data: {},
      })
    );
    setSaveTo("close");
  };

  const detectClickOutside = (e: MouseEvent) => {
    if (popoverRef.current && !popoverRef.current.contains(e.target as Node)) {
      setShowPopover(false);
    }
  };

  const getFrequencyAndCancelTrigger = () => {
    const meta = flowMeta[flowId];
    if (meta?.["cancelTrigger"]) {
      const formattedTrigger = {};
      Object.keys(meta?.["cancelTrigger"]).forEach((cancelTrigger, index) => {
        formattedTrigger[index] = cancelTrigger;
      });
      setCancelTriggers(formattedTrigger);
    }
    if (meta?.["frequencyCapping"] && meta?.["frequencyCapping"]?.ttlSeconds) {
      const { unit, value } = getUnitFromSeconds(meta?.["frequencyCapping"]);
      setFrequencyCap({ unit, value });
      setFreqThreshold(meta?.["frequencyCapping"]?.["threshold"]);
      setIsFrequencyCapped(true);
    }
  };

  const getUnitFromSeconds = (frequencyCap: {
    unit: string;
    ttlSeconds: string;
    threshold: string;
  }) => {
    const unit = frequencyCap.unit;
    const seconds = frequencyCap.ttlSeconds;
    const value =
      seconds / (unit === "hours" ? 3600 : unit === "days" ? 86400 : 604800);
    return { unit, value };
  };

  const getAutomationsHelper = async () => {
    dispatcher(AutomationTriggerActions.resetProperty());
    setIsAutomationsLoading(true);
    return new Promise(async (resolve) => {
      const response: any = await getAutomations({
        storeId,
        flowId,
        triggerType: itemList[flowId!]?.triggerType,
        triggers: itemList[flowId!]?.triggers,
      });
      if (response?.errors && response.errors.length > 0) {
        dispatcher(
          notificationDataStateActions.setNotifications({
            errorMessage: response.errors[0].message,
          })
        );
      }

      if (response?.success && response?.data && response?.data.length > 0) {
        setTriggerType(itemList[flowId!]?.triggerType as TriggerTypes);

        response?.data.forEach((trigger: any) => {
          const { type, triggerType } = trigger;
          let segmentId = "";
          if (triggerType === TriggerTypes.relay) {
            if (trigger.trigger && trigger.trigger.eventProperties.length > 0) {
              const segment = trigger.trigger.eventProperties.find(
                (item) => item.firstOperand === "segmentId"
              );
              segmentId = segment.secondOperand;
            }
          }
          if (type || triggerType === TriggerTypes.webhook) {
            dispatcher(
              AutomationTriggerActions.insertProperty({
                ...trigger,
              })
            );
          } else {
            const data = trigger.uiData;
            if (segmentId) {
              data.segmentId = segmentId;
            }
            dispatcher(
              AutomationTriggerActions.insertProperty({
                ...data,
              })
            );
          }
        });
      }
      setIsAutomationsLoading(false);
      resolve(true);
    });
  };

  const getFrequencyCapInSeconds = () => {
    const { unit, value } = frequencyCap;
    const unitInSeconds =
      unit === "hours" ? 3600 : unit === "days" ? 86400 : 604800;
    return unitInSeconds * value;
  };

  const handleTriggerPopover = () => {
    if (!triggerType) {
      if (triggerBtnRef.current) {
        const rect = triggerBtnRef.current.getBoundingClientRect();
        setButtonRect(rect);
        setShowPopover(true);
      }
    } else {
      if (triggerType === TriggerTypes.ig) {
        handleIgClick();
      } else if (triggerType === TriggerTypes.ctwa) {
        handleCTWAClick();
      } else if (triggerType === TriggerTypes.keyword) {
        handleKeywordsClick();
      } else if (triggerType === TriggerTypes.relay) {
        handleSpecialDateClick();
      } else {
        handleEventsClick();
      }
    }
  };

  const handleKeywordsClick = () => {
    setBuilderMode("create");
    setShowKeywordsBuilder(true);
  };

  const handleEventsClick = () => {
    setBuilderMode("create");
    setShowEventsBuilder(true);
  };

  const handleSpecialDateClick = () => {
    setBuilderMode("create");
    setShowSpecialDateBuilder(true);
  };

  const handleIgClick = () => {
    setBuilderMode("create");
    setShowIgBuilder(true);
  };

  const handleCTWAClick = () => {
    setBuilderMode("create");
    setShowCtwaBuilder(true);
  };

  const deleteTrigger = (trigger: string) => {
    dispatcher(AutomationTriggerActions.deleteProperty({ trigger: trigger }));
    if (trigger.type === "keyword") {
      const keywordsObj = cloneDeep(keywordsLists);
      delete keywordsObj[trigger.channelId];
      setKeywordsList(keywordsObj);
    }
    setTriggerUpdate(true)
  };

  const insertTrigger = (data) => {
    if (data?.type) {
      setTriggerType(data?.type as TriggerTypes);
      if (data?.type === TriggerTypes.webhook) {
        dispatcher(AutomationTriggerActions.resetProperty())
      }
      dispatcher(AutomationTriggerActions.insertProperty(data));
      setShowEventsBuilder(false);
      setShowIgBuilder(false);
      setShowCtwaBuilder(false);
      setShowKeywordsBuilder(false);
      setShowWebhookBuilder(false);
      setBuilderNodes([]);
      setTriggerUpdate(true)
    }
    setAdAccountId("");
    setSegmentIdForRelay("");
  };

  const handleAutomationCardClick = (trigger) => {
    if (trigger?.segmentId) {
      setSegmentIdForRelay(trigger.segmentId);
    }
    setBuilderMode("edit");
    if (trigger.type === TriggerTypes.relay) {
      setBuilderNodes(trigger.frontendNodes);
    } else {
      setBuilderNodes(trigger.nodes);
    }
    setInstaId(trigger?.instaId);
    if (trigger.type === TriggerTypes.ig) {
      setShowIgBuilder(true);
    } else if (trigger.type === TriggerTypes.ctwa) {
      setAdAccountId(trigger?.adAccountId);
      setShowCtwaBuilder(true);
    } else if (trigger.type === TriggerTypes.keyword) {
      setShowKeywordsBuilder(true);
      setCurrentChannelId(trigger?.channelId ?? '')
    } else if (trigger.type === TriggerTypes.relay) {
      setShowSpecialDateBuilder(true);
      setCurrentChannelId(trigger?.channelId ?? '')
    } else if (trigger.type === TriggerTypes.webhook) {
      setShowWebhookBuilder(true);
      setCurrentChannelId(trigger?.channelId ?? '')
    } else if (trigger.type === TriggerTypes.businessEvents) {
      if (trigger?.businessEventType === 'backInStock') {
        setShowBackInStock(true)
      } else {
        setShowPriceDrop(true)
      }
      setCurrentChannelId(trigger?.channelId ?? '')
    } else {
      setShowEventsBuilder(true);
      setCurrentChannelId(trigger?.channelId ?? '')
    }
  };

  const getIgTriggerClass = () => {
    if (!triggers.length) {
      return "";
    }
    if (triggers[0].type === TriggerTypes.keyword) {
      return "keywords";
    }
    return triggers[0].type === TriggerTypes.ig ? "igTriggers" : "events";
  };

  const getWhatsappTriggerClass = () => {
    if (!triggers.length) {
      return "";
    }

    return triggers[0].type
  }


  const handleWhatsappClick = (trigger) => {
    if (trigger?.segmentId) {
      setSegmentIdForRelay(trigger.segmentId);
    }
    if (trigger.type === TriggerTypes.relay) {
      setBuilderNodes(trigger.frontendNodes);
    } else {
      setBuilderNodes(trigger.nodes);
    }
    setBuilderMode("edit");
    if (trigger.type === TriggerTypes.keyword) {
      setCurrentChannelId(trigger?.channelId ?? '')
      setWhatsAppScreen(WhatsappActionEventsList.DIRECTMESSAGE);
      setCurrentChannelId(trigger?.channelId ?? '')
      setShowModal(true);
    } else if (trigger.type === TriggerTypes.webhook) {
      setShowWebhookBuilder(true)
      setWhatsAppScreen(WhatsappActionEventsList.WEBHOOKS);
      setCurrentChannelId(trigger?.channelId ?? '')
      setShowModal(true)
    } else if (trigger.type === TriggerTypes.businessEvents) {
      if (trigger.businessEventType === 'priceDrop') {
        setShowPriceDrop(true)
        setWhatsAppScreen(WhatsappActionEventsList.PRICEDROP)
      } else {
        setShowBackInStock(true)
        setWhatsAppScreen(WhatsappActionEventsList.BACKINSTOCK)
      }
      setCurrentChannelId(trigger?.channelId ?? '')
      setShowModal(true)
    } else if (trigger.type === TriggerTypes.relay) {
      setShowSpecialDateBuilder(true);
      setCurrentChannelId(trigger?.channelId ?? '')
      setShowModal(true)
      setWhatsAppScreen(WhatsappActionEventsList.LASTORDERDATE);
    } else if (trigger.type === TriggerTypes.event) {
      setShowEventsBuilder(true);
      setCurrentChannelId(trigger?.channelId ?? '')
      setShowModal(true)
      const subType = trigger?.nodes?.[0]?.metas?.[0]?.value
      const category = allEventNames?.[subType]
      if (category) {
        switch (category) {
          case EventSubTypes.ACQUISITIONS:
            setWhatsAppScreen(WhatsappActionEventsList.ACQUISITIONS);
            break
          case EventSubTypes.INBOX:
            setWhatsAppScreen(WhatsappActionEventsList.HELPDESK);
            break
          case EventSubTypes.ECOMMERCE:
            setWhatsAppScreen(WhatsappActionEventsList.ECOMMERCE);
            break
          case EventSubTypes.INTEGRATIONS:
            setWhatsAppScreen(WhatsappActionEventsList.INTEGRATIONS);
            break
          case EventSubTypes.ONLINE_STORE:
            setWhatsAppScreen(WhatsappActionEventsList.POSTPURCHASE);
            break
          default:
            setWhatsAppScreen(WhatsappActionEventsList.EVENTSEDIT);
            break
        }
      } else {
        setWhatsAppScreen(WhatsappActionEventsList.EVENTSEDIT);
      }
    }
  }

  const handleIg = (trigger) => {
    if (trigger.type === TriggerTypes.ig) {
      setIgConfig(trigger);
      if (trigger.trigger.eventSelected === "Reacts") {
        setIgScreen(ActionEventsList.REACTIONS);
      } else if (trigger.trigger.eventSelected === "Mentions") {
        setIgScreen(ActionEventsList.MENTIONS);
      } else if (trigger.trigger.eventSelected === "Replies") {
        setIgScreen(ActionEventsList.REPLIES);
      } else {
        if (trigger.mediaType === "reel" && !trigger.igType) {
          setIgScreen(ActionEventsList.COMMENTSONREEL);
        } else if (trigger.mediaType === "post" && !trigger.igType) {
          setIgScreen(ActionEventsList.COMMENTSONPOST);
        } else if (trigger.mediaType === "ads" && !trigger.igType) {
          setIgScreen(ActionEventsList.COMMENTSONAD);
        }

        if (trigger.igType === "dm_on_ads") {
          setIgScreen(ActionEventsList.DIRECTMESSAGEADS);
        } else if (trigger.igType === "dm_on_post") {
          setIgScreen(ActionEventsList.DIRECTMESSAGEPOSTS);
        } else if (trigger.igType === "dm_on_reel") {
          setIgScreen(ActionEventsList.DIRECTMESSAGEREELS);
        }
      }
      setShowModal(true);
    } else if (trigger.type === TriggerTypes.keyword) {
      setCurrentChannelId(trigger?.channelId ?? '')
      setIgScreen(ActionEventsList.DIRECTMESSAGE);
      setShowModal(true);
    } else {
      setCurrentChannelId(trigger?.channelId ?? '')
      switch (trigger.header) {
        case "paymentLinkUpdate":
          setIgScreen(ActionEventsList.EVENTS_PAYLINK_UPDATE);
          break;
        case "enteredASegment":
          setIgScreen(ActionEventsList.EVENTS_ENTER_SEGMENT);
          break;
        case "exitedASegment":
          setIgScreen(ActionEventsList.EVENTS_EXIT_SEGMENT);
          break;
        case "chatSessionClosed":
          setIgScreen(ActionEventsList.EVENTS_CHAT_CLOSE);
          break;
      }
      setShowModal(true);
    }
  };

  const handleIgCardClick = (trigger) => {
    setBuilderMode("edit");
    setBuilderNodes(trigger.nodes);
    setInstaId(trigger?.instaId);
    handleIg(trigger);
  };

  const handleWhatsappCardClick = (trigger) => {
    handleWhatsappClick(trigger)
  }

  const handleBuilderClose = () => {
    setBuilderNodes([]);
    setShowIgBuilder(false);
    setShowCtwaBuilder(false);
    setShowEventsBuilder(false);
    setShowModal(false);
    setAdAccountId("");
    setShowSpecialDateBuilder(false);
    setSegmentIdForRelay("");
    setCurrentChannelId("");
  };

  const renderAutomationCardIcon = (trigger: any) => {
    if (trigger.type === TriggerTypes.ig) {
      if (trigger?.header?.includes('message')) {
        return <InstagramSendIcon width={16} height={16} />;
      } else if (trigger?.header?.includes('Comments')) {
        return <InstagramCommentIcon width={16} height={16} />;
      } else if (trigger.header.includes('story')) {
        return <InstagramReplyIcon width={16} height={16} />;
      } else {
        return <InstagramIcon width={16} height={16} />;
      }
    } else if (trigger.type === TriggerTypes.ctwa) {
      return <WhatsappIcon width={16} height={16} />;
    } else if (trigger.type === TriggerTypes.webhook) {
      return <WebhookIcon width={16} height={16}
        color={"#1A872C"} backgroundColor={"#E6F4E7"} borderColor={'#C3E4C4'} />;
    } else if (trigger.type === TriggerTypes.keyword) {
      return channel === 'whatsapp' ? <HashIcon width={16} height={16} color={'#1A872C'} /> : <InstagramSendIcon width={16} height={16} />;
    } else if (trigger.type === TriggerTypes.relay) {
      return <SpecialDateIcon width={16} height={16} color={channel === 'whatsapp' ? '#1A872C' : channel === 'instagram' ? '#510058' : COLORS.content.secondary} />;
    } else if (trigger.type === TriggerTypes.businessEvents) {
      const subType = trigger.businessEventType
      if (subType === 'backInStock') {
        return <BackInStockIcon width="16" height="16" color='#1A872C'/>
      } else if (subType === 'priceDrop') {
        return <PromocodeIcon width="16" height="16" color='#1A872C'/>
      }
    }
    return <AddEvent width={16} height={16} color={channel === 'whatsapp' ? '#1A872C' : channel === 'instagram' ? '#510058' : COLORS.content.secondary} />;
  };

  if (isAutomationsLoading) {
    return <AutomationShimmer />;
  }

  const onCancelTriggerChange = (index: number, option: DropdownOption) => {
    const formattedData = option as SingleOption;
    const newData = { ...cancelTriggers };
    if (newData?.[index]) {
      newData[index] = formattedData.value;
    } else {
      newData[index] = formattedData.value;
    }
    setCancelTriggers(newData);
  };

  const getOptions = (i: number) => {
    const cancelTriggersCopy = Object.values(cancelTriggers);
    const cancelOptionCopy = [...cancelOptions];
    if (cancelOptionCopy.length) {
      cancelOptionCopy.forEach((option, index) => {
        if ((option as SingleOption)?.value === cancelTriggersCopy[i]) {
          cancelOptionCopy[index] = {
            ...option,
            selected: true,
          };
        }
      });
      return cancelOptionCopy;
    } else return [];
  };

  const frequencyOptions = [
    {
      label: "Hour(s)",
      value: "hours",
    },
    {
      label: "Day(s)",
      value: "days",
    },
    {
      label: "Week(s)",
      value: "weeks",
    },
  ];

  const getFrequencyOptions = () => {
    const options = frequencyOptions.map((item) => {
      if (item.value === frequencyCap.unit) {
        return {
          ...item,
          selected: true,
        };
      }
      return item;
    });
    return options;
  };

  const onAddCancelTrigger = () => {
    const cancelUpdated = { ...cancelTriggers };
    const updatedIndex = Object.keys(cancelTriggers).length;
    cancelUpdated[updatedIndex] = "";
    setCancelTriggers(cancelUpdated);
  };

  const onDeleteCancelTrigger = (index: number) => {
    const cancelUpdated = { ...cancelTriggers };
    delete cancelUpdated[index];
    const indexBasedMap = {};
    Object.values(cancelUpdated).forEach((value, index) => {
      indexBasedMap[index] = value;
    });
    setCancelTriggers(indexBasedMap);
  };

  const handleFrequencyChange = (option: DropdownOption) => {
    setFrequencyCap((prev) => ({
      ...prev,
      unit: (option as SingleOption).value,
    }));
  };

  const handleThresholdValue = (text: string) => {
    if (!text) {
      setFreqThreshold("");
      return;
    }
    if (text && frequencyThresholdError !== "") {
      setThresholdError("");
    }
    const isValidNumber = /^([0-9]+)$/.test(text); // Only allow whole numbers
    if (isValidNumber) {
      setFreqThreshold(Number(text));
    } else {
      dispatcher(
        notificationDataStateActions.setNotifications({
          errorMessage: "Only integers are allowed",
        })
      );
      setFreqThreshold("");
      setThresholdError("Only integers are allowed");
    }
  };

  const handleFrequencyValue = (text: string) => {
    if (!text) {
      setFrequencyCap((prev) => ({ ...prev, value: "" }));
      return;
    }
    if (text && frequencyCapError !== "") {
      setFrequencyCapError("");
    }
    const isValidNumber = /^([0-9]+)$/.test(text);
    if (isValidNumber) {
      const value = Number(text);
      setFrequencyCap((prev) => ({ ...prev, value }));
    } else {
      dispatcher(
        notificationDataStateActions.setNotifications({
          errorMessage: "Decimal numbers not allowed",
        })
      );
      setFrequencyCap((prev) => ({ ...prev, value: "" }));
      setFrequencyCapError("Decimal numbers not allowed");
    }
  };

  const getSelectedBlock = (id: string) => {
    if (channel === 'whatsapp') {
      if (id === WhatsappActionEventsList.WEBHOOKS) {
        setShowWebhookBuilder(true)
      } else if (id === WhatsappActionEventsList.PRICEDROP) {
        setShowPriceDrop(true)
      } else if (id === WhatsappActionEventsList.BACKINSTOCK) {
        setShowBackInStock(true)
      } else if (id === WhatsappActionEventsList.CLICKONWHATSAPPADS) {
        handleCTWAClick()
      } else if ([WhatsappActionEventsList.FIRSTORDERDATE, WhatsappActionEventsList.LASTORDERDATE, WhatsappActionEventsList.LIFETIMEORDERVALUE].includes(id)) {
        handleSpecialDateClick()
      } else if ([WhatsappActionEventsList.ECOMMERCE, WhatsappActionEventsList.ACQUISITIONS, WhatsappActionEventsList.POSTPURCHASE, WhatsappActionEventsList.HELPDESK, WhatsappActionEventsList.INTEGRATIONS].includes(id)) {
        handleEventsClick()
      }
      setWhatsAppScreen(id);
    } else {
      setIgScreen(id);
    }
  };

  const getScreen = () => {
    switch (channel) {
      case 'whatsapp':
        return getWhatsappScreen()
      case 'instagram':
        return getIgScreen()
      default:
        return <IgTriggersSelection
          getSelectedBlock={getSelectedBlock}
          setShowModal={setShowModal}
          channel={channel}
        />
    }
  }

  const getWhatsappScreen = () => {
    switch (whatsAppScreen) {
      case WhatsappActionEventsList.WHATSAPPMEASSAGE:
        return <KeywordsTrigger
          keywordObject={keywordsLists}
          setKeywordsObj={setKeywordsList}
          onClose={() => {
            setCurrentChannelId("");
            setShowModal(false);
          }}
          onSave={(data) => {
            if (data.subHeader === '') {
              deleteTrigger(data)
            } else {
              insertTrigger(data);
            }
          }}
          storeId={storeId}
          channel={channel}
          currentChannelId={currentChannelId}
        />
      case WhatsappActionEventsList.CLICKONWHATSAPPADS:
        return <CtwaBuilder
          accountId={adAccountId}
          storeId={storeId}
          mode={builderMode}
          nodes={builderNodes}
          visible={showCtwaBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
        />
      case WhatsappActionEventsList.BACKINSTOCK:
        return <BusinessEvents
          visible={showBackInStock}
          channel={channel}
          storeId={storeId}
          currentChannelId={currentChannelId}
          onClose={() => {
            setShowBackInStock(false)
          }}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => setShowBackInStock(false)}
          data={triggers || []}
          mode="backInStock"
        />
      case WhatsappActionEventsList.PRICEDROP:
        return <BusinessEvents
          visible={showPriceDrop}
          channel={channel}
          storeId={storeId}
          currentChannelId={currentChannelId}
          onClose={() => {
            setShowPriceDrop(false)
          }}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => setShowPriceDrop(false)}
          data={triggers || []}
          mode="priceDrop"
        />
      case WhatsappActionEventsList.WEBHOOKS:
        return <WebhookBuilder
          channel={channel}
          storeId={storeId}
          mode={'create'}
          visible={showWebhookBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => setShowWebhookBuilder(false)}
          data={triggers || []}
          currentChannelId={currentChannelId}
        />
      case WhatsappActionEventsList.LASTORDERDATE:
        return <SpecialDate
          segmentId={segmentIdForRelay}
          storeId={storeId}
          mode={builderMode}
          nodes={genWhatsAppBuilderNodes(whatsAppScreen)}
          visible={showSpecialDateBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
          channel={channel}
          currentChannelId={currentChannelId}
        />
      case WhatsappActionEventsList.FIRSTORDERDATE:
        return <SpecialDate
          segmentId={segmentIdForRelay}
          storeId={storeId}
          mode={builderMode}
          nodes={genWhatsAppBuilderNodes(whatsAppScreen)}
          visible={showSpecialDateBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
          channel={channel}
          currentChannelId={currentChannelId}
        />
      case WhatsappActionEventsList.LIFETIMEORDERVALUE:
        return <SpecialDate
          segmentId={segmentIdForRelay}
          storeId={storeId}
          mode={builderMode}
          nodes={genWhatsAppBuilderNodes(whatsAppScreen)}
          visible={showSpecialDateBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
          channel={channel}
          currentChannelId={currentChannelId}
        />
      case WhatsappActionEventsList.ACQUISITIONS:
        return <EventsBuilder
          storeId={storeId}
          mode={builderMode}
          nodes={builderNodes}
          visible={showEventsBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
          channel={channel}
          currentChannelId={currentChannelId}
          category="Acquisitions"
        />
      case WhatsappActionEventsList.ECOMMERCE:
        return <EventsBuilder
          storeId={storeId}
          mode={builderMode}
          nodes={builderNodes}
          visible={showEventsBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
          channel={channel}
          currentChannelId={currentChannelId}
          category="E-commerce"
        />
      case WhatsappActionEventsList.HELPDESK:
        return <EventsBuilder
          storeId={storeId}
          mode={builderMode}
          nodes={builderNodes}
          visible={showEventsBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
          channel={channel}
          currentChannelId={currentChannelId}
          category="Inbox"
        />
      case WhatsappActionEventsList.INTEGRATIONS:
        return <EventsBuilder
          storeId={storeId}
          mode={builderMode}
          nodes={builderNodes}
          visible={showEventsBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
          channel={channel}
          currentChannelId={currentChannelId}
          category="Integrations"
        />
      case WhatsappActionEventsList.EVENTSEDIT:
        return <EventsBuilder
          storeId={storeId}
          mode={builderMode}
          nodes={builderNodes}
          visible={showEventsBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
          channel={channel}
          currentChannelId={currentChannelId}
        />
      case WhatsappActionEventsList.POSTPURCHASE:
        return <EventsBuilder
          storeId={storeId}
          mode={builderMode}
          nodes={builderNodes}
          visible={showEventsBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
          channel={channel}
          currentChannelId={currentChannelId}
          category={"Online Store"}
        />
      default:
        return <IgTriggersSelection
          getSelectedBlock={getSelectedBlock}
          setShowModal={setShowModal}
          channel={channel}
          igClass={getWhatsappTriggerClass()}
        />
    }
  }

  const getIgScreen = () => {
    switch (igScreen) {
      case ActionEventsList.COMMENTSONPOST:
        return (
          <CommentsConfigScreen
            instaId={instaId}
            storeId={storeId}
            mediaType={MEDIA_TYPES.POST}
            navigateBack={setIgScreen}
            setShowModal={setShowModal}
            fetchedConf={createNew ? {} : igConfig}
            onSave={insertTrigger}
            isDM={false}
          />
        );
      case ActionEventsList.COMMENTSONREEL:
        return (
          <CommentsConfigScreen
            instaId={instaId}
            storeId={storeId}
            mediaType={MEDIA_TYPES.REEL}
            navigateBack={setIgScreen}
            setShowModal={setShowModal}
            fetchedConf={createNew ? {} : igConfig}
            onSave={insertTrigger}
            isDM={false}
          />
        );
      case ActionEventsList.DIRECTMESSAGEPOSTS:
        return (
          <CommentsConfigScreen
            instaId={instaId}
            storeId={storeId}
            mediaType={MEDIA_TYPES.POST}
            navigateBack={setIgScreen}
            setShowModal={setShowModal}
            fetchedConf={createNew ? {} : igConfig}
            onSave={insertTrigger}
            isDM={true}
          />
        );
      case ActionEventsList.DIRECTMESSAGEREELS:
        return (
          <CommentsConfigScreen
            instaId={instaId}
            storeId={storeId}
            mediaType={MEDIA_TYPES.REEL}
            navigateBack={setIgScreen}
            setShowModal={setShowModal}
            fetchedConf={createNew ? {} : igConfig}
            onSave={insertTrigger}
            isDM={true}
          />
        );
      case ActionEventsList.COMMENTSONAD:
        return (
          <CommentsConfigScreen
            instaId={instaId}
            storeId={storeId}
            mediaType={"ads"}
            navigateBack={setIgScreen}
            setShowModal={setShowModal}
            fetchedConf={createNew ? {} : igConfig}
            onSave={insertTrigger}
            isDM={false}
          />
        );
      case ActionEventsList.REACTIONS:
        return (
          <Reactions
            instaId={instaId}
            storeId={storeId}
            navigateBack={setIgScreen}
            setShowModal={setShowModal}
            fetchedConf={createNew ? {} : igConfig}
            onSave={insertTrigger}
          />
        );
      case ActionEventsList.MENTIONS:
        return (
          <Mentions
            instaId={instaId}
            storeId={storeId}
            navigateBack={setIgScreen}
            setShowModal={setShowModal}
            fetchedConf={createNew ? {} : igConfig}
            onSave={insertTrigger}
          />
        );
      case ActionEventsList.REPLIES:
        return (
          <Replies
            instaId={instaId}
            storeId={storeId}
            navigateBack={setIgScreen}
            setShowModal={setShowModal}
            fetchedConf={createNew ? {} : igConfig}
            onSave={insertTrigger}
          />
        );
      case ActionEventsList.EVENTS_CHAT_CLOSE:
        return (
          <EventsBuilder
            storeId={storeId}
            mode={builderMode}
            nodes={genBuilderNodes(igScreen)}
            visible={true}
            onSave={(data: any) => insertTrigger(data)}
            onClose={() => handleBuilderClose()}
            channel={channel}
            currentChannelId={currentChannelId}
          />
        );
      case ActionEventsList.EVENTS_ENTER_SEGMENT:
        return (
          <EventsBuilder
            storeId={storeId}
            mode={builderMode}
            nodes={genBuilderNodes(igScreen)}
            visible={true}
            onSave={(data: any) => insertTrigger(data)}
            onClose={() => handleBuilderClose()}
            channel={channel}
            currentChannelId={currentChannelId}
          />
        );
      case ActionEventsList.EVENTS_EXIT_SEGMENT:
        return (
          <EventsBuilder
            storeId={storeId}
            mode={builderMode}
            nodes={genBuilderNodes(igScreen)}
            visible={true}
            onSave={(data: any) => insertTrigger(data)}
            onClose={() => handleBuilderClose()}
            channel={channel}
            currentChannelId={currentChannelId}
          />
        );
      case ActionEventsList.EVENTS_PAYLINK_UPDATE:
        return (
          <EventsBuilder
            storeId={storeId}
            mode={builderMode}
            nodes={genBuilderNodes(igScreen)}
            visible={true}
            onSave={(data: any) => insertTrigger(data)}
            onClose={() => handleBuilderClose()}
            channel={channel}
            currentChannelId={currentChannelId}
          />
        );
      case ActionEventsList.FIRST_ORDER_DATE:
      case ActionEventsList.LAST_ORDER_DATE:
        return (
          <SpecialDate
            segmentId={segmentIdForRelay}
            storeId={storeId}
            mode={builderMode}
            nodes={genBuilderNodes(igScreen)}
            visible={true}
            onSave={(data: any) => insertTrigger(data)}
            onClose={() => handleBuilderClose()}
            channel={channel}
            currentChannelId={currentChannelId}
          />
        );
      case ActionEventsList.DIRECTMESSAGE:
        return (
          <KeywordsTrigger
            keywordObject={keywordsLists}
            setKeywordsObj={setKeywordsList}
            onClose={() => {
              setCurrentChannelId("");
              setShowModal(false);
            }}
            onSave={(data) => {
              if (data.subHeader === '') {
                deleteTrigger(data)
              } else {
                insertTrigger(data);
              }
            }}
            storeId={storeId}
            channel={channel}
            currentChannelId={currentChannelId}
          />
        );
      case ActionEventsList.DIRECTMESSAGEADS:
        return (
          <CommentsConfigScreen
            instaId={instaId}
            storeId={storeId}
            mediaType={"ads"}
            navigateBack={setIgScreen}
            setShowModal={setShowModal}
            fetchedConf={createNew ? {} : igConfig}
            onSave={insertTrigger}
            isDM={true}
          />
        );
      default:
        return (
          <IgTriggersSelection
            getSelectedBlock={getSelectedBlock}
            igClass={getIgTriggerClass()}
            setShowModal={setShowModal}
            channel={channel}
          />
        );
    }
  };

  const genWhatsAppBuilderNodes = (eventType: string) => {
    const id = uuidv4()
    const eventsMapper = {
      [WhatsappActionEventsList.LASTORDERDATE]: {
        nodes: [
          {
            "type": "USER_PROPERTY_FILTER",
            "properties": [
              {
                "name": "lastOrderDate",
                "dataType": "exactly",
                "_id": id,
                "operator": "exactly",
                "value": [
                  "1",
                  "days"
                ]
              }
            ],
            "metas": [],
            "_id": id,
            "PropertyOperator": "AND"
          }
        ],
      },
      [WhatsappActionEventsList.FIRSTORDERDATE]: {
        nodes: [
          {
            "type": "USER_PROPERTY_FILTER",
            "properties": [
              {
                "name": "initialOrderDate",
                "dataType": "exactly",
                "_id": id,
                "operator": "exactly",
                "value": [
                  "1",
                  "days"
                ]
              }
            ],
            "metas": [],
            "_id": id,
            "PropertyOperator": "AND"
          }
        ]
      },
      [WhatsappActionEventsList.LIFETIMEORDERVALUE]: {
        nodes: [
          {
            "type": "USER_PROPERTY_FILTER",
            "properties": [
              {
                "name": "totalOrderValue",
                "dataType": "integer",
                "_id": id,
                "operator": "is",
                "value": [
                ]
              }
            ],
            "metas": [],
            "_id": id,
            "PropertyOperator": "AND"
          }
        ]
      }
    };

    if (builderNodes && builderNodes.length) {
      return builderNodes;
    } else {
      return eventsMapper[eventType].nodes;
    }
  }

  const genBuilderNodes = (eventType: string) => {
    const eventsMapper = {
      [ActionEventsList.EVENTS_PAYLINK_UPDATE]: {
        nodes: [
          {
            type: "EVENTS_TRIGGER",
            properties: [],
            metas: [
              {
                key: "triggerName",
                value: "paymentLinkUpdate",
              },
            ],
            _id: uuidv4(),
            PropertyOperator: "AND",
          },
        ],
      },
      [ActionEventsList.EVENTS_ENTER_SEGMENT]: {
        nodes: [
          {
            type: "EVENTS_TRIGGER",
            properties: [],
            metas: [
              {
                key: "triggerName",
                value: "enteredASegment",
              },
            ],
            _id: uuidv4(),
            PropertyOperator: "AND",
          },
        ],
      },
      [ActionEventsList.EVENTS_EXIT_SEGMENT]: {
        nodes: [
          {
            type: "EVENTS_TRIGGER",
            properties: [],
            metas: [
              {
                key: "triggerName",
                value: "exitedASegment",
              },
            ],
            _id: uuidv4(),
            PropertyOperator: "AND",
          },
        ],
      },
      [ActionEventsList.EVENTS_CHAT_CLOSE]: {
        nodes: [
          {
            type: "EVENTS_TRIGGER",
            properties: [],
            metas: [
              {
                key: "triggerName",
                value: "chatSessionClosed",
              },
            ],
            _id: uuidv4(),
            PropertyOperator: "AND",
          },
        ],
      },
      // [ActionEventsList.LAST_ORDER_DATE]: {
      //   nodes: [
      //     {
      //       type: "USER_PROPERTY_FILTER",
      //       properties: [
      //         {
      //           _id: uuidv4(),
      //           dataType: "exactly",
      //           name: "lastOrderDate",
      //         },
      //       ],
      //       metas: [],
      //       _id: uuidv4(),
      //       PropertyOperator: "AND",
      //     },
      //   ],
      // },
      // [ActionEventsList.FIRST_ORDER_DATE]: {
      //   nodes: [
      //     {
      //       type: "USER_PROPERTY_FILTER",
      //       properties: [
      //         {
      //           _id: uuidv4(),
      //           dataType: "exactly",
      //           name: "initialOrderDate",
      //         },
      //       ],
      //       metas: [],
      //       _id: uuidv4(),
      //       PropertyOperator: "AND",
      //     },
      //   ],
      // },
    };

    if (builderNodes && builderNodes.length) {
      return builderNodes;
    } else {
      return eventsMapper[eventType].nodes;
    }
  };

  return (
    <>
      <AutomationTriggerStyle>
        <div className="title">Setup trigger to start your journey</div>
        <div className="desc">
          Journey will be triggered whenever the selected event happens
        </div>

        {!!triggers && triggers.length > 0 && (
          <>
            <BodySecondary style={{ marginTop: 16 }}>
              When this happens
            </BodySecondary>
            <>
              {triggers.map((trigger: any, index) => {
                const displayName = getChannelIdName(channel, channels, trigger);
                const hasError = hasChannelError(channel, trigger?.channelId ?? trigger?.instaId, channels);
                return (
                  <AutomationCard
                    hasWarning={cardHasWarning}
                    media={trigger?.media}
                    mediaType={trigger?.mediaType}
                    key={trigger.id ?? index}
                    onClick={() => {
                      if (channel === "instagram") {
                        setCreateNew(false);
                        handleIgCardClick(trigger);
                      } else if (channel === 'whatsapp') {
                        handleWhatsappCardClick(trigger)
                      } else {
                        handleAutomationCardClick(trigger);
                      }
                    }}
                    onCross={() => deleteTrigger(trigger)}
                    header={unCamelCase(trigger.header)}
                    subHeader={trigger.subHeader}
                    icon={renderAutomationCardIcon(trigger)}
                    channelIdName={hasError && !trigger.isDuplicateChannelId ? "Connect an account" : displayName}
                    channel={channel}
                    hasError={hasError}
                  />
                );
              })}
            </>
          </>
        )}
        {
          warningText && (
            <>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 8
              }}>
                <AlertTriangle width={20} height={20} color={'#99631B'} />
                <BodySecondary color={'#99631B'}>{warningText}</BodySecondary>
              </div>
            </>
          )
        }
        <>
          <div className="trigger-select">
            {!isAddTriggerDisabled ? <Button
              ref={triggerBtnRef}
              buttonText="Add trigger"
              buttonType="dashRegular"
              size="large"
              onClick={() => {
                if (channel === "email") {
                  handleTriggerPopover();
                  return;
                }

                if (channel === "whatsapp") {
                  setWhatsAppScreen("default");
                  setCreateNew(true);
                  setShowModal(true);
                  return;
                }

                if (channel === "instagram") {
                  setIgScreen("default");
                  setCreateNew(true);
                  setShowModal(true);
                }
              }}
              disabled={isAddTriggerDisabled}
              matchParentWidth
              color={COLORS.background.brand}
              LeadingIcon={() => isAddTriggerDisabled ? <PlusIcon color={COLORS.content.inactive} /> : <PlusIcon />}
            /> : <></>}

            {!triggerType &&
              (channel === "whatsapp" || channel === "email") && (
                <PopOver
                  ref={popoverRef}
                  buttonRect={buttonRect}
                  visible={showPopover}
                  width={225}
                  height={100}
                >
                  {(channel === "whatsapp" || channel === "instagram") && (
                    <>
                      <PopOverItem onClick={handleKeywordsClick}>
                        <GreyCircle
                          size={40}
                          icon={<HashIcon width={20} height={20} />}
                        />
                        <BodyPrimary
                          style={{ marginLeft: 16 }}
                          color={COLORS.content.primary}
                        >
                          Keywords
                        </BodyPrimary>
                      </PopOverItem>
                      <Separator />
                    </>
                  )}

                  <PopOverItem onClick={handleEventsClick}>
                    <GreyCircle
                      size={40}
                      icon={<AddEvent width={20} height={20} />}
                    />
                    <BodyPrimary
                      style={{ marginLeft: 16 }}
                      color={COLORS.content.primary}
                    >
                      Events
                    </BodyPrimary>
                  </PopOverItem>

                  <Separator />

                  <PopOverItem onClick={handleSpecialDateClick}>
                    <GreyCircle size={40} icon={<SpecialDateIcon />} />
                    <BodyPrimary
                      style={{ marginLeft: 16 }}
                      color={COLORS.content.primary}
                    >
                      Anniversary dates
                    </BodyPrimary>
                  </PopOverItem>

                  {channel !== "email" && <Separator />}

                  {channel === "instagram" && (
                    <>
                      <PopOverItem onClick={handleIgClick}>
                        <GreyCircle
                          size={40}
                          icon={<InstagramIcon width={20} height={20} />}
                        />
                        <BodyPrimary
                          style={{ marginLeft: 16 }}
                          color={COLORS.content.primary}
                        >
                          Instagram
                        </BodyPrimary>
                      </PopOverItem>
                    </>
                  )}

                  {channel === "whatsapp" && (
                    <>
                      <PopOverItem onClick={handleCTWAClick}>
                        <GreyCircle
                          size={40}
                          icon={<WhatsappIcon width={20} height={20} />}
                        />
                        <BodyPrimary
                          style={{ marginLeft: 16 }}
                          color={COLORS.content.primary}
                        >
                          CTWA
                        </BodyPrimary>
                      </PopOverItem>
                    </>
                  )}

                  {channel === "whatsapp" && <Separator />}

                  {
                    channel === "whatsapp" && (
                      <>
                        <PopOverItem onClick={() => {
                          setShowWebhookBuilder(true);
                        }}>
                          <GreyCircle
                            size={40}
                            icon={<WebhookIcon />}
                          />
                          <BodyPrimary
                            style={{ marginLeft: 16 }}
                            color={COLORS.content.primary}
                          >
                            Webhook
                          </BodyPrimary>
                        </PopOverItem>
                      </>
                    )
                  }

                  {(channel === "whatsapp" || channel === "email") && <Separator />}

                  {
                    (channel === "whatsapp" || channel === "email") && (
                      <>
                        <PopOverItem onClick={() => {
                          setShowBackInStock(true);
                        }}>
                          <GreyCircle
                            size={40}
                            icon={<BackInStockIcon width={20} height={20} color={COLORS.content.primary}/>}
                          />
                          <BodyPrimary
                            style={{ marginLeft: 16 }}
                            color={COLORS.content.primary}
                          >
                            Back in Stock
                          </BodyPrimary>
                        </PopOverItem>
                      </>
                    )
                  }

                  {(channel === "whatsapp" || channel === "email") && <Separator />}

                  {
                    (channel === "whatsapp" || channel === "email") && (
                      <>
                        <PopOverItem onClick={() => {
                          setShowPriceDrop(true);
                        }}>
                          <GreyCircle
                            size={40}
                            icon={<PromocodeIcon width={20} height={20} color={COLORS.content.primary}/>}
                          />
                          <BodyPrimary
                            style={{ marginLeft: 16 }}
                            color={COLORS.content.primary}
                          >
                            Price Drop
                          </BodyPrimary>
                        </PopOverItem>
                      </>
                    )
                  }
                </PopOver>
              )}
          </div>
        </>
        {triggerType === TriggerTypes.event && (
          <>
            <div className="title" style={{ marginTop: 28 }}>
              Cancel trigger
            </div>
            <div className="desc" style={{ marginBottom: 16 }}>
              Any ongoing session with this flow will be interrupted when the
              following event happens
            </div>
            {cancelTriggers &&
              Object.keys(cancelTriggers)?.map((cancelTrigger, index) => {
                return (
                  <div
                    className="d-flex"
                    style={{ marginBottom: 12 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown
                      key={index}
                      size={"small"}
                      options={getOptions(index)}
                      width={"352px"}
                      onSelect={(option) => {
                        onCancelTriggerChange(index, option);
                      }}
                    />
                    <div className={"delete-block"}>
                      <div
                        className={"m-auto"}
                        onClick={(e) => {
                          onDeleteCancelTrigger(index);
                          e.stopPropagation();
                        }}
                      >
                        <DeleteIcon width={20} height={20}></DeleteIcon>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="trigger-select">
              <Button
                buttonText="Cancel trigger"
                buttonType="dashRegular"
                size="large"
                onClick={() => onAddCancelTrigger()}
                matchParentWidth
                color={COLORS.background.brand}
                LeadingIcon={() => <PlusIcon />}
              />
            </div>
          </>
        )}
        {!!triggers && triggers.length > 0 && (
          <div>
            <div className="d-flex" style={{ marginTop: 28 }}>
              <div
                onClick={(e) => e.stopPropagation()}
                style={{ marginTop: "3px" }}
              >
                <CheckBox
                  isChecked={isFrequencyCapped}
                  onValueChange={() => {
                    setIsFrequencyCapped(!isFrequencyCapped);
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: 8,
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                  }}
                >
                  <div className={isFrequencyCapped && "title"}>Frequency limit</div>
                  <Tooltip
                    body={""}
                    tooltipContent={
                      <BodyCaption
                        color={COLORS.content.primaryInverse}
                        style={{ whiteSpace: "pre-line", padding: 8 }}
                      >
                        Limit the triggering of journey within the specified
                        period.
                      </BodyCaption>
                    }
                    width={300}
                    delay={300}
                    placement={"right"}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InfoIcon width={16} height={16} />
                    </div>
                  </Tooltip>
                </div>
                {isFrequencyCapped && (
                  <BodySecondary>
                    Limit the triggering of this journey to
                  </BodySecondary>
                )}
                {isFrequencyCapped && (
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "center",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Input
                      noErrorHint
                      errorMessage={frequencyThresholdError}
                      onChangeText={(text) => {
                        handleThresholdValue(text);
                      }}
                      placeholder="00"
                      type="text"
                      variant="small"
                      width="64px"
                      value={freqThreshold}
                    />
                    <div style={{ width: 10 }} />
                    <BodySecondary>time(s)</BodySecondary>
                  </div>
                )}
                {isFrequencyCapped && (
                  <BodySecondary>Within the period of</BodySecondary>
                )}
                {isFrequencyCapped && (
                  <div className="d-flex" onClick={(e) => e.stopPropagation()}>
                    <Input
                      noErrorHint
                      errorMessage={frequencyCapError}
                      onChangeText={(text) => handleFrequencyValue(text)}
                      placeholder="00"
                      type="text"
                      variant="small"
                      width="64px"
                      value={frequencyCap.value}
                    />
                    <div style={{ width: 10 }} />
                    <Dropdown
                      size={"small"}
                      options={getFrequencyOptions()}
                      width={"200px"}
                      onSelect={(option) => {
                        handleFrequencyChange(option);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {isFrequencyCapped && (
              <div
                style={{
                  marginTop: 16,
                  marginLeft: 24,
                  display: "flex",
                  flexDirection: "row",
                  gap: 8,
                  border: `1px solid ${COLORS.stroke.primary}`,
                  backgroundColor: COLORS.surface.hovered,
                  padding: 12,
                  borderRadius: 4,
                }}
              >
                <div>
                  <InfoIcon width={16} height={16} />
                </div>
                <BodyCaption>
                  For keyword triggers, after the frequency cap hits, the
                  fallback will be triggered.
                </BodyCaption>
              </div>
            )}
            <div style={{ height: 30 }} />
          </div>
        )}
        {triggerType === TriggerTypes.event && (
          <div className="event-warning">
            <Tag
              leadingIconId="events-warning"
              LeadingIcon={InfoIcon}
              tagText={
                <>
                  <div>• Trigger may take upto 5 mins to initiate.</div>
                  <div>
                    • Any new changes made will take effect after 10 mins.
                  </div>
                </>
              }
              type="warning"
            />
          </div>
        )}
        {triggerType === TriggerTypes.businessEvents && (
          <div className="event-warning">
            <Tag
              leadingIconId="events-warning"
              LeadingIcon={InfoIcon}
              tagText={
                <>
                  {triggers?.[0]?.['businessEventType'] === 'priceDrop' ? <BodyCaption color="#99631B">Price Drop checks for products are done daily between 10 AM to 5 PM.</BodyCaption>
                    : <BodyCaption color="#99631B">Restock checks for products are done daily between 10 AM to 5 PM.</BodyCaption>}
                </>
              }
              type="warning"
            />
          </div>
        )}
      </AutomationTriggerStyle>

      {showModal && getScreen()}

      {showKeywordsBuilder && (
        <KeywordsTrigger
          keywordObject={keywordsLists}
          setKeywordsObj={setKeywordsList}
          onClose={() => {
            setCurrentChannelId("");
            setShowKeywordsBuilder(false);
          }}
          onSave={(data) => {
            if (data.subHeader === '') {
              deleteTrigger(data)
            } else {
              insertTrigger(data);
            }
          }}
          storeId={storeId}
          channel={channel}
          currentChannelId={currentChannelId}
        />
      )}

      {(showEventsBuilder && !(channel === 'whatsapp' || channel === 'instagram')) && (
        <EventsBuilder
          storeId={storeId}
          mode={builderMode}
          nodes={builderNodes}
          visible={showEventsBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
          channel={channel}
          currentChannelId={currentChannelId}
        />
      )}

      {(showSpecialDateBuilder && !(channel === 'whatsapp' || channel === 'instagram')) && (
        <SpecialDate
          segmentId={segmentIdForRelay}
          storeId={storeId}
          mode={builderMode}
          nodes={builderNodes}
          visible={showSpecialDateBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
          channel={channel}
          currentChannelId={currentChannelId}
        />
      )}

      {showIgBuilder && (
        <IgBuilder
          instaId={instaId}
          storeId={storeId}
          mode={builderMode}
          nodes={builderNodes}
          visible={showIgBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
        />
      )}

      {(showCtwaBuilder && !(channel === 'whatsapp' || channel === 'instagram')) && (
        <CtwaBuilder
          accountId={adAccountId}
          storeId={storeId}
          mode={builderMode}
          nodes={builderNodes}
          visible={showCtwaBuilder}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => handleBuilderClose()}
        />
      )}

      {
        (showBackInStock && !(channel === 'whatsapp' || channel === 'instagram')) && <BusinessEvents
          visible={showBackInStock}
          channel={channel}
          storeId={storeId}
          currentChannelId={currentChannelId}
          onClose={() => {
            setShowBackInStock(false)
          }}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => setShowBackInStock(false)}
          data={triggers || []}
          mode="backInStock"
        />
      }

      {
        (showPriceDrop && !(channel === 'whatsapp' || channel === 'instagram')) && <BusinessEvents
          visible={showPriceDrop}
          channel={channel}
          storeId={storeId}
          currentChannelId={currentChannelId}
          onClose={() => {
            setShowPriceDrop(false)
          }}
          onSave={(data: any) => insertTrigger(data)}
          onClose={() => setShowPriceDrop(false)}
          data={triggers || []}
          mode="priceDrop"
        />
      }

    </>
  );
};

export default AutomationTrigger;
