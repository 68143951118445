import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {AiVariablesState} from "./storeDataStateType";

const initialState: AiVariablesState = {
    aiVariablesList: {}
}

const aiVariablesDataSlice = createSlice({
    name: 'AiVariableData',
    initialState: initialState,
    reducers: {
        setAiVariablesList: (state, action:PayloadAction<AiVariablesState> ) => {
            const { aiVariablesList} = action.payload;
            state.aiVariablesList = {...state.aiVariablesList, ...aiVariablesList};
            return state;
        },
        removeFromList: (state, action:PayloadAction<string[]> ) => {
            const keys = action.payload;
            keys.forEach(key => {
                delete state.aiVariablesList[key];
            });
            return state;
        }
    }
});

export const aiVariablesDataAction = aiVariablesDataSlice.actions;
export default aiVariablesDataSlice;
