import {
  BASE_COLORS,
  BodySecondary,
  Button,
  COLORS, Dropdown, DropdownPopover,
  StyledModal, Tag,
  TitleMedium, TitleSmall,
  Tooltip
} from "@bikdotai/bik-component-library";
import React, {useEffect, useState} from "react";
import { KeywordsModalStyle } from "../../app/entry-point/modals/EntryModal.style";
import TagManager from "../../app/entry-point/tag-manager";
import BotInput from "../../ui-components/input";
import {
  keyWordCounter,
  keyWordsStringify,
} from "../AutomationTrigger/AutomationTrigger";
import { Body, Container, Footer, Header } from "../ig-modals/style";
import {fetchInstaIds} from "../../services/helpers/QueryBuilderHelper";
import {InstaDropdownContainer, InstaDropdownWrapper} from "./styled";
import InstagramIcon from "../../icons/instagramIcon";
import ChevronDownIcon from "../../icons/chevron_down";
import WhatsappIcon from "../../icons/whatsappIcon";
import {cloneDeep} from "lodash";
import {useAppSelector} from "../../state/store";
import {populateChannelIdsDropdown} from "./utils";
import {WarnIcon} from "../../icons/warnIcon";
import InfoIcon from "../../icons/infoIcon";

export interface KeywordProps {
  onClose: () => void;
  onSave: (data?: any) => void;
  setKeywordsObj: (data: any) => void;
  keywordObject: any[];
  storeId: string;
  channel: string;
  currentChannelId?: string;
}
export interface keywordsType {
  BEGINS_WITH: string[];
  EXACT: string[];
  CONTAINS: string[];
  INCLUDES: string[];
}

const KeywordsTrigger: React.FC<KeywordProps> = (props) => {
  const [inputType, setShowInputType] = useState('');
  const [errorTypes, setErrorTypes] = useState<string[]>([]);
  const emptyKeywordsNewObject = {
    BEGINS_WITH: [],
    EXACT: [],
    CONTAINS: [],
    INCLUDES: [],
  };
  const emptyUnsavedKeywordsNewObject = {
    BEGINS_WITH: "",
    EXACT: "",
    CONTAINS: "",
    INCLUDES: "",
  };
  const [unsavedKeyword, setUnsavedKeyword] = useState<any>(emptyUnsavedKeywordsNewObject);
  const [keywordsListObj, setKeywordsListObj] = useState<any>(emptyKeywordsNewObject);
  const [channelIdOptions, setChannelIdOptions] = useState<{
    label: string,
    value: string,
    selected: boolean
  }[]>([])
  const [selectedChannel, setSelectedChannel] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const channels: { [key: string]: any } = useAppSelector(
      (state) => state.flowMetaState.channels!
  );

  const ButtonProp = {
    buttonType: "primary",
    size: "medium",
    buttonText: "Create",
    onClick: () => {
      if (checkUnsavedChanges()) return;
      if (keywordsListObj && keyWordCounter(keywordsListObj) > 0) {
        onClickUpdateKeywordsList(keywordsListObj);
        const keywordData = {
          type: "keyword",
          header: "Customer says",
          subHeader: keyWordsStringify(keywordsListObj),
        };
        props.onSave(keywordData);
        props.onClose()
      } else {
        props.onSave();
        props.onClose()
      }
    },
  };

  useEffect(() => {
    populateChannelIdsDropdown(
        props.channel,
        channels,
        setIsLoading,
        setSelectedChannel,
        setChannelIdOptions,
        props.currentChannelId,
    )
  }, []);

  useEffect(() => {
    if(channelIdOptions && channelIdOptions.length===1){
      setSelectedChannel(channelIdOptions[0])
    }

    if (selectedChannel) {
      if (selectedChannel.value === "ERROR" && props?.currentChannelId) {
        setKeywordsListObj(props.keywordObject[props?.currentChannelId as any] || emptyKeywordsNewObject)
      } else {
        setKeywordsListObj(props.keywordObject[selectedChannel.value] || emptyKeywordsNewObject)
      }
    }

  }, [channelIdOptions]);

  const InstaDropdown = () => {
    return (
        <InstaDropdownContainer>
          <DropdownPopover
              width="200px"
              disabled={isLoading}
              onSelect={(c: any) => {
                setSelectedChannel(c)
                const modified = channelIdOptions
                    .map((d) => {
                      if (d.value === c.value) {
                        return {
                          label: d.label,
                          value: d.value,
                          selected: true,
                        };
                      } else {
                        return {
                          label: d.label,
                          value: d.value,
                          selected: false,
                        };
                      }
                    });
                setChannelIdOptions(modified);
              }}
              options={channelIdOptions}
          >
            <InstaDropdownWrapper>
              {props.channel === 'whatsapp' ? <WhatsappIcon /> : <InstagramIcon />}
              <TitleSmall className="dropdown-text" style={{fontWeight: 400}}>
                {selectedChannel.label || "Select an account"}
              </TitleSmall>
              <ChevronDownIcon color={COLORS.content.primary} />
            </InstaDropdownWrapper>
          </DropdownPopover>
        </InstaDropdownContainer>
    );
  };

  const onClickUpdateKeywordsList = async (keywordsNewObj: string[]) => {
    const keywordsObj = cloneDeep(props.keywordObject);
    keywordsObj[selectedChannel.value] = keywordsNewObj
    props.setKeywordsObj(keywordsObj);
  };

  const keywordComponent = (title: string, type: string, tooltipBody?: string) => {
    return (
      <div className="body body-div" style={{
        backgroundColor: type === 'EXCLUDES' ? COLORS.surface.subdued : 'white',
        padding: type === 'EXCLUDES' ? 16 : 0,
        borderRadius: type === 'EXCLUDES' ? 4 : 0,
        border: type === 'EXCLUDES' ? `1px solid ${COLORS.stroke.primary}` : 'none'
      }}>
        <div className="body-used">
          <div className="used-label" style={{alignItems: "center"}}>{title}  
            {tooltipBody && <Tooltip body={tooltipBody} placement="right" variant="dark">
                <div style={{marginLeft: "8px", display: "inline"}}>
                  <InfoIcon />
                </div>
              </Tooltip>
            }
          </div>
          <div className="used-keywords" style={{display: "flex"}}>
            {keywordsListObj &&
              keywordsListObj[type] &&
              keywordsListObj[type]?.map((val: any, index: any) => {
                return (
                  <div key={index}>
                    <TagManager
                      textColor={"#616161"}
                      tagColor={"#FAFAFA"}
                      text={val}
                      borderColor={"#E0E0E0"}
                      showCross={true}
                      ClickCross={() => onClickRemove(index, type)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="body-add">
          <div className="add-keywords">
            <BotInput
              error={errorTypes.includes(type)}
              height={"24px"}
              width={"980px"}
              type="text"
              onBlur={() => {
                setShowInputType("");
              }}
              onFocus={() => {
                setShowInputType(type);
              }}
              subText={
                inputType === type || errorTypes.includes(type)
                  ? "Press Enter to add a keyword"
                  : undefined
              }
              onChange={(e) => handleUnsavedChanges(e, type)}
              onKeyDown={(e) => checkKeyPress(e, type)}
            />
          </div>
        </div>
      </div>
    );
  };

  const checkUnsavedChanges = () => {
    const errors: string[] = [];
    const bulkKeys: { type: string, value: string }[] = []
    Object.keys(unsavedKeyword).forEach((key: string) => {
      if (unsavedKeyword[key] !== "") {
        bulkKeys.push({
          type: key,
          value: unsavedKeyword[key]
        })
      }
    });
    const tempkeywordsListObj = addKeywordBulk(bulkKeys)
    setErrorTypes(errors);
    return tempkeywordsListObj
  };

  const checkKeyPress = (e: any, type: string) => {
    if (e.target.value === "") {
      return;
    }
    if (e.key === "Enter" && e.target.value && e.target.value.trim()) {
      addKeyword(type, e.target.value.trim());
      e.target.value = "";
      setErrorTypes(errorTypes.filter((item) => item !== type));
      setUnsavedKeyword((prev: any) => {
        const newState = { ...prev };
        newState[type] = "";
        return newState;
      });
    }
  };

  const handleUnsavedChanges = (e: any, type: string) => {
    if (e.target.value === "") {
      setErrorTypes(errorTypes.filter((item) => item !== type));
    }
    if(e.target.value && e.target.value.trim()) {
      setUnsavedKeyword((prev: any) => {
        const newState = { ...prev };
        newState[type] = e.target.value.trim();
        return newState;
      });
    }
  };

  const addKeyword = (type: string, value: string) => {
    let tempkeywordsListObj = { ...keywordsListObj };
    const copyArray = tempkeywordsListObj?.[type]
      ? [...tempkeywordsListObj?.[type]]
      : [];
    if (!copyArray.find((item) => item === value)) {
      copyArray.push(value);
    }
    tempkeywordsListObj[type] = copyArray;
    setKeywordsListObj(tempkeywordsListObj);
  };

  const addKeywordBulk = (bulkKeys: { type: string, value: string }[]) => {
    let tempkeywordsListObj = { ...keywordsListObj };
    for (let bKey of bulkKeys) {
      const copyArray = tempkeywordsListObj?.[bKey.type]
        ? [...tempkeywordsListObj?.[bKey.type]]
        : [];
      if (!copyArray.find((item) => item === bKey.value)) {
        copyArray.push(bKey.value);
      }
      tempkeywordsListObj[bKey.type] = copyArray;
    }
    setKeywordsListObj(tempkeywordsListObj);
    return tempkeywordsListObj
  }

  const onClickRemove = (index: number, type: string) => {
    let tempkeywordsListObj = { ...keywordsListObj };
    let copyArray = [...tempkeywordsListObj[type]];
    copyArray.splice(index, 1);
    tempkeywordsListObj[type] = copyArray;
    setKeywordsListObj(tempkeywordsListObj);
  };

  return (
    <StyledModal
      zIndex={200}
      open={true}
      centralContainerStyles={{ width: "1032px", height: "560px" }}
      onClose={() => {
        props.onClose();
      }}
    >
      <Container>
        <div style={{display: "flex"}}>
          <Header>
            <TitleMedium>{`Add keywords to trigger a flow`}</TitleMedium>
          </Header>
          <div style={{marginTop: 15, paddingRight: 15, borderBottom: `1px solid ${COLORS.stroke.primary}`}}>
            <InstaDropdown/>
          </div>
        </div>

        <Body>
          <KeywordsModalStyle hideExtras={true}>
            {keywordComponent("If message is", "EXACT", "Will only trigger the flow if the entire message is the same as the keyword. For example, the keyword ‘Hey’ will not trigger flow for ‘Hey there’")}
            {keywordComponent("If message begins with", "BEGINS_WITH")}
            {keywordComponent("If message contains", "INCLUDES", "Will trigger the flow even if the keyword is part of another word. For example, will trigger for the keyword ‘Hell’ in ‘Hello World’")}
            {keywordComponent("If message contains the whole word", "CONTAINS", "Will trigger the flow only if full keyword is in the message. For example, will trigger for keyword ‘Hello’ in ‘Hello there,’ but not for ‘Hell.’")}
            {keywordComponent("Exclude messages which contain keywords", "EXCLUDES")}
          </KeywordsModalStyle>
        </Body>

        <Footer>
          {selectedChannel.value === "ERROR" ?
              <Tag tagText={'No account is selected'} type="negative" LeadingIcon={WarnIcon} /> :
              <BodySecondary style={{ color: BASE_COLORS.grayscale["700"] }}>
                Create trigger to start any journey as per your use case
              </BodySecondary>}
          <Button disabled={isLoading} buttonText="Create" size={'medium'} onClick={() => {            
            if (!selectedChannel.value) {
               setSelectedChannel({ value: "ERROR" });
               return;
            }
            if (selectedChannel.value === 'ERROR') {
              return;
            }
              const tempkeywordsListObj = checkUnsavedChanges()
              if (tempkeywordsListObj && keyWordCounter(tempkeywordsListObj) > 0) {
                onClickUpdateKeywordsList(tempkeywordsListObj);
                const keywordData = {
                  type: "keyword",
                  header: "Customer says",
                  subHeader: keyWordsStringify(tempkeywordsListObj),
                  channelId: selectedChannel.value,
                };
                props.onSave(keywordData);
                props.onClose()
              } else {
                const keywordData = {
                  type: "keyword",
                  header: "Customer says",
                  subHeader: '',
                  channelId: selectedChannel.value,
                };
                props.onSave(keywordData);
                props.onClose()
              }
          }} />
        </Footer>
      </Container>

    </StyledModal>
  );
};

export default KeywordsTrigger;
